import "./Reviews.scss";
import React, { useEffect, useState } from "react";
import ReviewStars from "./ReviewStars";
import Review from "./Review";
import ReviewForm from "./ReviewForm";
import ReviewTiming from "./ReviewTiming";
import Constants from "../../services/Constants";
import Helpers from "../../services/Helpers";
import {Link} from "react-router-dom";

export default function Reviews({ authenticated, cache, store, hide }) {
  const { documentTypes } = Constants();
  const { isEmpty, isNotEmpty } = Helpers();
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const memberReview = store.memberReviews.find((r) => r.documentId === store.documentData.documentId);
    if (memberReview && memberReview.stars === 0) {
      setShowForm(true);
    }
  }, [store.memberReviews, store.documentData.documentId]);

  function otherReviews(memberReview) {
    let result;
    let reviews;
    if (memberReview) {
      reviews = store.reviewData.reviews.filter((r) => r.id !== memberReview.id && (r.text || r.photo));
    } else {
      reviews = store.reviewData.reviews.filter((r) => r.text || r.photo);
    }
    const noPotoLayout = reviews.filter((r) => isNotEmpty(r.photo)).length === 0;
    result = reviews.map((r) => <Review key={r.id} review={r} memberReview={false} noPhotoLayout={noPotoLayout} />);
    return result;
  }

  const stats =
    store.reviewData.reviews.length === 0 ? null : (
      <div className="ratings p-3 my-1 my-4">
        <div className="row align-items-center">
          <div className="col-12 col-lg text-center">
            <ReviewStars stars={store.reviewData.stars} />
            <span className="rated-by-count fs-6">Rated by {store.reviewData.reviews.length} members</span>
          </div>
          {store.documentData.documentType === documentTypes.WIZARD_PLAN ? (
            <div className="col-12 col-lg mt-2 reviews-timing-rating text-center">
              <ReviewTiming timing={store.reviewData.timing} />
              <span className="fs-6">Batch Time</span>
            </div>
          ) : null}
        </div>
        <div className="rating-categories mt-2">
          <div className="row align-items-start">
            {store.reviewData.kidFriendly > 0 ? (
              <div className="col-12 text-center">
                <p className="mb-0">
                  Rated kid-friendly by {store.reviewData.kidFriendly} {store.reviewData.kidFriendly === 1 ? "member" : "members"}
                </p>
              </div>
            ) : null}
            {store.reviewData.omniFriendly > 0 ? (
              <div className="col-12 text-center">
                <p className="mb-0">
                  Rated omni-friendly by {store.reviewData.omniFriendly} {store.reviewData.omniFriendly === 1 ? "member" : "members"}
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );

  function main() {
    const memberReview = store.memberReviews.find((r) => r.documentId === store.documentData.documentId && r.documentType === store.documentData.documentType);
    const reviewForm = <ReviewForm cache={cache} store={store} review={memberReview} hide={() => setShowForm(false)} />;
    let result;
    const addReviewButton = (
      <div className="text-center mt-2">
        {authenticated?<button className="btn btn-primary btn-lg" type="button" onClick={() => setShowForm(true)}>
          <i className="fa-solid fa-star" /> Add a review
        </button>:
        <Link to="/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=home+page" onClick={hide} className="btn btn-primary btn-lg">{`Make this ${store.documentData.documentType.toLowerCase()}`}</Link>}
      </div>
    );
    if (store.reviewData.reviews.length === 0) {
      if (authenticated) {
        result = showForm ? (
          reviewForm
        ) : memberReview ? (
          <Review review={memberReview} memberReview={true} noPhotoLayout={isEmpty(memberReview.photo)} edit={() => setShowForm(true)} destroy={store.destroy} />
        ) : (
          addReviewButton
        );
      } else {
        result = <>
        {addReviewButton}
        <div>No reviews yet</div>
        </>
      }
    } else {
      result =
        authenticated && showForm ? (
          reviewForm
        ) : (
          <>
            {authenticated ? (
              memberReview ? (
                <Review review={memberReview} memberReview={true} noPhotoLayout={isEmpty(memberReview.photo)} edit={() => setShowForm(true)} destroy={store.destroy} />
              ) : (
                addReviewButton
              )
            ) : addReviewButton}
            {stats}
            {otherReviews(memberReview)}
          </>
        );
    }
    return <div className="pt-0 pb-3">{result}</div>;
  }

  return main();
}
