import EditSubscriptionForm from "./EditSubscriptionForm";

export default function ChangeSubscriptionModal({ memberStore, subStore, hideModal }) {
  const showForm = subStore.plans.length > 0 && subStore.subscription.length > 0;

  return (
    <div id="change-subscription-modal" className="modal" tabIndex={-1} data-bs-backdrop="static">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Change my membership</h1>
            <button type="button" className="btn-close btn-close-white" onClick={() => hideModal("change-subscription-modal")} />
          </div>
          <div className="modal-body">
            {showForm && <EditSubscriptionForm member={memberStore.member} subStore={subStore} hide={() => hideModal("change-subscription-modal")} />}
          </div>
        </div>
      </div>
    </div>
  );
}
