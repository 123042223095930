import "./Subscription.css";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router-dom";
import { Modal } from "bootstrap";
import Helpers from "../../../services/Helpers";
import StartTrialModal from "../modals/StartTrialModal";
import ChangeSubscriptionModal from "../modals/ChangeSubscriptionModal";
import AddSubscriptionModal from "../modals/AddSubscriptionModal";
import CancelSubscriptionModal from "../modals/CancelSubscriptionModal";
import ReactivateSubscriptionModal from "../modals/ReactivateSubscriptionModal";
import SubscriptionButtons from "./SubscriptionButtons";
import SubscriptionMessage from "./SubscriptionMessage";
import SubscriptionDetails from "./SubscriptionDetails";

export default function Subscription(props) {
  const { memberStore, subStore } = props;
  const location = useLocation();
  const { isNotEmpty } = Helpers();
  const [showUpdateButtons, setShowUpdateButtons] = useState(false);
  const [resetCancel, setResetCancel] = useState(false);

  useEffect(() => {
    return () => subStore.resetSubscriptionMessage();
  }, []);

  useEffect(() => {
    setShowUpdateButtons(false);
  }, [subStore.subscription]);

  useEffect(() => {
    setResetCancel(false);
  }, [resetCancel]);

  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    const m = searchParams.get("m");
    if (isNotEmpty(m) && m === "become-a-member" && !memberStore.waitingForMember) {
      if (memberStore.member.hadMP) {
        showModal("reactivate-subscription-modal");
      } else if (memberStore.member.hasTrial || !memberStore.member.hasMP) {
        showModal("add-subscription-modal");
      } else if (memberStore.member.hasRV || memberStore.member.hasMP) {
        showModal("change-subscription-modal");
      }
    }
  }, [location, memberStore.waitingForMember, memberStore.member]);

  const showSubscriptionButtons =
    !subStore.waitingForSubscription && !memberStore.waitingForMember && !memberStore.member.admin && !memberStore.member.mpFriend;

  function showModal(id) {
    const element = document.getElementById(id);
    if (isNotEmpty(element)) {
      const modal = Modal.getOrCreateInstance(element);
      modal.show();
      const up = document.getElementById("userpilot-checklist");
      if (isNotEmpty(up)) {
        up.style.display = "none";
      }
    }
  }

  function hideModal(id) {
    const element = document.getElementById(id);
    if (isNotEmpty(element)) {
      const modal = Modal.getOrCreateInstance(element);
      modal.hide();
      if (id === "cancel-subscription-modal") {
        setResetCancel(true);
      }
      window.scrollTo(0, 0);
      const up = document.getElementById("userpilot-checklist");
      if (isNotEmpty(up)) {
        up.style.display = "block";
      }
    }
  }

  //TODO uncomment and refactor the code below after moving modals to a global modal component

  // if (memberStore.waitingForMember || subStore.waitingForSubscription) {
  //   return (
  //     <div className="mb-2">
  //       <span className="spinner-border" />
  //     </div>
  //   );
  // }

  return (
    <div>
      {!!subStore.subscriptionMessage && <SubscriptionMessage {...props} />}
      <div className="mb-2">
        {memberStore.waitingForMember || subStore.waitingForSubscription ? <span className="spinner-border" /> : <SubscriptionDetails {...props} />}
        {showSubscriptionButtons && (
          <SubscriptionButtons {...props} showModal={showModal} showUpdateButtons={showUpdateButtons} setShowUpdateButtons={setShowUpdateButtons} />
        )}
      </div>
      <StartTrialModal {...props} hideModal={hideModal} />
      <AddSubscriptionModal {...props} hideModal={hideModal} />
      <ChangeSubscriptionModal {...props} hideModal={hideModal} />
      <CancelSubscriptionModal {...props} resetCancel={resetCancel} hideModal={hideModal} />
      <ReactivateSubscriptionModal {...props} hideModal={hideModal} />
    </div>
  );
}

Subscription.propTypes = {
  memberStore: PropTypes.object,
  subStore: PropTypes.object,
};
