import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";

export default function Purchase(props) {
  const {formatDateInternational} = Helpers();
  return (
    <tr>
      <td>{formatDateInternational(props.purchase.date)}</td>
      <td>${props.purchase.total_price}</td>
      <td>{props.purchase.status}</td>
      <td>{props.purchase.name}</td>
      <td>{props.purchase.invoice_id}</td>
    </tr>
  );
}

Purchase.propTypes = {
  purchase: PropTypes.object,
}
