import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

export default function ViewSwitch(props) {
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setWorking(false);
  }, [props.text]);

  function toggle() {
    setWorking(true);
    props.toggle();
  }

  return working ? (
    <button className="btn btn-white text-muted" type="button" title="Switching..." disabled={true}>
      <span className="spinner-border spinner-border-sm" />
    </button>
  ) : (
    <button className="btn btn-white text-muted" type="button" onClick={toggle} title={props.text}>
      <i className="fa-solid fa-eye" />
    </button>
  );
}

ViewSwitch.propTypes = {
  toggle: PropTypes.func,
  text: PropTypes.string,
};
