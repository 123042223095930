// ***** PersonalizedContentStore.js *****
// This store interacts with api endpoints defined in PersonalizedContentResource.java
// This store provides data and function to views: CookingJournal.js

import {useEffect, useReducer} from "react";
import axios from "axios";
import Helpers from "../Helpers";

const actions = {
  LOAD: "load",
}
  
const initialState = {
  cookingJournal: {},
  uniqueRecipeMadeIds: [],
}

export default function usePersonalizedContent(token) {
  const { isNotEmpty } = Helpers();

  function reducer(state, action) {
    if (action.type === actions.LOAD) {
      const uniqueRecipeMadeIds = [...new Set(action.data.cookingJournal.map((rj) => rj.recipeId))];

      const recipesByDate = action.data.cookingJournal.reduce((group, recipe) => {
        const { date } = recipe;
        group[date] = group[date] ?? [];
        group[date].push(recipe);
        return group;
      }, {});

      return {...state, cookingJournal: recipesByDate, uniqueRecipeMadeIds: uniqueRecipeMadeIds}
    }
    return state;
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (isNotEmpty(token)) {
      get();
    }
  }, [token]);

  function get() {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/personalized-content/recipe-made`,
    }).then(res => {
      dispatch({
        type: actions.LOAD,
        data: { cookingJournal: res.data }
      });
    });
  }

  function deleteRecipeMade(id, cb) {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/personalized-content/recipe-made/${id}`,
    }).then(res => {
      dispatch({
        type: actions.LOAD,
        data: { cookingJournal: res.data }
      });
      if (cb) cb();
    });
  }

  return {
    state: state,
    get: get,
    deleteRecipeMade: deleteRecipeMade,
  };
}