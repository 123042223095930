import React from "react";
import PropTypes from "prop-types";
import marked from "marked";
import Helpers from "../../services/Helpers";

export default function MarkdownViewer(props) {
  const {isEmpty} = Helpers();

  const markdown = isEmpty(props.markdown)
    ? ""
    : marked(props.markdown);

  return <span className="markdown-notes"  dangerouslySetInnerHTML={{ __html: markdown }} />;
};

MarkdownViewer.propTypes = {
  markdown: PropTypes.string,
}