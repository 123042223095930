import {useState} from "react";
import Helpers from "./Helpers";
import Constants from "./Constants";

export default function useNutritionFacts(cache, rh, ss, member) {
  const {recipeComponentCategories, recipeTypes} = Constants();
  const {isEmpty, isNotEmpty} = Helpers();
  const [nutritionFacts, setNutritionFacts] = useState([]);
  const [nutritionFactsFor, setNutritionFactsFor] = useState("");
  const [serving, setServing] = useState("");
  const [components, setComponents] = useState([]);

  function resetData() {
    setNutritionFacts([]);
    setNutritionFactsFor("");
    setComponents([]);
  }

  // Accepts data which contains these properties: recipeId, recipeData (masters and recipes), recipeTools, deletedChildRecipes, ingredientNutrients
  function generateRecipeNutritionFacts(data) {
    ss.customizeRecipesComponents(data.recipeData, data.recipeTools);
    ss.deleteChildRecipes(data.recipeData.recipes, data.deletedChildRecipes);
    const deletedRecipeIds = data.deletedChildRecipes.map(cr => cr.childRecipeId);
    // Keep only recipes that were not removed
    const recipes = data.recipeData.recipes.filter(r => deletedRecipeIds.indexOf(r.id) === -1);
    const recipe = recipes.find(r => r.id === data.recipeId);
    setNutritionFactsFor(recipe.name);
    // Set the initial ratio to 1 portion
    recipe["ratio"] = 1 / recipe.portions;
    // Show portion yield when possible
    singlePortionMeasurement(recipe);
    // Add child recipe ratios
    const keptRecipeIds = [recipe.id];
    const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
    processComponents(recipes, keptRecipeIds, components, recipe.ratio);
    // Keep only recipes that are either the current recipe or a child recipe of the current recipe
    const keptRecipes = recipes.filter(r => keptRecipeIds.indexOf(r.id) !== -1);
    // Calculate nutrients
    const recipesNutrients = [];
    ss.generateNutritionFacts(keptRecipes, data.ingredients, data.ingredientNutrients, recipesNutrients);
    const result = [];
    calculateNutrients(keptRecipes, recipesNutrients, result);
    setNutritionFacts(result);
    if (recipe.type === recipeTypes.ASSEMBLY) {
      const recipeComponents = [];
      for (let c of components) {
        if (c.category === recipeComponentCategories.RECIPE) {
          const childRecipe = keptRecipes.find(r => r.id === c.childRecipeId);
          if (isNotEmpty(childRecipe)) {
            recipeComponents.push({id: c.id, name: childRecipe.name, quantity: rh.makeMeasurementLookGood(childRecipe.yieldVolume * childRecipe.ratio, childRecipe.yieldUnitId, member.preferences.volumeSystem, childRecipe.name)});
          }
        } else if (c.category === recipeComponentCategories.INGREDIENT) {
          const ingredient = data.ingredients.find(i => i.id === c.ingredientId);
          const ingredientName = isNotEmpty(ingredient) ? ingredient.invertedName : ""; // TODO: c might already have inverted name, to validate
          if (isNotEmpty(c.volume)) {
            recipeComponents.push({id: c.id, name: ingredientName, quantity: rh.makeMeasurementLookGood(recipe.ratio*c.volume, c.volumeUnitId, member.preferences.volumeSystem)});
          } else if (isNotEmpty(c.weight)) {
            recipeComponents.push({id: c.id, name: ingredientName, quantity: rh.makeMeasurementLookGood(recipe.ratio*c.weight, c.weightUnitId, member.preferences.weightSystem)});
          }
        }
      }
      setComponents(recipeComponents);
    }
  }

  function singlePortionMeasurement(recipe) {
    if (isNotEmpty(recipe.yieldVolume) && isNotEmpty(recipe.yieldUnitId)) {
      if (recipe.yieldVolume === 1) {
        // TODO
        let fraction;
        switch (recipe.portions) {
          case 2:
            fraction = "½";
            break;
          case 3:
            fraction = "⅓";
            break;
          case 4:
            fraction = "¼";
            break;
          case 8:
            fraction = "⅛";
            break;
          default:
            fraction = "";
        }
        setServing(`1 serving (1/${recipe.portions} ${rh.yieldUnitName(recipe.yieldUnitId, member.preferences.volumeSystem)})`)
      } else {
        setServing(`1 serving (${rh.makeMeasurementLookGood(recipe.yieldVolume * recipe.ratio, recipe.yieldUnitId, member.preferences.volumeSystem, "")})`);
      }
    } else {
      setServing("1 serving");
    }
  }

  function processComponents(recipes, keptRecipeIds, components, ratio) {
    for (let component of components) {
      if (component.category === recipeComponentCategories.RECIPE) {
        processRecipe(recipes, keptRecipeIds, component, ratio);
      }
    }
  }

  function processRecipe(recipes, keptRecipeIds, component, ratio) {
    const recipe = recipes.find(r => r.id === component.childRecipeId);
    if (!recipe.hasOwnProperty("ratio")) {
      recipe["ratio"] = 0;
    }
    let appearanceRatio = 0;
    if (isNotEmpty(component.volume)) {
      appearanceRatio = component.volume / recipe.yieldVolume * ratio;
    }
    recipe.ratio += appearanceRatio;
    const keptRecipeId = keptRecipeIds.find(id => id === recipe.id);
    if (isEmpty(keptRecipeId)) {
      keptRecipeIds.push(recipe.id);
    }
    const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
    processComponents(recipes, keptRecipeIds, components, appearanceRatio);
  }

  function calculateNutrients(recipes, recipesNutrients, result) {
    for (let recipe of recipes) {
      const recipeNutrients = recipesNutrients.find(rn => rn.recipeId === recipe.id);
      if (isNotEmpty(recipeNutrients)) {
        for (let nutrient of recipeNutrients.nutrients) {
          const fact = result.find(f => f.name === nutrient.name);
          if (isNotEmpty(fact)) {
            if (fact.unit === nutrient.unit) {
              fact.amount += nutrient.amount * recipe.ratio;
            } else {
              console.log("nutrient name: " + nutrient.name);
              console.log("fact unit: " + fact.unit);
              console.log("unit in update: " + nutrient.unit);
            }
          } else {
            nutrient.amount *= recipe.ratio;
            result.push(nutrient);
          }
        }
      }
    }
  }

  const nutritionFactsStore = {
    nutritionFacts: nutritionFacts,
    nutritionFactsFor: nutritionFactsFor,
    serving: serving,
    components: components,
    resetData: resetData,
    generateRecipeNutritionFacts: generateRecipeNutritionFacts,
  }

  return {nutritionFactsStore};
}