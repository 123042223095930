import { useEffect, useState } from "react";
import axios from "axios";
import Helpers from "../Helpers";

export default function useFeatured(token, member) {
  const { handleError, isNotEmpty } = Helpers();
  const [items, setItems] = useState({
    featuredRecipes: [],
    featuredWizardPlans: [],
    noteworthyRecipes: [],
  });

  useEffect(() => {
    if (isNotEmpty(token) && (member.hasMP || member.hasRV)) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: `${process.env.REACT_APP_API}/search/featured`,
      })
        .then((res) => {
          setItems(res.data);
        })
        .catch((err) => {
          handleError(err);
        });
    }
  }, [token, member]);

  const featuredStore = {
    items: items,
  };

  return { featuredStore };
}
