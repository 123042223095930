import React, {useEffect, useState} from "react";
import axios from "axios";
import {Toast} from "bootstrap";
import {useLocation} from "react-router-dom";

export default function VersionToast() {
  const location = useLocation();
  const [timeOutId, setTimeOutId] = useState(0);
  const [zIndex, setZIndex] = useState(0);

  function getVersion() {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API}/versions/current`,
    }).then(res => {
      if (res.data === process.env.REACT_APP_VERSION) {
        setTimeOutId(setTimeout(getVersion, 300000));
      } else {
        if (res.data.length > 0 && res.data[0].match(/\d/) !== null) {
          clearTimeout(timeOutId);
          show();
        } else {
          clearTimeout(timeOutId);
          // We don't show the modal in this case, what we received was not a real version
        }
      }
    });
  }

  function show() {
    if (["/trial", "/sign-up"].indexOf(location.pathname) === -1) {
      setZIndex(1200);
      const element = document.getElementById("version-toast");
      const toast = Toast.getOrCreateInstance(element, {autohide: false});
      toast.show();
    }
  }

  function dismiss() {
    const element = document.getElementById("version-toast");
    const toast = Toast.getOrCreateInstance(element, {autohide: false});
    toast.hide();
    setZIndex(0);
  }

  useEffect(() => {
    getVersion();
  }, []);

  return (
    <div className="position-fixed top-0 start-50 translate-middle-x p-3 text-center" style={{zIndex:zIndex}}> {/* zindex to make it appear above everything */}
      <div id="version-toast" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
        <div className="toast-header">
          <span className="fs-5"><i className="fa-solid fa-bell-on me-1" />Updates released</span>
          <button type="button" className="btn-close btn-close-white ms-auto" aria-label="Close" onClick={dismiss}/>
        </div>
        <div className="toast-body">
          <p>We've been busy in the kitchen and at our desks making Plant Fueled Life even better!</p>
          <button type="button" className="btn btn-light" onClick={() => window.location.reload()}><span className="d-sm-none">Tap</span><span className="d-none d-sm-inline">Click</span> here to refresh</button>
          <p className="p-0 m-2 small">Curious to know what changed? <a className="text-white fw-bold" href="https://help.cleanfooddirtygirl.com/article/97-portal-updates" target="_blank">Read the release notes</a>.</p>
        </div>
      </div>
    </div>
  );
}