import React, {useEffect} from "react";
import PropTypes from "prop-types";
import useTileCollection from "../../../services/stores/TileCollectionStore";
import Tile from "../Tile/Tile";
import Footer from "../../components/Footer";
import {useLocation} from "react-router-dom";
import Helpers from "../../../services/Helpers";

export default function TileCollection(props) {
  const location = useLocation();
  const {pageTitle} = Helpers();
  const { tileCollection } = useTileCollection(props.token, props.id);

  useEffect(() => {
    document.title = pageTitle(location.pathname);
  }, [location]);

  function className() {
    let result = "container-lg";
    if (tileCollection.cssClass) {
      result = `container-lg ${tileCollection.cssClass}`;
    }
    return result;
  }

  const tiles = tileCollection.tiles.map((t, i) => (
    <Tile key={i} t={t} />
  ));

  function rowClassName() {
    let result;
    if (tileCollection.tiles.length === 3 || tileCollection.tiles.length > 4) {
      result = "row row-cols-1 row-cols-md-3";
    } else {
      result = "row row-cols-1 row-cols-md-2";
    }
    return result;
  }

  return (
    <>
      <main id="tile-collection" className={className()}>
        {props.banner}
        <h1 className="text-center page-title mb-3">{tileCollection.title}</h1>
        <div className="container-lg">
          <div className={rowClassName()}>{tiles}</div>
        </div>
      </main>
      <Footer />
    </>
  );
}

TileCollection.propTypes = {
  token: PropTypes.string,
  id: PropTypes.number,
  banner: PropTypes.element,
}