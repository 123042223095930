import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";

export default function IngredientAppearance(props) {
  const {isNotEmpty} = Helpers();

  function renderSubNote() {
    let result = "";
    if (isNotEmpty(props.appearance.subNote) && props.showAppearancesSubNotes) {
      result = (
        <>
          <small className="text-muted">&nbsp;{props.appearance.subNote}</small>
        </>
      );
    }
    return result;
  }

  return (
    <li>
      <div className="row my-2">
        <div className="col-6">
          <small>
          {props.appearance.quantity}
            <span className="text-muted">
              {renderSubNote()}
            </span>
          </small>
        </div>
        <div className="col-6">
          <small>{props.appearance.parentRecipeName}</small>
        </div>
      </div>
    </li>
  );
}

IngredientAppearance.propTypes = {
  appearance: PropTypes.object,
}