import React from "react";
import PropTypes from "prop-types";
import AddSomething from "../../components/AddSomething";
import MagicMealPlanSection from "./MagicMealPlanSection";
import ViewSwitch from "./ViewSwitch";
import MemberOnlyFeatureModal from "../../components/MemberOnlyFeatureModal";
import Constants from "../../../services/Constants";

export default function MagicMealPlanSections(props) {
  const { MMP_SECTIONS: mmpSections } = Constants();

  const sections = props.mmpStore.state.plan.sections.map((s) => (
    <MagicMealPlanSection
      key={s.id}
      stashStore={props.stashStore}
      mmpStore={props.mmpStore}
      section={s}
      bookmarked={props.mmpStore.state.dayBookmark.planId === props.mmpStore.state.plan.id && props.mmpStore.state.dayBookmark.day === s.day}
      selectMeal={props.setMealSlot}
      viewMeal={props.viewMeal}
      bookmark={() => props.mmpStore.setDayBookmark({ planId: props.mmpStore.state.plan.id, day: s.day })}
    />
  ));

  const header = (
    <div className="row justify-content-between align-items-center p-2 mb-2 my-auto">
      <div className="col-auto">
        <h2 className="h-alt mt-2 text-magic nav-heading">
          <i className="far fa-calendar-day" /> Plan
        </h2>
      </div>
      <div className="col text-end">
        <ViewSwitch text={props.mmpStore.state.plan.listView ? "Switch to Detailed" : "Switch to List"} toggle={props.mmpStore.toggleListView} />
      </div>
    </div>
  );

  return (
    <div className="p-0 p-sm-3">
      {header}
      {sections}
      {props.mmpStore.state.plan.sections.length < mmpSections ? (
        <div className="position-relative">
          <div className="position-absolute mt-10 start-50 translate-middle add-day">
            <AddSomething text="Add Section" action={() => props.mmpStore.createSection(props.mmpStore.state.plan.id, { name: "" })} />
          </div>
        </div>
      ) : null}
      <MemberOnlyFeatureModal member={props.member} />
    </div>
  );
}

MagicMealPlanSections.propTypes = {
  stashStore: PropTypes.object,
  mmpStore: PropTypes.object,
  title: PropTypes.element,
  setMealSlot: PropTypes.func,
  viewMeal: PropTypes.func,
  member: PropTypes.object,
  print: PropTypes.func,
};
