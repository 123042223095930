import { DateTime } from "luxon";
import Helpers from "../../../services/Helpers";

export default function SubscriptionDetails({ memberStore, subStore }) {
  const { isNotEmpty } = Helpers();

  const currentSub = subStore.subscription.length > 0 ? subStore.subscription[0] : null;
  const futureSub = subStore.subscription.length === 2 ? subStore.subscription[1] : null;
  const nextBillingDate = currentSub && currentSub.nextBillingDate ? DateTime.fromSeconds(currentSub.nextBillingDate) : null;
  const lastDay = nextBillingDate ? nextBillingDate.minus({ days: 1 }).toLocaleString() : "";
  // last trial day is 4 days before last day, or last day if less than 4 days remaining
  const lastTrialDay = isNotEmpty(nextBillingDate)
    ? nextBillingDate.minus({ days: 5 }).startOf("day") >= DateTime.now().startOf("day")
      ? nextBillingDate.minus({ days: 5 }).toLocaleString()
      : nextBillingDate.minus({ days: 1 }).toLocaleString()
    : "";
  const trialExtended = isNotEmpty(nextBillingDate) ? nextBillingDate.minus({ days: 5 }).startOf("day") < DateTime.now().startOf("day") : false;

  if (memberStore.member.admin || memberStore.member.mpFriend) {
    return (
      <div className="card mb-3">
        <div className="card-body p-2">
          <div className="text-center my-2">
            Plan: <strong>{`${memberStore.member.admin ? "Admin" : "Family & Friends"} (no subscription)`}</strong>
          </div>
          <div className="text-center pb-2">
            Status: <strong>Active</strong>
          </div>
        </div>
      </div>
    );
  }

  if (currentSub?.internalName === "MP_GIFT") {
    return (
      <div className="card mb-3">
        <div className="card-body p-2">
          <div className="text-center my-2">{currentSub.plan}</div>
          <div className="text-center pb-2">
            <small className="text-muted">Gift expires on {lastDay}</small>
          </div>
        </div>
      </div>
    );
  }

  if (currentSub?.internalName === "PFL_TRIAL") {
    let dateInfo = `Trial expired on ${lastTrialDay}`;
    if (memberStore.member.hasTrial) {
      dateInfo = `Trial ${trialExtended ? "extended to" : "expires on"} ${lastTrialDay}`;
    }
    return (
      <div className="card mb-3">
        <div className="card-body p-2">
          <div className="text-center my-2">{currentSub.plan}</div>
          <div className="text-center pb-2">
            <small className="text-muted">
              {trialExtended && <span className="fa-solid fa-party-horn me-1" />}
              {dateInfo}
            </small>
          </div>
        </div>
      </div>
    );
  }

  const plan = subStore.plans.find((p) => currentSub?.internalName === p.internalName);
  if (isNotEmpty(plan)) {
    const futurePlan = futureSub && subStore.plans.find((p) => futureSub.internalName === p.internalName);
    if (subStore.subscription.length > 0) {
      let status = "Active";
      let dateInfo = null;
      if (futurePlan) {
        dateInfo = `Plan changes to ${futurePlan.name} for $${futurePlan.price} on ${lastDay}`;
      } else if (currentSub.canceled && nextBillingDate >= DateTime.now()) {
        status = "Canceled";
        dateInfo = `Membership expires on ${lastDay}`;
      } else if (plan) {
        dateInfo = `Next $${plan.price} charge on ${lastDay}`;
      }
      return (
        <div className="card mb-3">
          <div className="card-body p-2">
            <div className="text-center my-2">{plan.name}</div>
            <div className="text-center mb-2">
              Status: <strong>{status}</strong>
            </div>
            <div className="text-center pb-2">
              <small className="text-muted">{dateInfo}</small>
            </div>
          </div>
        </div>
      );
    }
  }
  return null;
}
