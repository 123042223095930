import { Carousel } from "bootstrap";
import React, { useEffect } from "react";
import Helpers from "../../../../services/Helpers";

export default function HomepageCarousel({ id, photos, goTo }) {
  const { assetUrl } = Helpers();

  useEffect(() => {
    if (photos.length > 0) {
      const element = document.getElementById(id);
      if (element !== null) {
        const carousel = new Carousel(element, {
          ride: "carousel",
          wrap: true,
          touch: true,
        });
      }
    }
  }, [photos]);

  return photos.length > 0 ? (
    <div id={id} className="carousel slide" data-bs-interval="false">
      <div className="carousel-inner cursor-pointer rounded shadow-sm">
        {photos.map((p, i) => (
          <div key={i} className={i === 0 ? "carousel-item active" : "carousel-item"}>
            <img
              src={assetUrl(p)}
              className="shadow"
              style={{ objectFit: "cover", width: "100%", aspectRatio: "1/1" }}
              onClick={(event) => {
                event.stopPropagation();
                goTo();
              }}
            />
          </div>
        ))}
      </div>
      <button className="carousel-control-prev ms-1" type="button" data-bs-target={`#${id}`} data-bs-slide="prev">
        <i className="fas fa-circle-chevron-left" style={{ filter: "drop-shadow(0 0 4px #6c757d)" }} />
      </button>
      <button className="carousel-control-next me-1" type="button" data-bs-target={`#${id}`} data-bs-slide="next">
        <i className="fas fa-circle-chevron-right" style={{ filter: "drop-shadow(0 0 4px #6c757d)" }} />
      </button>
    </div>
  ) : null;
}
