import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../components/Checkbox";

export default function RecipeInstruction(props) {

  return props.showCheckbox ? (
    <div className="row align-top mb-2">
      <div className="col-auto mx-0 px-0">
        <Checkbox
          checked={props.checked}
          i={props.checkboxIterator}
          locked={props.updatingCheckboxes}
          toggle={props.toggleCheckbox}
        />
      </div>
      <div className={props.checked ? "col text-muted" : "col"}>
        {props.content}
      </div>
    </div>
  ) : (
    <div className="row">
      <div className="col">
        {props.content}
      </div>
    </div>
  );
}

RecipeInstruction.propTypes = {
  content: PropTypes.element,
  showCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
  checkboxIterator: PropTypes.number,
  updatingCheckboxes: PropTypes.bool,
  toggleCheckbox: PropTypes.func,
}