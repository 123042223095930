import React from "react";
import PropTypes from "prop-types";

export default function MacroNutrient(props) {

  const config = props.config.find(fn => fn.name === props.nutrient.name);

  return (
    <div className="col-6 py-3 col-md-3">
      <h4>{Math.round(props.nutrient.amount)}{config.showUnit ? props.nutrient.unit : null}</h4> {config.friendlyName}
    </div>
  )
}

MacroNutrient.propTypes = {
  nutrient: PropTypes.object,
  config: PropTypes.array,
}