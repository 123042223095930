import "./WizardPlan.css";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import useWizardPlan from "../../services/stores/WizardPlanStore";
import { Modal } from "bootstrap";
import MealPreview from "./components/MealPreview";
import AccordionRecipe from "../Recipe/components/AccordionRecipe";
import IngredientPreps from "./components/IngredientPreps";
import ReviewStars from "../components/ReviewStars";
import Constants from "../../services/Constants";
import AccordionItem from "../components/AccordionItem";
import CustomGroceryItems from "../components/CustomGroceryItems";
import AnonymousFooter from "../components/AnonymousFooter";
import GroceryListCategory from "./components/GroceryListCategory";
import Helpers from "../../services/Helpers";
import Recipe from "../Recipe/components/Recipe";
import Notes from "../components/Notes";
import ReviewTiming from "../components/ReviewTiming";
import FullScreenImage from "../Recipe/components/FullScreenImage";
import Footer from "../components/Footer";
import VimeoVideo from "../components/VimeoVideo";
import usePDF from "../../services/PDFHook";
import MemberOnlyFeatureModal from "../components/MemberOnlyFeatureModal";
import AnonymousCallToActions from "../components/AnonymousCallToActions";
import StashButton from "../Search/components/StashButton";
import Review from "../components/Review";
import { Helmet } from "react-helmet";

const views = {
  MEALS: "meals",
  BATCHING: "batching",
  GROCERY_LIST: "groceries",
  NOTES: "notes",
  MEAL: "meal",
};
export default function WizardPlan(props) {
  let { hashtag } = useParams();
  let location = useLocation();
  let history = useHistory();
  const { contexts, documentTypes } = Constants();
  const { assetUrl, capitalizeFirstLetter, isNotEmpty, shortenedText, splitHashtag, isEmpty } = Helpers();
  const {
    state,
    specialScalingIds,
    changeCookingMethod,
    changePortions,
    deleteChildRecipe,
    deleteCustomizations,
    deleteIngredients,
    prepareDataForNutritionFacts,
    restoreChildRecipes,
    updateCheckboxes,
    updateChildRecipeAdditionalPortions,
    updateIngredient,
    updatePrep,
  } = useWizardPlan(props.cache, props.token, props.memberStore.member, props.ss, props.getPersonalizedContent, hashtag);
  const { pdfStore } = usePDF(props.memberStore.member.preferences, documentTypes.WIZARD_PLAN, state);
  const [view, setView] = useState(views.MEALS);
  const [recipe, setRecipe] = useState(null);
  const [prepVideo, setPrepVideo] = useState(null);
  const [lightBoxOpened, setLightBoxOpened] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [collapseAll, setCollapseAll] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const publicReviews = state.publicData.reviews.filter((r) => r.text || r.photo);

  useEffect(() => {
    // Clean video modal when closing to avoid seeing the wrong video thumbnail for a moment
    const element = document.getElementById("video-modal");
    if (isNotEmpty(element)) {
      element.addEventListener("hidden.bs.modal", () => {
        setPrepVideo(null);
      });
    }
  }, []);

  // When the URL changes, set the proper view
  useEffect(() => {
    let searchParams = new URLSearchParams(location.search);
    const v = searchParams.get("v");
    if (isNotEmpty(v)) {
      for (const [key, value] of Object.entries(views)) {
        if (v === value) {
          if (v === views.MEAL) {
            let cannotShowMeal = true;
            const id = searchParams.get("id");
            if (isNotEmpty(id)) {
              const desiredMeal = state.meals.viewRecipes.find((m) => m.id.toString() === id);
              if (isNotEmpty(desiredMeal)) {
                cannotShowMeal = false;
                setRecipe(desiredMeal);
              }
            }
            if (cannotShowMeal) {
              history.replace(`${location.pathname}?v=${views.MEALS}`);
            }
          } else {
            setRecipe(null);
            setView(views[key]);
          }
          break;
        }
      }
    } else {
      history.replace(`${location.pathname}?v=${views.MEALS}`);
    }
  }, [location]);

  // Set the page title when the plan hashtag changes
  useEffect(() => {
    if (isNotEmpty(state.mealPlan.hashtag)) {
      document.title = `${splitHashtag(state.mealPlan.hashtag)} - Plant Fueled Life`;
    }
  }, [state.mealPlan.hashtag]);

  useEffect(() => {
    // Refresh recipe in state when meal has been updated
    if (recipe !== null) {
      const meal = state.meals.viewRecipes.find((m) => m.id === recipe.id);
      if (meal !== undefined) {
        setRecipe(meal);
      }
    }
  }, [state]);

  useEffect(() => {
    if (isNotEmpty(recipe)) {
      setView(views.MEAL);
    }
  }, [recipe]);

  useEffect(() => {
    if (pdfStore.fileDownloaded) {
      setDownloading(false);
      pdfStore.reset();
    }
  }, [pdfStore.fileDownloaded]);

  function download() {
    if (props.memberStore.member.hasTrial) {
      const element = document.getElementById("trial-feature-modal");
      const trialFeatureModal = Modal.getOrCreateInstance(element);
      trialFeatureModal.show();
    } else {
      setDownloading(true);
      props.memberStore.recordDownload({ mealPlanId: state.mealPlan.id });
      pdfStore.download();
    }
  }

  useEffect(() => {
    setExpandAll(false);
    setCollapseAll(false);
  }, [view]);

  useEffect(() => {
    if (expandAll) {
      setCollapseAll(false);
    }
  }, [expandAll]);

  useEffect(() => {
    if (collapseAll) {
      setExpandAll(false);
    }
  }, [collapseAll]);

  const rawRecipes = state.meals.recipes.concat(state.batchings.recipes.concat(state.nightOfMeals.recipes));

  function confirmDeleteCustomizations() {
    deleteCustomizations();
    const element = document.getElementById("reset-wp-modal");
    const resetModal = Modal.getOrCreateInstance(element);
    resetModal.hide();
  }

  function showResetPlanModal() {
    const element = document.getElementById("reset-wp-modal");
    const resetModal = Modal.getOrCreateInstance(element);
    resetModal.show();
  }

  function showPrepVideo(prepVideo) {
    setPrepVideo(prepVideo);
    const element = document.getElementById("video-modal");
    const videoModal = Modal.getOrCreateInstance(element);
    videoModal.show();
  }

  const stashItem = props.stashStore.items.filter((i) => i.documentType === documentTypes.WIZARD_PLAN).find((i) => i.documentId === state.mealPlan.id);

  const mealRecipes = state.meals.viewRecipes.map((meal, index) => (
    <MealPreview
      key={meal.id}
      stashStore={props.stashStore}
      modals={props.modals}
      recipe={meal}
      batchings={state.batchings.recipes}
      day={index + 1}
      specialScalingIds={specialScalingIds}
      show={() => history.push(`${location.pathname}?v=${views.MEAL}&id=${meal.id}`)}
      changePortions={changePortions}
      updateCheckboxes={updateCheckboxes}
    />
  ));

  const preps = state.prepInstructions.preps.map((p, index) => (
    <IngredientPreps key={index} i={index} prep={p} updatePrep={updatePrep} showVideo={showPrepVideo} />
  ));

  const batchingRecipes = state.batchings.viewRecipes.map((r, index) => (
    <AccordionRecipe
      key={r.id}
      index={index}
      member={props.memberStore.member}
      stashStore={props.stashStore}
      noteStore={props.noteStore}
      context={contexts.WIZARD_PLAN}
      recipe={r}
      recipes={rawRecipes}
      modals={props.modals}
      canShowActionButtons={true}
      batchingContext={true}
      canShowNutritionFacts={props.memberStore.member.preferences.showNutritionFacts}
      expandAll={expandAll}
      collapseAll={collapseAll}
      specialScalingIds={specialScalingIds}
      changeCookingMethod={changeCookingMethod}
      updateAdditionalPortions={updateChildRecipeAdditionalPortions}
      updateCheckboxes={updateCheckboxes}
      prepareDataForNutritionFacts={prepareDataForNutritionFacts}
      setExpandAll={setExpandAll}
      setCollapseAll={setCollapseAll}
    />
  ));

  const batchingWrap =
    state.batchings.viewRecipes.filter((r) => r.checkboxes.filter((x) => !x).length !== 0).length === 0 ? <p>That's a batching wrap</p> : null;

  function noteBadge(active) {
    let result = null;
    let notesCount = state.notes.length;
    const memberNote = props.noteStore.memberNotes.find((n) => n.documentId === state.mealPlan.id);
    // if (isNotEmpty(memberNote)) {
    //   notesCount = notesCount + 1;
    // }
    if (notesCount > 0) {
      result = (
        <>
          <span className={`d-none d-md-inline notes-badge-wp badge ${active ? "bg-white" : "bg-light"}`}>{notesCount}</span>
          <span className={`d-inline d-md-none notes-badge-wp badge ${active ? "bg-light" : "bg-white"}`}>{notesCount}</span>
        </>
      );
    }
    return result;
  }

  const mealsButton =
    view === views.MEALS ? (
      <button type="button" className="nav-link active" disabled={true}>
        <i className="fas fa-utensils-alt" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.MEALS)}</span>
      </button>
    ) : (
      <button type="button" className="nav-link" onClick={() => history.push(`${location.pathname}?v=${views.MEALS}`)}>
        <i className="fas fa-utensils-alt" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.MEALS)}</span>
      </button>
    );
  const batchingButton =
    view === views.BATCHING ? (
      <button type="button" className="nav-link active" disabled={true}>
        <i className="fas fa-bowl-spoon" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.BATCHING)}</span>
      </button>
    ) : (
      <button type="button" className="nav-link" onClick={() => history.push(`${location.pathname}?v=${views.BATCHING}`)}>
        <i className="fas fa-bowl-spoon" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.BATCHING)}</span>
      </button>
    );
  const ingredientButton =
    view === views.GROCERY_LIST ? (
      <button type="button" className="nav-link active" disabled={true}>
        <i className="fas fa-shopping-basket" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.GROCERY_LIST)}</span>
      </button>
    ) : (
      <button type="button" className="nav-link" onClick={() => history.push(`${location.pathname}?v=${views.GROCERY_LIST}`)}>
        <i className="fas fa-shopping-basket" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.GROCERY_LIST)}</span>
      </button>
    );
  const notesButton =
    view === views.NOTES ? (
      <button type="button" className="nav-link active position-relative" disabled={true}>
        <i className="notes-icon-wp fas fa-sticky-note" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.NOTES)}</span>
        {noteBadge(true)}
      </button>
    ) : (
      <button type="button" className="nav-link position-relative" onClick={() => history.push(`${location.pathname}?v=${views.NOTES}`)}>
        <i className="notes-icon-wp fas fa-sticky-note" /> <span className="ms-1 d-none d-sm-inline">{capitalizeFirstLetter(views.NOTES)}</span>
        {noteBadge(false)}
      </button>
    );

  function groceryList() {
    return (
      <>
        <GroceryListCategory
          icon={<i className="fa-solid fa-leafy-green me-1" />}
          name="Produce"
          ingredients={state.ingredientData.views.produce}
          updateIngredient={updateIngredient}
        />
        <GroceryListCategory
          icon={<i className="fa-solid fa-sack me-1" />}
          name="Bulk"
          ingredients={state.ingredientData.views.bulk}
          updateIngredient={updateIngredient}
        />
        <GroceryListCategory
          icon={<i className="fa-solid fa-mortar-pestle me-1" />}
          name="Spices and Herbs"
          ingredients={state.ingredientData.views.spicesHerbs}
          updateIngredient={updateIngredient}
        />
        <GroceryListCategory
          icon={<i className="fa-regular fa-can-food me-1" />}
          name="Packaged"
          ingredients={state.ingredientData.views.packaged}
          updateIngredient={updateIngredient}
        />
        <GroceryListCategory
          icon={<i className="fa-solid fa-cart-circle-check me-1" />}
          name="Items You Have"
          ingredients={state.ingredientData.views.itemsYouHave}
          updateIngredient={updateIngredient}
          uncheckAll={deleteIngredients}
        />
      </>
    );
  }

  function main() {
    let result;
    if (view === views.BATCHING) {
      result = (
        <div className="p-0 p-sm-3">
          <div className="row justify-content-between align-items-center p-2 pt-sm-1 px-sm-1 mb-2 my-auto">
            <div className="col-auto">
              <h2 className="h-alt mt-2 text-orange nav-heading">
                <i className="fas fa-bowl-spoon" /> Batching
              </h2>
            </div>

            <div className="col text-end">
              <button className="btn btn-white text-muted me-1" type="button" title="Collapse all" onClick={() => setCollapseAll(true)}>
                <i className="fa-solid fa-chevrons-up" />
              </button>
              <button className="btn btn-white text-muted" type="button" title="Expand all" onClick={() => setExpandAll(true)}>
                <i className="fa-solid fa-chevrons-down" />
              </button>
            </div>
          </div>

          <div className="accordion mb-3 preps orange-accordion">
            <AccordionItem
              id="preps"
              title={<span>Mise en Place (Prep)</span>}
              item={
                <table className="table table-borderless">
                  <tbody>{preps}</tbody>
                </table>
              }
            />
            {batchingRecipes}
          </div>
          {batchingWrap}
        </div>
      );
    } else if (view === views.MEAL && isNotEmpty(recipe)) {
      result = (
        <Recipe
          token={props.token}
          authenticated={props.authenticated}
          member={props.memberStore.member}
          stashStore={props.stashStore}
          context={contexts.WIZARD_PLAN}
          recipeId={recipe.recipes.length > 0 ? recipe.recipes[recipe.recipes.length - 1].id : null}
          recipes={recipe.recipes}
          rawRecipes={rawRecipes}
          childRecipeIds={state.childRecipeIds}
          deletedChildRecipes={state.customizations.deletedChildRecipes}
          reviewStore={props.reviewStore}
          noteStore={props.noteStore}
          modals={props.modals}
          canShowNutritionFacts={props.memberStore.member.preferences.showNutritionFacts}
          showRelatedContent={false}
          specialScalingIds={specialScalingIds}
          updatingCheckboxes={state.updatingCheckboxes}
          changePortions={changePortions}
          changeCookingMethod={changeCookingMethod}
          updateCheckboxes={updateCheckboxes}
          deleteChildRecipe={deleteChildRecipe}
          restoreChildRecipes={restoreChildRecipes}
          updateChildRecipeAdditionalPortions={updateChildRecipeAdditionalPortions}
          prepareDataForNutritionFacts={prepareDataForNutritionFacts}
          recordDownload={props.memberStore.recordDownload}
        />
      );
    } else if (view === views.GROCERY_LIST) {
      result = (
        <div id="grocery-list" className="p-0 py-sm-3 px-sm-3">
          <div className="px-2 pt-2 p-sm-0 d-flex justify-content-between align-items-center">
            <div>
              <h2 className="h-alt mt-2 mb-3 mb-sm-4 text-orange nav-heading">
                <i className="fas fa-shopping-basket" /> Groceries
              </h2>
            </div>
          </div>

          <div className="col">
            <CustomGroceryItems store={props.gStore} />
            {groceryList()}
          </div>
        </div>
      );
    } else if (view === views.NOTES) {
      result = (
        <div id="notes" className="p-2 py-sm-3 px-sm-3">
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h2 className="h-alt mt-2 mb-3 mb-sm-4 text-orange nav-heading">
                <i className="fas fa-sticky-note" /> Notes
              </h2>
            </div>
          </div>
          <Notes documentId={state.mealPlan.id} documentType={documentTypes.WIZARD_PLAN} store={props.noteStore} documentPublicNotes={state.notes} />
        </div>
      );
    } else {
      result = (
        <div className="p-0 py-sm-3 px-sm-4 wizard-meals">
          <div className="p-2 p-sm-0 d-flex justify-content-between align-items-center">
            <div>
              <h2 className="h-alt mt-2 mb-3 mb-sm-4 text-orange nav-heading">
                <i className="fas fa-utensils-alt" /> Meals
              </h2>
            </div>
          </div>
          {mealRecipes}
        </div>
      );
    }
    return result;
  }

  function render() {
    const pin = (
      <StashButton
        documentId={state.mealPlan.id}
        documentType={documentTypes.WIZARD_PLAN}
        smallButton={false}
        margin=" me-1 mb-1"
        title="Pin"
        active={stashItem === undefined ? false : stashItem.pinned}
        activeClassName="fas fa-thumbtack fa-fw"
        inactiveClassName="far fa-thumbtack fa-fw"
        toggle={props.stashStore.togglePin}
      />
    );
    const save = (
      <StashButton
        documentId={state.mealPlan.id}
        documentType={documentTypes.WIZARD_PLAN}
        smallButton={false}
        margin=" me-1 mb-1"
        title="Save"
        active={stashItem === undefined ? false : stashItem.saved}
        activeClassName="fa fa-folder-bookmark fa-fw"
        inactiveClassName="far fa-folder-bookmark fa-fw"
        toggle={props.stashStore.toggleSaved}
      />
    );
    const love = (
      <StashButton
        documentId={state.mealPlan.id}
        documentType={documentTypes.WIZARD_PLAN}
        smallButton={false}
        margin=" me-1 mb-1"
        title="Love"
        active={stashItem === undefined ? false : stashItem.loved}
        activeClassName="fa fa-heart fa-fw"
        inactiveClassName="far fa-heart fa-fw"
        toggle={props.stashStore.toggleLove}
      />
    );
    const addToMealPlannerButton = (
      <button
        className="btn btn-light me-1 mb-1"
        type="button"
        title="Add to Meal Planner"
        onClick={() =>
          props.modals.showAddToMealPlan({
            documentId: state.mealPlan.id,
            documentType: documentTypes.WIZARD_PLAN,
            name: splitHashtag(state.mealPlan.hashtag),
          })
        }
      >
        <span className="fa fa-plus fa-fw" />
      </button>
    );
    const downloadButton = downloading ? (
      <button className="btn btn-light me-1 mb-1" type="button" disabled={true}>
        <i className="fa-solid fa-download fa-fade fa-fw" />
      </button>
    ) : (
      <button className="btn btn btn-light me-1 mb-1" type="button" title="Download" onClick={download}>
        <i className="fa-solid fa-download fa-fw" />
      </button>
    );
    const resetButton = (
      <button className="btn btn-light me-1 mb-1" type="button" title="Reset Wizard Plan" onClick={showResetPlanModal}>
        <i className="fa-solid fa-arrows-rotate fa-fw" />
      </button>
    );
    return props.authenticated ? (
      <div className="container-fluid ps-md-0 pt-md-0">
        <Helmet>
          <link rel="canonical" href={`https://members.cleanfooddirtygirl.com/meal-plans/wizard-plans/${hashtag}?v=meals`} />
        </Helmet>
        <div id="content" className="row p-0">
          {/* desktop sidebar */}
          <div id="left-column" className="sidenav-wp shadow-sm col-12 col-md-3 col-lg-2 p-0 d-flex">
            <div className="d-flex bg-white flex-md-column flex-row flex-grow-1 align-items-center align-items-md-start px-0 pt-0 fixed-sidebar">
              <FullScreenImage
                opened={lightBoxOpened}
                image={state.mealPlan.photo}
                caption={state.mealPlan.description}
                onCloseRequest={() => setLightBoxOpened(false)}
              />
              <div className="d-none d-md-block d-lg-none text-center pt-md-1 pb-md-1 p-lg-0 mx-auto">
                <img
                  src={assetUrl(state.mealPlan.photo)}
                  alt={`Plant based meal plan ${splitHashtag(state.mealPlan.hashtag)}`}
                  className="rounded-circle cursor-pointer mt-4 poster d-print-none"
                  style={{ width: "120px", height: "120px" }}
                  onClick={() => setLightBoxOpened(true)}
                />
              </div>
              <div className="d-none d-lg-block text-center pt-md-2 mt-md-1">
                <img
                  src={assetUrl(state.mealPlan.photo)}
                  alt={`Plant based meal plan ${splitHashtag(state.mealPlan.hashtag)}`}
                  className="cursor-pointer w-100 mb-1 d-print-none w-100 poster"
                  onClick={() => setLightBoxOpened(true)}
                />
              </div>

              <div className="d-none d-md-block bd-highlight my-2 px-2 w-100 text-center text-lg-start">
                <div className="d-lg-flex justify-content-between">
                  <div className="p-0">
                    <ReviewStars
                      stars={state.stars}
                      cta={() =>
                        props.modals.showReviews({
                          documentId: state.mealPlan.id,
                          documentType: documentTypes.WIZARD_PLAN,
                          reviewsFor: `${splitHashtag(state.mealPlan.hashtag)}`,
                        })
                      }
                    />
                  </div>
                  <div className="p-0 d-print-none">
                    <ReviewTiming
                      timing={state.timing}
                      cta={() =>
                        props.modals.showReviews({
                          documentId: state.mealPlan.id,
                          documentType: documentTypes.WIZARD_PLAN,
                          reviewsFor: `${splitHashtag(state.mealPlan.hashtag)}`,
                        })
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="align-self-start d-none d-md-block">
                <h4 className="d-none d-md-block cursor-pointer ps-2 h-light mb-0" onClick={() => setLightBoxOpened(true)}>
                  {splitHashtag(state.mealPlan.hashtag)}
                </h4>
                <div className="pb-1 text-muted plan-type">{state.mealPlan.planType} Plan</div>
                <div className="ps-2 pt-1">
                  <div className="d-inline text-nowrap">
                    {addToMealPlannerButton}
                    {pin}
                  </div>
                  <div className="d-inline text-nowrap">
                    {save}
                    {love}
                  </div>
                </div>
              </div>

              <div className="nav-sidebar mt-md-1 mt-md-0">
                <ul
                  className="nav nav-pills flex-md-column flex-row flex-nowrap flex-shrink-1 flex-md-grow-0 flex-grow-1 mb-md-auto mb-0 end-0 mt-md-1 justify-content-center"
                  id="menu"
                >
                  <li className="nav-item">{mealsButton}</li>
                  {/* if we are viewing a meal, add a navigation element for that meal */}
                  {view === views.MEAL ? (
                    <li className="nav-item text-white text-end">
                      <div className="nav-link active bg-white nav-item-child">
                        <span className="d-none d-lg-inline">Viewing Meal </span>
                        <span className="d-none d-md-inline d-lg-none">Meal </span>
                        <span className="d-none d-sm-inline d-md-none">Meal </span>
                        {recipe.displayOrder}
                      </div>
                    </li>
                  ) : null}
                  <li className="nav-item">{batchingButton}</li>
                  <li className="nav-item">{ingredientButton}</li>
                  <li className="nav-item">{notesButton}</li>
                </ul>
              </div>
              <div className="d-none d-md-block ps-2 pt-2">
                {downloadButton}
                {resetButton}
              </div>
              <div className="d-none d-md-block px-md-2 pb-2 d-print-none">
                <div className="description mt-2 px-md-1 d-print-none">
                  <p className="ex-small">{shortenedText(state.mealPlan.description)}</p>
                </div>
              </div>
            </div>
          </div>

          {/* mobile header */}
          <div className="main-content-wp col d-flex flex-column">
            <main className="row">
              <div className="col fixed-content orange-accordion">
                {/* Special phone only section to show review stars and timing */}
                <div className="mobile-header-wp d-block d-md-none bg-white shadow-sm d-print-none mx-0 mb-2 px-0">
                  <div className="d-flex flex-row align-items-center">
                    <div className="px-2 d-print-none">
                      <img
                        src={assetUrl(state.mealPlan.photo)}
                        alt={`Plant based meal plan ${splitHashtag(state.mealPlan.hashtag)}`}
                        className="rounded-circle cursor-pointer mt-3 ms-2 plan-img-print"
                        style={{ width: "110px", height: "110px" }}
                        onClick={() => setLightBoxOpened(true)}
                      />
                    </div>
                    <div className="p-2 pb-0 w-100 px-2">
                      <h1 className="d-block d-md-none xs-intro mb-0">{splitHashtag(state.mealPlan.hashtag)}</h1>
                      <img
                        src={`https://assets.cleanfooddirtygirl.com/static/plant-fueled-life-grey-logo.jpeg`}
                        width="190"
                        className="watermark"
                        alt="watermark"
                      />
                      <div className="text-muted plan-type-mobile">{state.mealPlan.planType} Plan</div>
                      <div className="d-flex justify-content-between">
                        <div className="p-0">
                          <ReviewStars
                            stars={state.stars}
                            cta={() =>
                              props.modals.showReviews({
                                documentId: state.mealPlan.id,
                                documentType: documentTypes.WIZARD_PLAN,
                                reviewsFor: `${splitHashtag(state.mealPlan.hashtag)}`,
                              })
                            }
                          />
                        </div>
                        <div className="pe-3 d-print-none">
                          <ReviewTiming
                            timing={state.timing}
                            cta={() =>
                              props.modals.showReviews({
                                documentId: state.mealPlan.id,
                                documentType: documentTypes.WIZARD_PLAN,
                                reviewsFor: `${splitHashtag(state.mealPlan.hashtag)}`,
                              })
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="small mt-2 mb-1 pb-0 px-3 d-print-none">{state.mealPlan.description}</p>
                  <div className="nav-item pt-0 ps-2 pb-2 d-print-none">
                    <div className="ps-2 pt-1">
                      {addToMealPlannerButton}
                      {pin}
                      {save}
                      {love}
                    </div>
                    <div className="ps-2 pt-1">
                      {downloadButton}
                      {resetButton}
                    </div>
                  </div>
                </div>
                {main()}
              </div>

              <div id="video-modal" className="modal" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      {prepVideo === null ? null : <h1 className="modal-title">{prepVideo.ingredientPrepVideoTitle}</h1>}
                      <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                      {prepVideo === null ? null : (
                        <div className="ratio ratio-16x9">
                          <VimeoVideo vimeoId={prepVideo.ingredientPrepVimeoId} title={prepVideo.ingredientPrepVideoTitle} />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div id="reset-wp-modal" className="modal" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h1 className="modal-title h-alt">Reset Plan</h1>
                      <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                      <p>Resetting this plan will remove all checkmarks and restore the plan to default.</p>
                      <p>Would you like to proceed?</p>
                    </div>
                    <div className="modal-footer">
                      <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">
                        No, cancel
                      </button>
                      <button type="button" className="btn btn-secondary me-auto" onClick={confirmDeleteCustomizations}>
                        Yes, reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <MemberOnlyFeatureModal member={props.memberStore.member} />
              <Footer />
            </main>
          </div>
        </div>
      </div>
    ) : (
      /* Start public page */
      <div className="container-fluid p-0 p-sm-3 p-md-4 strip-padding">
        <Helmet>
          <link rel="canonical" href={`https://members.cleanfooddirtygirl.com/meal-plans/wizard-plans/${hashtag}?v=meals`} />
        </Helmet>
        <div className="row gx-0 justify-content-md-center recipe-wrap bg-white">
          <div className="col-md-5 bg-white">
            <img className="img-fluid" src={assetUrl(state.publicData.photo)} alt={splitHashtag(state.publicData.hashtag)} />
          </div>
          <div className="col-sm-12 col-md-7 p-3 p-md-4 my-auto">
            <ReviewStars
              stars={state.publicData.stars}
              cta={() =>
                props.modals.showReviews({
                  documentId: state.publicData.id,
                  documentType: documentTypes.WIZARD_PLAN,
                  reviewsFor: `${splitHashtag(state.publicData.hashtag)}`,
                })
              }
            />
            <h1>{splitHashtag(state.publicData.hashtag)}</h1>
            <h3 className="h-alt">Whole food plant based meal plan</h3>
            <p className="lead">{state.publicData.description}</p>
            <Link
              to={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=wp+trial&utm_term=${state.publicData.id}`}
              className="btn btn-primary btn-lg m-1 ms-0"
            >
              View meal plan with a free trial
            </Link>
            <p className="text-muted">
              <small>
                No credit card <em>or</em> perfection required.
              </small>
            </p>
          </div>
        </div>
        <div className="row gx-0 justify-content-md-center recipe-wrap bg-white mt-5">
          <div className="col-12 col-md-8 p-3">
            <div>
              <div className="public-reviews">
                <h3 className="h-alt mb-3">Wizard Plan reviews</h3>
                {publicReviews.length ? (
                  <ul className="list-group ratings">
                    {publicReviews.map((r) => (
                      <li key={r.id} className="list-group-item">
                        <Review review={r} memberReview={false} noPhotoLayout={isEmpty(r.photo)} />
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>
                    No reviews yet! Let’s change that.{" "}
                    <Link to={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=wp+trial&utm_term=${state.publicData.id}`}>
                      Make this meal plan with a free trial
                    </Link>
                    .
                  </p>
                )}
              </div>
              <h2 className="mt-4">Why can’t I see this meal plan?</h2>
              <p>
                Plant Fueled Life is a paid membership. We have hundreds of{" "}
                <a href="https://cleanfooddirtygirl.com/plant-based-meal-plans/" target="_blank">
                  plant based meal plans
                </a>{" "}
                and thousands of recipes waiting for you. We charge a monthly fee so our small team of women across the world can create and maintain our
                ever-growing library of yum. Start a free trial today and see you around!
              </p>

              <div className="card bg-light mt-5">
                <div className="card-body sales-card text-center">
                  <a
                    href="https://plantfueledlife.members-only.online/1?utm_campaign=guides&utm_source=portal&utm_medium=public&utm_content=beginners+guide"
                    target="_blank"
                  >
                    <img src={`${process.env.PUBLIC_URL}/images/Beginners-Guide-to-Eating-More-Plants.png`} alt="Download your free Beginner's Guide" />
                  </a>
                  <h2 className="card-title text-dark">New?</h2>
                  <p className="card-text mb-2">
                    Download your free Beginner's Guide and start the most delicious and rewarding journey of your life, fueled by plants.
                  </p>
                  <a
                    href="https://plantfueledlife.members-only.online/1?utm_campaign=guides&utm_source=portal&utm_medium=public&utm_content=beginners+guide"
                    className="btn btn-primary"
                    target="_blank"
                  >
                    Get your Guide
                  </a>
                </div>
              </div>
              {/* Bright card: Link to batching funnel */}
            </div>
          </div>
          <div className="col-12 col-md-4 p-3">
            <div>
              <div className="card bg-primary">
                <div className="card-body sales-card text-center">
                  <a
                    href="https://plantfueledlife.members-only.online/batching?utm_campaign=guides&utm_source=portal&utm_medium=public&utm_content=batching+handbook"
                    target="_blank"
                    className="whiteLink"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}/images/Find-Joy-in-Plant-Based-Cooking-With-Plant-Fueled-Life-Meal-Plans.png`}
                      alt="Download your free Beginner's Guide"
                    />
                  </a>
                  <h2 className="card-title text-white">About batch cooking</h2>
                  <p className="card-text mb-2 text-white">
                    Our mouthgasmic plant based meal plans are designed around batch cooking. Get your free Batching Handbook and dive in.
                  </p>
                  <a
                    href="https://plantfueledlife.members-only.online/batching?utm_campaign=guides&utm_source=portal&utm_medium=public&utm_content=batching+handbook"
                    className="btn btn-light btn-md"
                    target="_blank"
                  >
                    Get your free Handbook
                  </a>
                </div>
              </div>

              <h2 className="mt-4">Plant Fueled Life can help you</h2>
              <h5 className="h-alt">Become a confident plant based cook</h5>
              <p>Cook surprisingly delicious whole food plant based meals you and your (omni and plant based) family will enjoy.</p>
              <h5 className="h-alt">Create your own meal plans</h5>
              <p>Find the meals and recipes you want to make and easily add them to our Magic Meal Planner.</p>
              <h5 className="h-alt">Find joy in eating plant based</h5>
              <p>Experience the joy that bubbles up when the food you love loves you back.</p>
              <h5 className="h-alt">Crave healthy plant based meals</h5>
              <p>We'll show you how to truly prefer healthy plant based meals to anything else</p>
              <h5 className="h-alt">Stop being stressed about what to make</h5>
              <p>
                Over 4,000 of the most delicious plant based recipes you won't find anywhere else. Say goodbye to pesky ads and Pinterest fails. Say hello to
                your kitchen BFF.
              </p>
              {/* end right column -> */}
            </div>
          </div>
        </div>
        <AnonymousFooter />
        <AnonymousCallToActions
          tellMeMoreLink={`https://cleanfooddirtygirl.com/plant-fueled-life/?utm_campaign=join&utm_source=portal&utm_medium=button&utm_content=wp+tell+me+more&utm_term=${state.publicData.id}`}
          joinLink={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=wp+trial&utm_term=${state.publicData.id}`}
        />
      </div>
    );
  }

  return state.loading ? (
    <main className="text-center">
      <span className="spinner-border" />
    </main>
  ) : (
    render()
  );
}

WizardPlan.propTypes = {
  authenticated: PropTypes.bool,
  cache: PropTypes.object,
  token: PropTypes.string,
  memberStore: PropTypes.object,
  stashStore: PropTypes.object,
  reviewStore: PropTypes.object,
  noteStore: PropTypes.object,
  gStore: PropTypes.object,
  ss: PropTypes.object,
  modals: PropTypes.object,
  getPersonalizedContent: PropTypes.func,
};
