import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Renders a button that shows a spinner and cycles through texts when an activity is in progress.
 * When no activity is in progress, it displays a static text. If the button type is "submit", it does not attach an onClick handler.
 *
 * @param {Object} props - The props for the SnailButton component.
 * @param {string} props.className - The CSS class for the button.
 * @param {string} props.text - The button label when no activity is running.
 * @param {string} props.type - The type of the button. If type is "submit", there should not be any onClick handler.
 * @param {function} props.onClick - The function to call when the button is clicked (not applicable for type "submit").
 * @param {boolean} props.disabled - Disabled flag.
 * @param {boolean} props.working - A flag indicating whether an activity is in progress.
 * @param {string[]} props.workingTexts - The texts that will cycle while the activity is in progress.
 * @param {number} [props.delay=5000] - The delay in milliseconds for cycling through workingTexts. Defaults to 5000.
 * @returns {JSX.Element} The SnailButton component.
 */
export default function SnailButton({ className, text, type, onClick, disabled, working, workingTexts, delay = 5000 }) {
  const [epoch, setEpoch] = useState(0);
  const intervalRef = useRef(null);
  const handleClick = type === "submit" ? null : onClick; //just to be sure that submit button has no onClick handler

  useEffect(() => {
    if (working) {
      intervalRef.current = setInterval(() => {
        setEpoch((prev) => prev + 1);
      }, delay);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [working]);

  return (
    <button className={className} disabled={disabled} type={type} onClick={handleClick}>
      {working ? (
        <>
          <span className="spinner-border spinner-border-sm" />
          <span className="ms-1">{workingTexts[epoch % workingTexts.length]}</span>
        </>
      ) : (
        text
      )}
    </button>
  );
}

SnailButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  text: PropTypes.string,
  workingTexts: PropTypes.array,
  working: PropTypes.bool,
  delay: PropTypes.number,
};
