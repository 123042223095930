import React from "react";
import PropTypes from "prop-types";
import PublicMagicMealPlanSection from "./PublicMagicMealPlanSection";
import { Modal } from "bootstrap";
import MemberOnlyFeatureModal from "../../components/MemberOnlyFeatureModal";

export default function PublicMagicMealPlanSections(props) {
  const sections = props.mmpStore.state.publicPlan.sections.map((s) => <PublicMagicMealPlanSection key={s.id} stashStore={props.stashStore} mmpStore={props.mmpStore} section={s} />);

  function print() {
    if (props.member.hasTrial) {
      const element = document.getElementById("trial-feature-modal");
      const trialFeatureModal = Modal.getOrCreateInstance(element);
      trialFeatureModal.show();
    } else {
      window.print();
    }
  }

  const header = (
    <div className="row">
      <div className="col align-self-center">
        <h2 className="h-alt mt-2 mb-3 mb-sm-4 text-magic nav-heading ps-2">
          <i className="far fa-calendar-day" /> Plan
        </h2>
      </div>
    </div>
  );

  return (
    <div className="p-0 p-sm-3">
      {header}
      {sections}
      <MemberOnlyFeatureModal member={props.member} />
    </div>
  );
}

PublicMagicMealPlanSections.propTypes = {
  stashStore: PropTypes.object,
  mmpStore: PropTypes.object,
  member: PropTypes.object,
};
