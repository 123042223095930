import React from "react";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";

export default function Pager(props) {
  let history = useHistory();
  const increment = 24;

  function render() {
    let result = null;
    if (props.store.sectionTotal() > increment) {
      const previous = props.store.state.offset === 0 ? (
        <li className="page-item disabled">
          <span className="page-link"><i className="fa fa-less-than"/></span>
        </li>
      ) : (
        <li className="page-item">
          <span className="page-link cursor-pointer" onClick={() =>
            history.push(props.store.nextLocation(props.store.searchActions.PAGER, props.store.state.offset-increment))
          }><i className="fa fa-less-than"/></span>
        </li>
      );
      const currentPage = props.store.state.offset === 0 ? 1 : props.store.state.offset / increment + 1;
      const pages = Array(Math.ceil(props.store.sectionTotal() / increment)).fill().map((n, i) => i + 1);
      const keptPages = [];
      if (pages.length > 0) {
        let indices;
        let currentIndex = props.store.state.offset === 0 ? 0 : props.store.state.offset / increment;
        if (currentIndex+2 >= pages.length) {
          if (pages.length > 4) {
            // We keep the last five indices
            indices = pages.slice(pages.length-5, pages.length);
          } else {
            indices = pages.slice(0, 5);
          }
        } else if (currentIndex > 2) {
          if (pages.length > 4) {
            // We only keep two pages before and after the current page
            indices = pages.slice(currentIndex-2, currentIndex+3);
          } else {
            indices = pages.slice(0, 5);
          }
        } else {
          // We keep the first five indices
          indices = pages.slice(0, 5);
        }
        for (let i = 0; i < indices.length; i++) {
          if (indices[i] === currentPage) {
            keptPages.push(
              <li key={indices[i]} className="page-item active">
                <span className="page-link">{indices[i]}</span>
              </li>
            );
          } else {
            keptPages.push(
              <li key={indices[i]} className="page-item">
                <span className="page-link cursor-pointer" onClick={() => history.push(props.store.nextLocation(props.store.searchActions.PAGER, (indices[i]-1)*increment))}>{indices[i]}</span>
              </li>
            );
          }
        }
      }

      const next = props.store.sectionTotal() - props.store.state.offset <= increment ? (
        <li className="page-item disabled">
          <span className="page-link"><i className="fa fa-greater-than"/></span>
        </li>
      ) : (
        <li className="page-item">
          <span className="page-link cursor-pointer" onClick={() => history.push(props.store.nextLocation(props.store.searchActions.PAGER, props.store.state.offset+increment))}><i className="fa fa-greater-than"/></span>
        </li>
      );
      result = (
        <nav>
          <ul className="pagination pagination-lg justify-content-center">
            {previous}
            {keptPages}
            {next}
          </ul>
        </nav>
      );
    }
    return result;
  }
  return props.store.state.loading ? null : render();
}

Pager.propTypes = {
  store: PropTypes.object,
}
