import { Helmet } from "react-helmet";
import Helpers from "../../../services/Helpers";

export default function StandaloneRecipeContainer({ recipeStore, banner, children }) {
  const { makeUrlLookGood } = Helpers();
  const { standaloneRecipe, publicData, loading } = recipeStore.state;

  const id = standaloneRecipe.recipeId || publicData.id;
  const name = standaloneRecipe.recipeData.views.find((r) => r.id === standaloneRecipe.recipeId)?.name || publicData.name;

  return (
    <main>
      {banner}
      {loading ? (
        <div className="text-center">
          <span className="spinner-border" />
        </div>
      ) : (
        <>
          <Helmet>
            <link rel="canonical" href={`https://members.cleanfooddirtygirl.com/recipes/recipe-vault/${makeUrlLookGood(id, name)}`} />
          </Helmet>
          {children}
        </>
      )}
    </main>
  );
}
