import React, {useState} from "react";
import PropTypes from "prop-types";
import Note from "./Note";
import NewNoteForm from "./NewNoteForm";
import Helpers from "../../services/Helpers";
import EditNoteForm from "./EditNoteForm";

export default function Notes(props) {
  const {isEmpty} = Helpers();
  const [showEditForm, setShowEditForm] = useState(false);
  const [showPublicNotes, setShowPublicNotes] = useState(false);

  const memberNote = props.store.memberNotes.find(n => n.documentId === props.documentId);

  const publicNotesToggle = props.documentPublicNotes.length > 0 ? (
    <div className="form-check form-switch mt-2">
      <label className="form-check-label">
        <input
          className="form-check-input"
          type="checkbox"
          checked={showPublicNotes}
          onChange={e => setShowPublicNotes(e.target.checked)}
        /> Show public notes
      </label>
    </div>
  ) : null;

  function publicNotes() {
    let result = null;
    if (props.documentPublicNotes.length > 0) {
      let publicNotes;
      if (isEmpty(memberNote)) {
        publicNotes = props.documentPublicNotes.map(n => (
          <Note key={n.id} note={n} memberNote={false}/>
        ));
      } else {
        publicNotes = props.documentPublicNotes.filter(n => n.id !== memberNote.id).map(n => (
          <Note key={n.id} note={n} memberNote={false}/>
        ));
      }
      result = showPublicNotes ? (
        <div style={{maxHeight: "300px", overflow: "auto"}}>
          {publicNotes}
        </div>
      ) : null;
    }
    return result;
  }

  const newNoteForm = isEmpty(memberNote) ? (
    <NewNoteForm
      documentId={props.documentId}
      documentType={props.documentType}
      create={props.store.create}
    />
  ) : null;

  const editNoteForm = showEditForm ? (
    <EditNoteForm
      note={memberNote}
      update={props.store.update}
      hide={() => setShowEditForm(false)}
    />
  ) : null;

  return (
    <div className="mp-notes">
      <div className="note-form">
        {props.showTitle ? <h5>Notes</h5> : null}
        {newNoteForm}
        {editNoteForm}
        {publicNotesToggle}
        {isEmpty(memberNote) || showEditForm ? null : <Note note={memberNote} memberNote={true} edit={() => setShowEditForm(true)} destroy={props.store.destroy} />}
        {publicNotes()}
      </div>
    </div>
  );
}

Notes.propTypes = {
  store: PropTypes.object,
  documentId: PropTypes.number,
  documentType: PropTypes.string,
  documentPublicNotes: PropTypes.array,
  showTitle: PropTypes.bool,
}