import { DateTime } from "luxon";
import SubscriptionCancellationForm from "./SubscriptionCancellationForm";

export default function CancelSubscriptionModal({ memberStore, subStore, resetCancel, hideModal }) {
  const showForm = subStore.subscription.length > 0;

  const currentSub = subStore.subscription.length > 0 ? subStore.subscription[0] : null;
  const nextBillingDate = currentSub && currentSub.nextBillingDate ? DateTime.fromSeconds(currentSub.nextBillingDate) : null;
  const lastDay = nextBillingDate ? nextBillingDate.minus({ days: 1 }).toLocaleString() : "";

  return (
    <div id="cancel-subscription-modal" className="modal" tabIndex={-1} data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-lg">
        {showForm && (
          <SubscriptionCancellationForm
            hasMP={memberStore.member.hasMP}
            lastDay={lastDay}
            cancel={subStore.cancelSubscription}
            hide={() => hideModal("cancel-subscription-modal")}
            shouldReset={resetCancel}
          />
        )}
      </div>
    </div>
  );
}
