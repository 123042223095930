import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCcMastercard, faCcAmex, faCcVisa, faCcDiscover } from "@fortawesome/free-brands-svg-icons";

export default function CreditCardLogo({ type }) {
  switch (type) {
    case 1: // Visa
      return <FontAwesomeIcon icon={faCcVisa} size="2x" />;
    case 2: // Mastercard
      return <FontAwesomeIcon icon={faCcMastercard} size="2x" />;
    case 3: // American Express
      return <FontAwesomeIcon icon={faCcAmex} size="2x" />;
    case 4: // Discover
      return <FontAwesomeIcon icon={faCcDiscover} size="2x" />;
    case 6: // Diner's Club, JCB, UnionPay
      return <i className="fa-solid fa-credit-card-front other-card"></i>;
    default:
      return null;
  }
}
