import React, { useEffect, useReducer } from "react";
import Constants from "../../services/Constants";
import Helpers from "../../services/Helpers";

const actions = {
  LOAD_REVIEW: "loadReview",
  STARS: "stars",
  TIMING: "timing",
  TEXT: "text",
  OMNI_FRIENDLY: "omniFriendly",
  KID_FRIENDLY: "kidFriendly",
  SHOW_MESSAGE: "showMessage",
  WORKING: "working",
  RESET: "reset",
};
const initialState = {
  stars: 0,
  timing: null,
  text: "",
  omniFriendly: false,
  kidFriendly: false,
  showMessage: false,
  working: false,
};
export default function ReviewForm({ cache, store, review, hide }) {
  const { documentTypes, memberTags } = Constants();
  const { assetUrl, isNotEmpty } = Helpers();
  function reducer(state, action) {
    if (action.type === actions.LOAD_REVIEW) {
      return {
        stars: action.data.stars,
        timing: action.data.timing,
        text: action.data.text,
        omniFriendly: action.data.omniFriendly,
        kidFriendly: action.data.kidFriendly,
        showMessage: false,
        working: false,
      };
    } else if (action.type === actions.STARS) {
      return { ...state, stars: action.data.stars };
    } else if (action.type === actions.TIMING) {
      return { ...state, timing: action.data.timing };
    } else if (action.type === actions.TEXT) {
      return { ...state, text: action.data.text };
    } else if (action.type === actions.OMNI_FRIENDLY) {
      return { ...state, omniFriendly: action.data.omniFriendly };
    } else if (action.type === actions.KID_FRIENDLY) {
      return { ...state, kidFriendly: action.data.kidFriendly };
    } else if (action.type === actions.SHOW_MESSAGE) {
      return { ...state, showMessage: action.data.showMessage };
    } else if (action.type === actions.WORKING) {
      return { ...state, working: action.data.workingv };
    } else if (action.type === actions.RESET) {
      return initialState;
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (review !== undefined) {
      dispatch({
        type: actions.LOAD_REVIEW,
        data: {
          stars: review.stars,
          timing: review.timing,
          text: review.text,
          omniFriendly: review.tags.find((t) => t.tag === memberTags.OMNI_FRIENDLY) !== undefined,
          kidFriendly: review.tags.find((t) => t.tag === memberTags.KID_FRIENDLY) !== undefined,
        },
      });
    }
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    if (state.stars === 0) {
      dispatch({ type: actions.SHOW_MESSAGE, data: { showMessage: true } });
    } else {
      dispatch({ type: actions.WORKING, data: { working: true } });
      const tags = [];
      if (state.omniFriendly) {
        const tag = cache.tags.find((t) => t.tag === memberTags.OMNI_FRIENDLY);
        if (isNotEmpty(tag)) {
          tags.push({ id: tag.id, tag: tag.tag });
        }
      }
      if (state.kidFriendly) {
        const tag = cache.tags.find((t) => t.tag === memberTags.KID_FRIENDLY);
        if (isNotEmpty(tag)) {
          tags.push({ id: tag.id, tag: tag.tag });
        }
      }
      // Build a form with the photo and the review data
      const files = document.getElementById("photo-input").files;
      let data = new FormData();
      data.append(
        "review",
        JSON.stringify({
          documentId: store.documentData.documentId,
          documentType: store.documentData.documentType,
          stars: state.stars,
          timing: state.timing,
          text: state.text,
          tags: tags,
        })
      );
      if (files.length > 0) {
        data.append("content-type", files[0].type);
        data.append("file", files[0]);
      }
      if (review === undefined) {
        store.create(data, () => {
          hide();
        });
      } else {
        store.update(review.id, data, () => {
          hide();
          dispatch({ type: actions.RESET });
        });
      }
    }
  }

  function cancel(event) {
    if (event) {
      event.preventDefault();
    }
    hide();
    dispatch({ type: actions.RESET });
  }

  function message() {
    return state.showMessage ? (
      <div className="alert alert-primary" role="alert">
        You must select a number of stars to submit your review.
      </div>
    ) : null;
  }

  function starSelector() {
    let classNames = [];
    for (let i = 1; i < 6; i++) {
      classNames.push(i <= state.stars ? "fa fa-star review-stars" : "far fa-star review-stars");
    }
    return classNames.map((cn, i) => <i key={i} className={cn} aria-hidden="true" onClick={() => dispatch({ type: actions.STARS, data: { stars: i + 1 } })} />);
  }

  function timingSelector() {
    let classNames = [];
    for (let i = 1; i < 4; i++) {
      classNames.push(i <= state.timing ? "fa fa-clock" : "far fa-clock");
    }
    return classNames.map((cn, i) => (
      <span key={i}>
        <i className={cn} aria-hidden="true" onClick={() => dispatch({ type: actions.TIMING, data: { timing: i + 1 } })} />{" "}
      </span>
    ));
  }

  function wizardPlanPlanFields() {
    return (
      <div className="row pt-3">
        <div className="col">
          <h4>Rate your batch time</h4>
          <div className="time-rating-review cursor-pointer mx-auto">{timingSelector()}</div>
        </div>
      </div>
    );
  }

  const photoInput = (
    <div className="input-group mb-3">
      <input type="file" className="form-control" id="photo-input" accept="image/*" />
    </div>
  );

  return (
    <form onSubmit={handleSubmit}>
      {message()}
      <div className="row">
        <div className="col">
          <h4>Overall Rating</h4>
          <div className="cursor-pointer align-center leave-rating-stars">{starSelector()}</div>
        </div>
      </div>

      <div className="row gy-0">
        <div className="pt-4 col">
          <h4>Would you rate this as:</h4>
          <div className="form-check align-items-center">
            <label className="form-check-label pe-3">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                checked={state.omniFriendly}
                onChange={(e) => dispatch({ type: actions.OMNI_FRIENDLY, data: { omniFriendly: e.target.checked } })}
              />{" "}
              {memberTags.OMNI_FRIENDLY}
            </label>
            <label className="form-check-label ps-3">
              <input
                className="form-check-input cursor-pointer"
                type="checkbox"
                checked={state.kidFriendly}
                onChange={(e) => dispatch({ type: actions.KID_FRIENDLY, data: { kidFriendly: e.target.checked } })}
              />{" "}
              {memberTags.KID_FRIENDLY}
            </label>
          </div>
        </div>
      </div>

      {store.documentData.documentType === documentTypes.WIZARD_PLAN ? wizardPlanPlanFields() : null}

      <div className="row">
        <div className="review-text pt-4 col">
          <h4>So, tell us what you thought about it!</h4>
          <textarea className="form-control" rows={3} value={state.text} onChange={(e) => dispatch({ type: actions.TEXT, data: { text: e.target.value } })} />
        </div>
      </div>

      <div className="row pt-4">
        <h4>Did you take a picture you want to share?</h4>
        {review && review.photo ? (
          <>
            <div className="col-12">{photoInput}</div>
            <div className="col-12">
              <img src={assetUrl(review.photo)} alt="Review" style={{ width: "100%", aspectRatio: "1/1", maxWidth: "300px" }} />
            </div>
          </>
        ) : (
          <div className="col-12">{photoInput}</div>
        )}
      </div>

      <div className="mb-0 mt-4">
        {state.working ? (
          <button type="button" className="btn btn-outline-secondary me-2" disabled={true}>
            Cancel
          </button>
        ) : (
          <button type="button" className="btn btn-outline-secondary me-2" onClick={cancel}>
            Cancel
          </button>
        )}

        {state.working ? (
          <button type="button" className="btn btn-primary" disabled={true}>
            <span className="spinner-border spinner-border-sm" />
            <span className="ms-1">Sending...</span>
          </button>
        ) : (
          <button type="submit" className="btn btn-secondary btn-submit-review">
            Submit review
          </button>
        )}
      </div>
    </form>
  );
}
