import "./Testimonials.css";
import React, {useEffect} from "react";
import {Carousel} from "bootstrap";
import Helpers from "../../services/Helpers";

export default function Testimonials() {
  const {isNotEmpty} = Helpers();

  useEffect(() => {
    const element = document.getElementById("testimonials-carousel");
    console.log(element)
    if (isNotEmpty(element)) {
      const carousel = Carousel.getOrCreateInstance(element);
    }
  }, []);
  return (
    <div id="testimonials-carousel" className="carousel carousel-dark slide" data-bs-interval="false">
      <div className="carousel-inner">
        <div className="carousel-item rounded px-3 active">
          <div className="row p-2">
            <div className="col-3 px-0">
              <img className="w-100" src={`${process.env.PUBLIC_URL}/images/andrea.png`} alt="Andrea Voon" />
            </div>
            <div className="col">
              <div className="testimonial-text">
                The community, resources, and Meal Plans make it so easy for me to stay on track! My A1C is now 5.9, just within the "normal" range, and my blood pressure and cholesterol levels are excellent.
              </div>
              <div className="testimonial-text text-muted mt-1">
                Andrea Voon
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item rounded px-3">
          <div className="row p-2">
            <div className="col-3 px-0">
              <img className="w-100" src={`${process.env.PUBLIC_URL}/images/jeff.png`} alt="Jeff Brewster" />
            </div>
            <div className="col">
              <div className="testimonial-text">
                I am new to this lifestyle and loving every minute of it. I am a married father of five. I need to be here for them and this has given me the energy I need and I have that pep in my step again!!!
              </div>
              <div className="testimonial-text text-muted mt-1">
                Jeff Brewster
              </div>
            </div>
          </div>
        </div>
        <div className="carousel-item rounded px-3">
          <div className="row p-2">
            <div className="col-3 px-0">
              <img className="w-100" src={`${process.env.PUBLIC_URL}/images/cheryl.png`} alt="Cheryl A." />
            </div>
            <div className="col">
              <div className="testimonial-text">
                It's been such a blessing that I stumbled on this site. Not only the recipes and meal plans but all the other members are such an inspiration. Couldn't be happier.
              </div>
              <div className="testimonial-text text-muted mt-1">
                Cheryl A.
              </div>
            </div>
          </div>
        </div>

      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#testimonials-carousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#testimonials-carousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
}
