import { useEffect, useState } from "react";
import pdfMake from "pdfmake/build/pdfmake";
import vfs from "./vfs_fonts";
import axios from "axios";
import Constants from "./Constants";
import Helpers from "./Helpers";

const styles = {
  HEADER_1: { fontSize: 24, bold: true, margin: [0, 0, 0, 10] },
  HEADER_2: { fontSize: 18, bold: true, margin: [0, 10, 0, 5] },
  HEADER_3: { fontSize: 14, bold: true, margin: [0, 10, 0, 5] },
  SMALL: { fontSize: 10 },
  TINY: { fontSize: 8 },
}
export default function usePDF(preferences, documentType, data) {
  const { documentTypes, recipeComponentCategories, recipeTypes } = Constants();
  const { assetUrl, isEmpty, isNotEmpty, safeFileName, splitHashtag } = Helpers();
  const [images, setImages] = useState([]);
  const [ready, setReady] = useState(false);
  const [fileDownloaded, setFileDownloaded] = useState(false);

  useEffect(() => {
    if (ready) {
      preparePDF();
    }
  }, [ready]);

  useEffect(() => {
    if (images.length > 0) {
      if (isNotEmpty(images.find(i => !i.ready))) {
        downloadImage();
      } else {
        setReady(true);
      }
    }
  }, [images]);

  function reset() {
    setImages([]);
    setReady(false);
    setFileDownloaded(false);
  }

  function downloadImage() {
    const image = images.find(i => !i.ready);
    if (isNotEmpty(image)) {
      const newImages = images.filter(i => i.path !== image.path);
      const [imageName, imageExtension] = image.path.split(/\.(?=[^.]+$)/);
      const jsPath = `${imageName}_js.${imageExtension}`;
      axios.get(jsPath, { responseType: "blob" }).then(res => {
        const reader = new window.FileReader();
        reader.readAsDataURL(res.data);
        reader.onload = () => {
          image.data = reader.result;
          image.ready = true;
          newImages.push(image);
          setImages(newImages);
        };
      }
      ).catch(() => {
        console.log("Could not get image.");
        image.ready = true;
        newImages.push(image);
        setImages(newImages);
      });
    }
  }

  function download() {
    if (preferences.excludePicturesFromPDF) {
      // still add PFL logo if pictures are excluded
      const images = [];
      images.unshift({ path: "https://assets.cleanfooddirtygirl.com/logos/pfl.png", data: null, ready: false });
      setImages(images);
    } else {
      if (documentType === documentTypes.WIZARD_PLAN) {
        const recipes = data.meals.viewRecipes.concat(data.batchings.viewRecipes.concat(data.nightOfMeals.viewRecipes));
        const images = recipes.filter(r => isNotEmpty(r.photo)).map(r => { return { path: assetUrl(r.photo), data: null, ready: false } });
        images.unshift({ path: "https://assets.cleanfooddirtygirl.com/logos/pfl.png", data: null, ready: false });
        images.push({ path: assetUrl(data.mealPlan.photo), data: null, ready: false });
        setImages(images);
      } else if (documentType === documentTypes.RECIPE) {
        const images = data.childRecipes.concat(data.recipe).filter(r => isNotEmpty(r.photo)).map(r => { return { path: assetUrl(r.photo), data: null, ready: false } });
        images.unshift({ path: "https://assets.cleanfooddirtygirl.com/logos/pfl.png", data: null, ready: false });
        setImages(images);
      }
    }
  }

  function preparePDF() {
    if (documentType === documentTypes.WIZARD_PLAN) {
      prepareWizardPlanPDF();
    } else if (documentType === documentTypes.RECIPE) {
      prepareRecipePDF();
    }
  }

  function prepareWizardPlanPDF() {
    let fileName = wizardPlanFileName();
    const content = [];

    // Intro
    content.push({
      text: splitHashtag(data.mealPlan.hashtag),
      style: styles.HEADER_1,
      alignment: "center",
      link: `https://members.cleanfooddirtygirl.com/meal-plans/wizard-plans/${data.mealPlan.hashtag.toLowerCase()}`,
    });
    const planImage = images.find(i => i.path.indexOf(data.mealPlan.photo) !== -1);
    content.push("\n");
    if (isNotEmpty(planImage)) {
      const columns = [
        { image: planImage.data, width: 200 },
        { text: data.mealPlan.description, width: "50%" }
      ];
      content.push({ columns: columns, columnGap: 40 });
    } else if (isNotEmpty(data.mealPlan.description)) {
      content.push({ text: data.mealPlan.description });
    }
    for (let meal of data.meals.viewRecipes) {
      if (meal.portions > 0) {
        content.push([
          "\n",
          { text: `${meal.displayOrder} - ${meal.name}`, alignment: "left", style: styles.HEADER_3 },
        ]);
      }
    }
    content.push("\n");
    content.push("\n");
    content.push({
      toc: { numberStyle: { bold: true } },
    });

    // Grocery List
    content.push({
      pageBreak: "before",
      text: "GROCERY LIST",
      style: styles.HEADER_2,
      tocItem: true,
    });
    const weightHelpText = "The weight in parentheses after each ingredient is the TOTAL weight you will need to purchase for the meal plan. On the right side for each ingredient is the PREPPED weight you will use in recipes.";
    groceryList(content, data.ingredientData.views, weightHelpText);

    // Prep Instructions
    if (!preferences.excludePrepInstructionsFromPDF) {
      content.push({
        pageBreak: "before",
        text: "PREP INSTRUCTIONS",
        style: styles.HEADER_2,
        tocItem: true,
      });
      content.push("\n");
      const body = [];
      for (let ingredientInstructions of data.prepInstructions.preps) {
        for (let i = 0; i < ingredientInstructions.instructions.length; i++) {
          const row = [];
          if (i === 0) {
            row.push({
              rowSpan: ingredientInstructions.instructions.length,
              text: ingredientInstructions.ingredientName,
              bold: true,
              italics: true,
            });
          }
          const text = [`[_] ${ingredientInstructions.instructions[i].text}`];
          if (isNotEmpty(ingredientInstructions.instructions[i].additionalInstruction)) {
            text.push({
              text: ` ${" " +
                ingredientInstructions.instructions[i].additionalInstruction}`,
              italics: true,
            });
          }
          if (i === 0) {
            row.push({ text: text });
            row.push(ingredientInstructions.instructions[i].recipeName);
          } else {
            row.push("");
            row.push({ text: text });
            row.push(ingredientInstructions.instructions[i].recipeName);
          }
          body.push(row);
        }
      }
      if (body.length > 0) {
        content.push({
          table: {
            dontBreakRows: true,
            widths: ["30%", "50%", "20%"],
            body: body,
          },
        });
      }
    }

    // Batchings
    if (!preferences.excludeBatchingFromPDF) {
      content.push({
        pageBreak: "before",
        text: "BATCHING",
        style: styles.HEADER_2,
        tocItem: true,
      });
      for (let recipe of data.batchings.viewRecipes) {
        content.push("\n");
        childRecipe(content, documentTypes.WIZARD_PLAN, recipe, true);
      }
    }

    // Meals
    content.push({
      pageBreak: "before",
      text: "MENU",
      style: styles.HEADER_2,
      tocItem: true,
    });
    for (let recipe of data.meals.viewRecipes) {
      content.push("\n");
      menuMealRecipe(recipe, content, data.batchings.recipes);
    }

    const docDefinition = wizardPlanDefinition(fileName, content);
    pdfMake.fonts = {
      IBMPlexSerif: {
        normal: "IBMPlexSerif-Regular.ttf",
        bold: "IBMPlexSerif-Bold.ttf",
        italics: "IBMPlexSerif-Italic.ttf",
        bolditalics: "IBMPlexSerif-BoldItalic.ttf",
      },
    };
    pdfMake.vfs = vfs;
    pdfMake.createPdf(docDefinition).download(fileName, () => {
      setFileDownloaded(true);
    });
  }

  function prepareRecipePDF() {
    const content = [];
    const leftColumn = [];
    leftColumn.push({
      text: data.recipe.name,
      style: styles.HEADER_1,
    });
    if (data.recipe.allergens.length > 0) {
      leftColumn.push({
        text: "Contains: " + data.recipe.allergens.join(", "),
        margin: [0, 5, 0, 0],
      });
    }
    leftColumn.push({
      text: `Servings: ${data.recipe.customPortions}`,
      bold: true,
      margin: [0, 5, 0, 0],
    });
    if (isNotEmpty(data.recipe.yield)) {
      leftColumn.push({
        text: `Yield: ${data.recipe.yield}`,
        bold: true,
        margin: [0, 5, 0, 0],
      });
    }
    const image = images.find(i => i.path === assetUrl(data.recipe.photo));
    if (isNotEmpty(image) && isNotEmpty(image.data)) {
      const columns = [
        leftColumn,
        { image: image.data, width: 250 }
      ];
      content.push({
        unbreakable: true,
        stack: [
          { columns: columns, columnGap: 10 }
        ]
      });
    } else {
      const columns = [
        leftColumn
      ];
      content.push({
        unbreakable: true,
        stack: [
          { columns: columns, columnGap: 10 }
        ]
      });
    }
    for (let r of data.childRecipes) {
      content.push("\n\n");
      childRecipe(content, documentTypes.RECIPE, r, true);
    }

    if (data.recipe.type === recipeTypes.ASSEMBLY) {
      if (isNotEmpty(data.recipe.instructions)) {
        content.push({ text: "Put it all together", style: styles.HEADER_2 });
        for (let c of data.recipe.components) {
          recipeComponent(content, c);
        }
        content.push("\n");
        markdownContent(content, data.recipe.instructions);
      }
    } else {
      content.push("\n");
      if (data.childRecipes.length > 1) {
        content.push({ text: data.recipe.name, style: styles.HEADER_2 });
        content.push("\n");
      }
      componentsAndInstructions(content, data.recipe, false);
    }

    const docDefinition = recipeDefinition(data.recipe.name, content);
    pdfMake.fonts = {
      IBMPlexSerif: {
        normal: "IBMPlexSerif-Regular.ttf",
        bold: "IBMPlexSerif-Bold.ttf",
        italics: "IBMPlexSerif-Italic.ttf",
        bolditalics: "IBMPlexSerif-BoldItalic.ttf",
      },
    };
    pdfMake.vfs = vfs;
    pdfMake.createPdf(docDefinition).download(`${safeFileName(data.recipe.name)}.pdf`, () => {
      setFileDownloaded(true);
    });
  }

  function groceryList(content, list, weightHelpText) {
    content.push("\n");
    content.push({ fontSize: 10, text: weightHelpText, italics: true });
    const categories = [
      { name: "Produce", ingredients: list.produce },
      { name: "Bulk", ingredients: list.bulk },
      { name: "Spices and Herbs", ingredients: list.spicesHerbs },
      { name: "Packaged", ingredients: list.packaged },
      { name: "Items you have", ingredients: list.itemsYouHave }
    ]
    for (let category of categories) {
      let includeCategory = false;
      if (category.name === "Items you have") {
        if (!preferences.excludeGroceryListItemsYouHaveFromPDF && category.ingredients.length > 0) {
          includeCategory = true;
        }
      } else {
        if (category.ingredients.length > 0) {
          includeCategory = true;
        }
      }
      if (includeCategory) {
        content.push("\n");
        content.push({ text: category.name, bold: true });
        const body = [];
        for (let ingredient of category.ingredients) {
          const row = [];
          const checkbox = category.name === "Items you have" ? "[X]" : "[_]"; // pdfmake doesn't support emoji, it renders an empty box because it fails
          const text = isEmpty(ingredient.subNote) ?
            `${checkbox} ${ingredient.name} - ${ingredient.total}` :
            `${checkbox} ${ingredient.name} - ${ingredient.total} - ${ingredient.subNote}`;
          row.push({ border: [false, false, false, true], margin: [0, 5, 0, 5], text: text });
          const ul = [];
          for (let appearance of ingredient.appearances) {
            const quantity = isEmpty(appearance.subNote) || !ingredient.showAppearancesSubNotes ?
              appearance.quantity :
              `${appearance.quantity} - ${appearance.subNote}`;
            ul.push({ text: quantity, style: styles.SMALL });
          }
          row.push({ border: [false, false, false, true], margin: [0, 5, 0, 5], type: "circle", ul: ul });
          body.push(row);
        }
        content.push({
          table: { dontBreakRows: true, widths: ["50%", "50%"], body: body },
        });
      }
    }
  }

  function menuMealRecipe(meal, content, batchingRecipes) {
    const recipe = meal.recipes.find(r => r.id === meal.id);
    const childRecipes = meal.recipes.filter(r => r.id !== meal.id);
    const image = images.find(i => i.path === assetUrl(recipe.photo));
    if (isNotEmpty(image) && isNotEmpty(image.data)) {
      const leftColumn = [];
      leftColumn.push({ text: `${meal.displayOrder} - ${recipe.name}`, style: styles.HEADER_1 });
      if (recipe.allergens.length > 0) {
        leftColumn.push({ text: "Contains: " + recipe.allergens.join(", "), margin: [0, 5, 0, 0] });
      }
      leftColumn.push({ text: `Servings: ${meal.portions}`, bold: true, margin: [0, 5, 0, 0] });
      if (isNotEmpty(recipe.yield)) {
        leftColumn.push({ text: `Yield: ${recipe.yield}`, bold: true, margin: [0, 5, 0, 0] });
      }
      const columns = [leftColumn, { image: image.data, width: 250 }];
      content.push({ unbreakable: true, stack: [{ columns: columns, columnGap: 10 }] });
    } else {
      content.push({ text: `${meal.displayOrder} - ${recipe.name}`, style: styles.HEADER_1 });
      if (recipe.allergens.length > 0) {
        content.push({ text: "Contains: " + recipe.allergens.join(", "), margin: [0, 5, 0, 0] });
      }
      content.push({ text: `Servings: ${recipe.customPortions}`, bold: true, margin: [0, 5, 0, 0] });
      if (isNotEmpty(recipe.yield)) {
        content.push({ text: `Yield: ${recipe.yield}`, bold: true });
      }
    }

    if (isNotEmpty(recipe.notesAndTips)) {
      content.push("\n");
      markdownContent(content, `Note: ${recipe.notesAndTips}`);
      content.push("\n");
    } else {
      content.push("\n");
    }

    for (let c of recipe.components) {
      recipeComponent(content, c);
    }

    content.push("\n");

    for (let r of childRecipes) {
      if (r.usedIn.length > 0 && batchingRecipes.find(b => b.id === r.id) === undefined) {
        content.push({ text: r.name, bold: true });
        if (isNotEmpty(r.yield)) {
          content.push({ text: `Yield: ${r.yield}`, bold: true });
        }
        if (r.tools.length > 0) {
          const joinedToolNames = r.tools.map(t => { return t.name; }).join(", ");
          content.push({ text: joinedToolNames });
        }
        if (isNotEmpty(r.notesAndTips)) {
          markdownContent(content, `Note: ${r.notesAndTips}`);
          content.push("\n");
        }
        for (let c of r.components) {
          recipeComponent(content, c);
        }
        content.push("\n");
        if (isNotEmpty(r.instructions)) {
          markdownContent(content, r.instructions);
          content.push("\n");
        }
      }
    }

    if (isNotEmpty(recipe.instructions)) {
      content.push({ text: "Put it all together", bold: true });
      markdownContent(content, recipe.instructions);
    }
  }

  function childRecipe(content, documentType, recipe, showPhoto) {
    const header = [];
    header.push({ text: recipe.name, style: styles.HEADER_2 });
    if (documentType === documentTypes.WIZARD_PLAN) {
      const usedIn = [];
      for (let usage of recipe.usedIn) {
        usedIn.push(usage.name);
      }
      header.push({ ul: usedIn });
    }
    content.push({ unbreakable: true, stack: [{ columns: header }] });
    content.push({ text: `Yield: ${recipe.yield}`, bold: true });
    if (recipe.tools.length > 0) {
      const joinedToolNames = recipe.tools.map(t => { return t.name; }).join(", ");
      content.push({ text: joinedToolNames });
    }
    if (isNotEmpty(recipe.notesAndTips)) {
      markdownContent(content, `Note: ${recipe.notesAndTips}`);
      content.push("\n");
    } else {
      content.push("\n");
    }
    componentsAndInstructions(content, recipe, showPhoto);
  }

  function componentsAndInstructions(content, recipe, showPhoto) {
    if (recipe.hasTwoVersions) {
      let text = "Instant Pot Directions";
      if (recipe.isStoveTopVersion) {
        text = "Stove Top Directions";
      }
      content.push({ text: text, bold: true });
    }
    if (showPhoto) {
      const image = images.find(i => i.path === assetUrl(recipe.photo));
      if (isNotEmpty(image) && isNotEmpty(image.data)) {
        const leftColumn = [];
        for (let c of recipe.components) {
          recipeComponent(leftColumn, c);
        }
        const columns = [leftColumn, { image: image.data, width: 250 }];
        content.push({ stack: [{ columns: columns, columnGap: 10 }] });
      } else {
        for (let c of recipe.components) {
          recipeComponent(content, c);
        }
      }
    } else {
      for (let c of recipe.components) {
        recipeComponent(content, c);
      }
    }

    content.push("\n");
    if (isNotEmpty(recipe.instructions)) {
      markdownContent(content, recipe.instructions);
      content.push("\n");
    }
  }

  function recipeComponent(content, component) {
    if (component.category === recipeComponentCategories.BLANK_LINE) {
      content.push("\n");
    } else if (component.category === recipeComponentCategories.TEXT_ONLY) {
      markdownContent(content, component.text);
    } else {
      const text = [];
      let name;
      if (component.category === recipeComponentCategories.INGREDIENT) {
        name = isEmpty(component.invertedName) ? component.name : component.invertedName;
      } else {
        name = component.name;
      }
      if (isEmpty(component.volume) && isNotEmpty(component.weight)) {
        name = `${component.weight} ${name}`;
      } else if (isNotEmpty(component.volume)) {
        name = `${component.volume} ${name}`;
      }
      text.push(name);
      if (isNotEmpty(component.prepInstruction)) {
        const commaOrNot = ["juice", "zest", "brine"].indexOf(component.prepInstruction) !== -1 ? " " : ", ";
        text.push(`${commaOrNot}${component.prepInstruction}`);
      }
      if (isNotEmpty(component.additionalInstruction)) {
        text.push(` ${component.additionalInstruction}`);
      }
      if (isNotEmpty(component.volume) && isNotEmpty(component.weight)) {
        text.push(` (${component.weight})`);
      }
      content.push({
        text: text,
      });
    }
  }

  function markdownContent(content, data) {
    const sections = [];
    const lines = data.split("\n").map(l => l.trim());
    let keepSectionOpen = false;
    for (let line of lines) {
      if (line.startsWith("* ")) {
        if (keepSectionOpen) {
          sections[sections.length - 1].content.push(line.replace("* ", ""));
        } else {
          keepSectionOpen = true;
          const section = { type: "list", content: [line.replace("* ", "")] };
          sections.push(section);
        }
      } else {
        keepSectionOpen = false;
        const section = { type: "paragraph", content: [line] };
        sections.push(section);
      }
    }
    for (let [index, section] of sections.entries()) {
      if (index > 0 && content.length > 0 && content[content.length - 1] !== "\n") {
        content.push("\n");
      }
      if (section.type === "paragraph") {
        const text = [];
        const textParts = markdownToTextParts(section.content[0]);
        for (let part of textParts) {
          if (part.bold && part.italics) {
            text.push({ text: part.text.replace("**_", "").replace("_**", ""), bold: true, italics: true });
          } else if (part.bold) {
            text.push({ text: part.text.replace(/\*\*/g, ""), bold: true });
          } else if (part.italics) {
            text.push({ text: part.text.replace(/_/g, ""), italics: true });
          } else {
            text.push(part.text);
          }
        }
        if (text.length > 0) {
          content.push({ text: text });
        }
      } else if (section.type === "list") {
        const ul = [];
        for (let item of section.content) {
          const text = [];
          const textParts = markdownToTextParts(item);
          for (let part of textParts) {
            if (part.bold && part.italics) {
              text.push({ text: part.text.replace("**_", "").replace("_**", ""), bold: true, italics: true });
            } else if (part.bold) {
              text.push({ text: part.text.replace(/\*\*/g, ""), bold: true });
            } else if (part.italics) {
              text.push({ text: part.text.replace(/_/g, ""), italics: true });
            } else {
              text.push(part.text);
            }
          }
          ul.push({ text: text, margin: [0, 2, 0, 2] });
        }
        content.push({ ul: ul });
      }
    }
  }

  function markdownToTextParts(text) {
    const textParts = [];
    let willBeBold = false;
    let wasBold = false;
    let bold = false;
    let italics = false;
    for (let x = 0; x < text.length; x++) {
      if (["*", "_"].indexOf(text[x]) === -1) {
        if (textParts.length > 0) {
          const part = textParts[textParts.length - 1];
          if (part.bold === bold && part.italics === italics) {
            textParts[textParts.length - 1].text += text[x];
          } else {
            textParts.push({ text: text[x], bold: bold, italics: italics });
          }
        } else {
          textParts.push({ text: text[x], bold: bold, italics: italics });
        }
      } else {
        if (text[x] === "*") {
          if (bold) {
            bold = false;
            wasBold = true;
          } else if (wasBold) {
            wasBold = false;
          } else if (willBeBold) {
            willBeBold = false;
            bold = true;
          } else {
            willBeBold = true;
          }
        } else if (text[x] === "_") {
          italics = !italics;
        }
      }
    }
    return textParts;
  }

  function wizardPlanFileName() {
    let result = "";
    if (isNotEmpty(data.mealPlan.hashtag)) {
      result = `${data.mealPlan.hashtag}_WizardPlan.pdf`;
    }
    return result;
  }

  function wizardPlanDefinition(title, content) {
    const logo = images.find(i => i.path.indexOf("pfl") !== -1);
    return {
      info: { title: title, author: "Clean Food Dirty Girl" },
      pageSize: "LETTER",
      header: isNotEmpty(logo) ? { image: logo.data, width: 60, alignment: "center", margin: [10, 5] } : "",
      footer: (currentPage, pageCount) => {
        const columns = [
          { text: "members.cleanfooddirtygirl.com", link: "https://members.cleanfooddirtygirl.com" },
          { text: `${currentPage.toString() + " of " + pageCount}`, alignment: "right" }
        ];
        return { margin: [10, 5], fontSize: 10, columns: columns };
      },
      content: content,
      watermark: { text: 'Plant Fueled Life', color: 'black', opacity: 0.1, bold: true, italics: false, angle: 0 },
      defaultStyle: { font: "IBMPlexSerif" },
    };
  }

  function recipeDefinition(title, content) {
    const logo = images.find(i => i.path.indexOf("pfl") !== -1);
    return {
      info: { title: title, author: "Clean Food Dirty Girl" },
      pageSize: "LETTER",
      header: isNotEmpty(logo) ? { image: logo.data, width: 60, alignment: "center", margin: [10, 5] } : "",
      footer: (currentPage, pageCount) => {
        const columns = [
          { text: "members.cleanfooddirtygirl.com", link: "https://members.cleanfooddirtygirl.com" },
          { text: `${currentPage.toString() + " of " + pageCount}`, alignment: "right" }
        ];
        return { margin: [10, 5], fontSize: 10, columns: columns };
      },
      content: content,
      watermark: { text: 'Plant Fueled Life', color: 'black', opacity: 0.1, bold: true, italics: false, angle: 0 },
      defaultStyle: { font: "IBMPlexSerif" },
    };
  }

  const pdfStore = {
    fileDownloaded: fileDownloaded,
    download: download,
    reset: reset,
  }

  return { pdfStore };
}