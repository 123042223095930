import React from "react";
import PropTypes from "prop-types";

export default function ActiveFilter(props) {
  return (
    <button
      className="btn btn-white me-1 mb-1"
      type="button"
      disabled={props.disabled}
      onClick={props.remove}
    >
      <i className="fas fa-times"/> {props.filter}
    </button>
  );
}

ActiveFilter.propTypes = {
  filter: PropTypes.string,
  disabled: PropTypes.bool,
  remove: PropTypes.func,
}