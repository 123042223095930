import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Collapse } from "bootstrap";
import Checkcircle from "../../components/Checkcircle";
import Helpers from "../../../services/Helpers";
import NotesAndTips from "./NotesAndTips";
import RecipeDetails from "./RecipeDetails";
import YieldAndLeftovers from "./YieldAndLeftovers";
import RecipeComponent from "../../components/RecipeComponent";
import RecipeInstruction from "./RecipeInstruction";
import MarkdownViewer from "../../components/MarkdownViewer";
import ReviewStars from "../../components/ReviewStars";
import Constants from "../../../services/Constants";
import FullScreenImage from "./FullScreenImage";
import Notes from "../../components/Notes";
import { useHistory } from "react-router-dom";
import StashButton from "../../Search/components/StashButton";

export default function AccordionRecipe(props) {
  let history = useHistory();
  const { contexts, documentTypes, recipeTypes, recipeComponentCategories } = Constants();
  const { assetUrl, isNotEmpty, makeUrlLookGood, arrElementValue, toggleCheckbox } = Helpers();
  const [showRecipe, setShowRecipe] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [lightBoxOpened, setLightBoxOpened] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setWorking(false);
  }, [props.recipe]);

  useEffect(() => {
    if (props.recipe.checkboxes.filter(x => !x).length === 0) {
      setShowRecipe(false);
    } else {
      setShowRecipe(true);
    }
  }, [props.recipe.checkboxes]);

  useEffect(() => {
    const element = document.getElementById(`r-${props.recipe.id}`);
    const collapse = new Collapse(element, { toggle: false });
    if (showRecipe) {
      collapse.show();
      props.setCollapseAll(false);
    } else {
      collapse.hide();
      window.scrollTo({ top: element.offsetTop - 200, behavior: "smooth" });
    }
    showRecipe ? collapse.show() : collapse.hide();
  }, [showRecipe, props.recipe.id]);

  useEffect(() => {
    if (props.expandAll && !showRecipe) {
      setShowRecipe(true);
    }
  }, [props.expandAll]);

  useEffect(() => {
    if (props.collapseAll && showRecipe) {
      setShowRecipe(false);
    }
  }, [props.collapseAll]);

  const recipe = props.recipes.find(r => r.id === props.recipe.id);

  /**
   * Toggle all checkboxes of a recipe
   *
   * The new state checked/unchecked of the recipe is used to check/uncheck all checkboxes of the recipe. The new array
   * is sent to a parent function which takes care of saving data and refreshing the state.
   *
   * @param {boolean} checked - new state of the recipe checkbox
   */
  function toggleCheckboxes(checked) {
    const checkboxes = Array(props.recipe.checkboxes.length).fill(checked);
    props.updateCheckboxes(props.recipe.id, checkboxes);
  }

  const checked = props.recipe.checkboxes.filter(x => !x).length === 0;

  function cookingMethodSwitcher() {
    let result = null;
    if (props.recipe.hasTwoVersions && props.canShowActionButtons) {
      const buttonText = props.recipe.isStoveTopVersion ? "Switch to Instant Pot" : "Switch to Stove Top";
      const otherMethod = props.recipe.isStoveTopVersion ? "Instant Pot" : "Stove Top";
      const button = working ? (
        <button
          className="btn btn-sm btn-light ms-md-1"
          type="button"
          disabled={true}
        >
          <i className="fa-solid fa-repeat"></i> Switching...
        </button>
      ) : (
        <button
          className="btn btn-sm btn-light ms-md-1  print-yield"
          type="button"
          onClick={() => {
            setWorking(true)
            props.changeCookingMethod(props.recipe.id, otherMethod)
          }}
        >
          <i className="fa-solid fa-repeat"></i> {buttonText}
        </button>
      );
      const text = props.recipe.isStoveTopVersion ? "Stove Top Directions" : "Instant Pot Directions";
      result = (
        <p className="print-yield">{text} {button}</p>
      );
    }
    return result;
  }

  const tools = props.recipe.tools && props.recipe.tools.map((t, index) => (
    <li key={index} className="list-inline-item">
      <img style={{ width: "32px", margin: ".3em .05em .5em .05em" }} src={assetUrl(t.icon)} title={t.name} alt={t.name} />
    </li>
  ));

  let checkboxIterator = 0;
  const components = [];
  for (const [i, c] of props.recipe.components.entries()) {
    const content = <RecipeComponent component={c} parentIsMenuMeal={props.recipe.type === recipeTypes.ASSEMBLY} />;
    if (c.category === recipeComponentCategories.RECIPE || c.category === recipeComponentCategories.INGREDIENT) {
      const checked = arrElementValue(props.recipe.checkboxes, checkboxIterator);
      components.push(
        <RecipeInstruction
          key={i}
          content={content}
          showCheckbox={true}
          checked={checked}
          checkboxIterator={checkboxIterator}
          updatingCheckboxes={props.updatingCheckboxes}
          toggleCheckbox={checkboxIterator => toggleCheckbox(props.recipe.id, props.recipe.checkboxes, checkboxIterator, props.updateCheckboxes)}
        />
      );
      checkboxIterator++;
    } else {
      components.push(
        <RecipeInstruction
          key={i}
          content={content}
          showCheckbox={false}
        />
      );
    }
  }

  const instructions = [];
  if (isNotEmpty(props.recipe.instructions) && props.recipe.instructions.match(/\n\* |^\* /g)) {
    const splitInstructions = props.recipe.instructions.split("\n");
    for (const [i, element] of splitInstructions.entries()) {
      const content = <MarkdownViewer markdown={element} />;
      // When markdown element starts with a *, we render the checkbox
      if (element.match(/^\* /g)) {
        const checked = arrElementValue(props.recipe.checkboxes, checkboxIterator);
        instructions.push(
          <RecipeInstruction
            key={i}
            content={content}
            showCheckbox={true}
            checked={checked}
            checkboxIterator={checkboxIterator}
            updatingCheckboxes={props.updatingCheckboxes}
            toggleCheckbox={checkboxIterator => toggleCheckbox(props.recipe.id, props.recipe.checkboxes, checkboxIterator, props.updateCheckboxes)}
          />
        );
        checkboxIterator++;
      } else {
        instructions.push(
          <RecipeInstruction
            key={i}
            content={content}
            showCheckbox={false}
          />
        );
      }
    }
  }

  const stashItem = props.stashStore.items.filter(i => i.documentType === documentTypes.RECIPE).find(i => i.documentId === props.recipe.id);

  function canRemoveRecipe() {
    let result = false;
    if (!props.batchingContext && props.canShowActionButtons && props.recipe.hasOwnProperty("usedIn")) {
      result = props.recipe.usedIn.filter(r => r.type !== recipeTypes.ASSEMBLY).length === 0;
    }
    return result;
  }

  function accordionTitle() {
    let result;
    if (props.recipe.type === recipeTypes.ASSEMBLY) {
      result = "Put it all together";
    } else {
      if (props.context === contexts.STANDALONE_RECIPE) {
        result = props.recipe.name;
      } else if (props.recipe.hasOwnProperty("usedIn") && props.recipe.usedIn.length > 0) {
        if (props.batchingContext) {
          result = (
            <>
              <span>{props.recipe.name}</span>
              <p className="text-muted used-in">Used in: {props.recipe.usedIn.map(usage => `${usage.name} (${usage.usedYield})`).join(", ")}</p>
            </>
          );
        } else {
          if (isNotEmpty(props.parentRecipeId)) {
            let currentUsage = props.recipe.usedIn.find(usage => usage.id === props.parentRecipeId);
            const otherUsages = props.recipe.usedIn.filter(usage => usage.id !== props.parentRecipeId);
            if (isNotEmpty(currentUsage)) {
              result = (
                <>
                  <span>{props.recipe.name}</span>
                  {otherUsages.length > 0 ? (
                    <p className="text-muted used-in">Also used in: {otherUsages.map(usage => `${usage.name} (${usage.usedYield})`).join(", ")}</p>
                  ) : null}
                </>
              );
            } else {
              result = (
                <>
                  <span>{props.recipe.name}</span>
                  {otherUsages.length > 0 ? (
                    <p className="text-muted used-in">Used in: {otherUsages.map(usage => `${usage.name} (${usage.usedYield})`).join(", ")}</p>
                  ) : null}
                </>
              );
            }
          } else {
            result = (
              <>
                <span>{props.recipe.name}</span>
                {props.recipe.usedIn.length > 0 ? (
                  <p className="text-muted used-in">Used in: {props.recipe.usedIn.map(usage => `${usage.name} (${usage.usedYield})`).join(", ")}</p>
                ) : null}
              </>
            );
          }
        }
      } else {
        result = props.recipe.name;
      }
    }
    return result;
  }

  function sideBarMetaData() {
    let result = null;
    if (isNotEmpty(props.recipe.yield) || (props.recipe.hasOwnProperty("tools") && props.recipe.tools.length > 0)) {
      result = (
        <div className="py-2 px-3 text-center recipe-sidebar">
          <div className="my-2 lead single-recipe-yield">
            <p>
              <YieldAndLeftovers yield={props.recipe.yield} leftovers={props.recipe.leftovers} />
            </p>
          </div>
          <ul className="list-inline mb-1 text-center d-print-none">{tools}</ul>
        </div>
      );
    }
    return result;
  }

  function sideBarClasses() {
    let result = "recipe-deets";
    if (isNotEmpty(props.recipe.yield) || (props.recipe.hasOwnProperty("tools") && props.recipe.tools.length > 0)) {
      result = "recipe-deets bg-light mt-md-4";
    }
    return result;
  }

  function makeLessButton() {
    let result = null;
    if (isNotEmpty(recipe) && props.specialScalingIds.indexOf(recipe.id) === -1) {
      const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
      const childRecipeComponents = components.filter(c => c.category === recipeComponentCategories.RECIPE);
      if (childRecipeComponents.length === 0) {
        if (recipe.hasOwnProperty("additionalPortions") && recipe.additionalPortions > 0) {
          result = (
            <button
              className="btn btn-white btn-sm mb-1"
              type="button"
              onClick={() => props.updateAdditionalPortions({ childRecipeId: recipe.id, additionalPortions: recipe.additionalPortions - 1 })}
            >
              <i className="fas fa-caret-down" /> Make Less
            </button>
          );
        }
      }
    }
    return result;
  }

  function makeMoreButton() {
    let result = null;
    if (isNotEmpty(recipe) && props.specialScalingIds.indexOf(recipe.id) === -1) {
      const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
      const childRecipeComponents = components.filter(c => c.category === recipeComponentCategories.RECIPE);
      if (childRecipeComponents.length === 0) {
        const additionalPortions = recipe.hasOwnProperty("additionalPortions") ? recipe.additionalPortions + 1 : 1;
        result = (
          <button
            className="btn btn-white btn-sm ms-1 mb-1"
            type="button"
            onClick={() => props.updateAdditionalPortions({
              childRecipeId: recipe.id,
              additionalPortions: additionalPortions
            })}
          >
            <i className="fas fa-caret-up" /> Make More
          </button>
        );
      }
    }
    return result;
  }

  function noteBadge() {
    let result = null;
    let notesCount = props.recipe.notes.length;
    const memberNote = props.noteStore.memberNotes.find(n => n.documentId === props.recipe.id);
    // if (isNotEmpty(memberNote)) {
    //   notesCount = notesCount + 1;
    // }
    if (notesCount > 0) {
      result = (
        <span className="notes-badge-accordion badge bg-light text-secondary">{notesCount}</span>
      );
    }
    return result;
  }

  const recipeSideBar = props.lastRecipe ? (
    <div className="col-12 col-md-4 mb-2 mt-md-4 d-print-none">

      <div className={sideBarClasses()}>

        <div className="recipe-single-img d-print-none">
          <img
            className="img-fluid mb-2 cursor-pointer rounded"
            src={isNotEmpty(props.recipe.photo) ? assetUrl(props.recipe.photo) : `${process.env.PUBLIC_URL}/images/recipe-placeholder-picture.png`}
            alt={props.recipe.name}
            onClick={() => {
              setLightBoxOpened(true)
            }}
          />
        </div>
        {sideBarMetaData()}
      </div>
    </div>
  ) : (
    <div className="col-sm-12 col-md-4 mb-4 mb-sm-5">
      <p className="d-none d-print-block ps-2">
        <YieldAndLeftovers yield={props.recipe.yield} leftovers={props.recipe.leftovers} />
      </p>
      <div className="recipe-deets bg-light mt-md-5 pb-2">
        <div className="recipe-single-img d-print-none">
          <img
            className="img-fluid mb-2 cursor-pointer rounded-top"
            src={isNotEmpty(props.recipe.photo) ? assetUrl(props.recipe.photo) : `${process.env.PUBLIC_URL}/images/recipe-placeholder-picture.png`}
            alt={props.recipe.name}
            onClick={() => {
              setLightBoxOpened(true)
            }}
          />
        </div>
        <div className="py-2 px-2 text-center recipe-sidebar d-print-none">
          <div className="single-review-stars d-print-none">
            <ReviewStars
              stars={props.recipe.stars}
              cta={() => props.modals.showReviews({
                documentId: props.recipe.id,
                documentType: documentTypes.RECIPE,
                reviewsFor: `${props.recipe.name}`,
              })}
            />
          </div>
          <div className="my-1 lead single-recipe-yield">
            <p>
              <YieldAndLeftovers yield={props.recipe.yield} leftovers={props.recipe.leftovers} />
            </p>
            {canRemoveRecipe() ? (
              <button
                type="button"
                className="btn btn-white btn-sm mb-1 me-1"
                onClick={() => props.deleteChildRecipe(props.parentRecipeId, props.recipe.id)}
              >
                <i className="fas fa-minus-circle" /> Remove
              </button>
            ) : null}
            {makeLessButton()}
            {makeMoreButton()}
          </div>

          <ul className="list-inline mb-1 text-center d-print-none">{tools}</ul>
          <div className="d-flex align-items-center justify-content-center flex-wrap">
            {isNotEmpty(recipe) && !recipe.excludeFromAllRecipes ? (
              <>
                {props.member.hasMP && props.canShowActionButtons ? (
                  <button
                    className="btn btn-sm btn-white m-1"
                    type="button"
                    title="Add Recipe to Meal Planner"
                    onClick={() => props.modals.showAddToMealPlan({
                      documentId: props.recipe.id,
                      documentType: documentTypes.RECIPE,
                      name: props.recipe.name
                    })
                    }
                  >
                    <span className="fas fa-plus fa-fw" />
                  </button>
                ) : null}
                <StashButton
                  documentId={props.recipe.id}
                  documentType={documentTypes.RECIPE}
                  darkTheme={true}
                  smallButton={true}
                  margin=" m-1"
                  title="Save"
                  active={stashItem === undefined ? false : stashItem.saved}
                  activeClassName="fa fa-folder-bookmark fa-fw"
                  inactiveClassName="far fa-folder-bookmark fa-fw"
                  toggle={props.stashStore.toggleSaved}
                />
                <StashButton
                  documentId={props.recipe.id}
                  documentType={documentTypes.RECIPE}
                  darkTheme={true}
                  smallButton={true}
                  margin=" m-1"
                  title="Love"
                  active={stashItem === undefined ? false : stashItem.loved}
                  activeClassName="fa fa-heart fa-fw"
                  inactiveClassName="far fa-heart fa-fw"
                  toggle={props.stashStore.toggleLove}
                />
                <button
                  className="btn btn-sm btn-white m-1"
                  type="button"
                  title="View"
                  onClick={() => { history.push(`/recipes/recipe-vault/${makeUrlLookGood(props.recipe.id, props.recipe.name)}`) }}
                >
                  <span className="fa-regular fa-arrow-up-right-from-square" />
                </button>
              </>
            ) : null}
            {props.canShowNutritionFacts ? (
              <button
                className="btn btn-sm btn-white m-1"
                type="button"
                title="Info"
                onClick={() => props.modals.showNutritionFacts(props.prepareDataForNutritionFacts(props.recipe.id))}
              >
                <span className="fa fa-chart-simple-horizontal fa-fw" />
              </button>
            ) : null}
            <button
              className="btn btn-sm btn-white m-1 position-relative"
              type="button"
              title="Notes"
              onClick={() => setShowNotes(!showNotes)}
            >
              <span className={showNotes ? "notes-icon-accordion fa-solid fa-note fa-fw" : "notes-icon-accordion far fa-sticky-note fa-fw"} />
              {noteBadge()}
            </button>
          </div>
        </div>
        {showNotes ? (
          <div className="row p-3">
            <div className="col">
            </div>
            <Notes
              documentId={props.recipe.id}
              documentType={documentTypes.RECIPE}
              store={props.noteStore}
              documentPublicNotes={props.recipe.notes}
            />
          </div>
        ) : null}
      </div>
    </div>
  );

  return (
    <div className={isNotEmpty(props.index) && props.index === 0 ? "accordion-item first-accordion-print mb-3" : "accordion-item mb-3"}>
      <h2 className="accordion-header">
      
        <button
          className={showRecipe ? "accordion-button" : "accordion-button collapsed"}
          type="button"
          onClick={() => setShowRecipe(!showRecipe)}
        >
          <Checkcircle checked={checked} toggle={() => toggleCheckboxes(!checked)} /><h3 className={checked ? "ms-2 mt-1 h-playlist text-muted" : "ms-2 mt-1 h-playlist"}>{accordionTitle()}</h3>
        </button>
      </h2>
      <div id={`r-${props.recipe.id}`} className="accordion-collapse collapse">
        <div className="accordion-body accordion-recipe p-3">
          {isNotEmpty(props.recipe.photo) ? <FullScreenImage
            opened={lightBoxOpened}
            image={props.recipe.photo}
            caption={props.recipe.name}
            onCloseRequest={() => setLightBoxOpened(false)}
          /> : null}            
          <div className="row bg-white justify-content-md-center">
            {recipeSideBar}
            <div className="col-sm-12 col-md-7 order-md-first mt-md-3">
              <NotesAndTips notesAndTips={props.recipe.notesAndTips} />
              {cookingMethodSwitcher()}
              <RecipeDetails components={components} instructions={instructions} recipeType={props.recipe.type} />
              {/*Add a checkbox that acts like the check circle to mark the recipe as done*/}
              <div className="ms-2 d-print-none">
                <RecipeInstruction
                  content={<MarkdownViewer markdown="- I'm done! Mark this recipe as complete." />}
                  showCheckbox={true}
                  checked={checked}
                  checkboxIterator={0}
                  updatingCheckboxes={props.updatingCheckboxes}
                  toggleCheckbox={() => toggleCheckboxes(!checked)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AccordionRecipe.propTypes = {
  index: PropTypes.number,
  member: PropTypes.object,
  stashStore: PropTypes.object,
  noteStore: PropTypes.object,
  context: PropTypes.string,
  parentRecipeId: PropTypes.number,
  recipe: PropTypes.object,
  recipes: PropTypes.array,
  modals: PropTypes.object,
  lastRecipe: PropTypes.bool,
  canShowNutritionFacts: PropTypes.bool,
  canShowActionButtons: PropTypes.bool,
  batchingContext: PropTypes.bool,
  expandAll: PropTypes.bool,
  collapseAll: PropTypes.bool,
  updatingCheckboxes: PropTypes.bool,
  specialScalingIds: PropTypes.array,
  updateCheckboxes: PropTypes.func,
  changeCookingMethod: PropTypes.func,
  deleteChildRecipe: PropTypes.func,
  updateAdditionalPortions: PropTypes.func,
  prepareDataForNutritionFacts: PropTypes.func,
  setExpandAll: PropTypes.func,
  setCollapseAll: PropTypes.func,
}
