import { Offcanvas } from "bootstrap";
import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Userpilot } from "userpilot";
import SignInButton from "./SignInButton";

export default function Header({ authenticated, menuStore, quickLinks, toggleQuickLink, signOut }) {
  let history = useHistory();
  let location = useLocation();

  useEffect(() => {
    if (authenticated) {
      Userpilot.initialize("NX-44ef2fc1");
      Userpilot.reload();
    }
    // Deal with menu
    const element = document.getElementById("offcanvasMenu");
    if (element !== null && element.classList.contains("show")) {
      const menu = Offcanvas.getOrCreateInstance(element);
      menu.hide();
    }
  }, [location, authenticated]);

  function anonymousButtons() {
    let result = null;
    if (location.pathname.indexOf("/forgot-password") === -1 && location.pathname.indexOf("/gift") === -1) {
      let buttons;
      buttons = (
        <>
        <Link to="/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=home+header&utm_term=try+now" className="btn btn-secondary me-2">
         Start trial
          </Link>
          <SignInButton />
        </>
      );
      result = <nav className="d-inline-flex ms-md-auto header-icons header navbar navbar-light bg-white p-0">{buttons}</nav>;
    }
    return result;
  }

  function renderMenu() {
    const menuItems = menuStore.items;
    let result = [];
    let previousMenuItem = {
      id: 0,
      sectionTitle: "",
      sectionIcon: "",
      sectionColor: "",
      title: "",
      link: "",
      opensInNewTab: false,
    };
    for (const key in menuItems) {
      if (Object.hasOwnProperty.call(menuItems, key)) {
        const menuItem = menuItems[key];
        // if previous menu item is not in the same section
        if (menuItem.sectionTitle !== previousMenuItem.sectionTitle) {
          // create the section header
          result.push(
            <div key={`${key}-section`} className={`menu-section text-${menuItem.sectionColor} ${previousMenuItem.id !== 0 ? "mt-3" : ""}`}>
              <i className={`menu-section-icon fa-fw ${menuItem.sectionIcon}`} />
              <span className="ms-2">{menuItem.sectionTitle}</span>
            </div>
          );
        }
        if (menuItem.opensInNewTab) {
          // create menu item if link opens in a new tab
          result.push(
            <div key={key} className={`menu-${menuItem.sectionColor} me-2`}>
              <a className="nav-link menu-text rounded ms-3" href={menuItem.link} target="_blank" rel="noreferrer">
                {menuItem.title}
              </a>
            </div>
          );
        } else {
          // create menu item if link is internal
          result.push(
            <div key={key} className={`menu-${menuItem.sectionColor} me-2`}>
              <Link className="nav-link menu-text rounded ms-3" to={menuItem.link}>
                {menuItem.title}
              </Link>
            </div>
          );
        }

        // update previous menu item with current menu item before reading next one
        previousMenuItem = menuItem;
      }
    }
    return result.length > 0 ? result : null;
  }
  const ql =
    quickLinks.find((ql) => ql.link === location.pathname + location.search) === undefined ? (
      <button type="button" className="btn btn-light" title="Add this page to quick links" onClick={() => toggleQuickLink(location.pathname, location.search)}>
        <i className="fa-solid fa-link" />
      </button>
    ) : (
      <button type="button" className="btn btn-primary" title="Remove this page from quick links" onClick={() => toggleQuickLink(location.pathname, location.search)}>
        <i className="fa-solid fa-link" />
      </button>
    );

  function offcanvasMenu() {
    let result = null;
    result = (
      <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel">
        <div className="offcanvas-header">
          <h5 className="mb-0 d-none" id="offcanvasMenuLabel">
            Menu
          </h5>
          {ql}
          <button type="button" className="btn-close text-reset me-sm-1" data-bs-dismiss="offcanvas" aria-label="Close" />
        </div>
        <div className="offcanvas-body pt-0">
          {renderMenu()}

          {/* **** sign out **** */}
          <div className="menu-red mt-2 me-2">
            <a
              className="nav-link menu-text rounded text-muted ms-3"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                signOut();
              }}
            >
              Sign Out
            </a>
          </div>

          {/* **** socials **** */}
          <nav className="menu-icons mt-3">
            <a className="p-2" href="https://www.facebook.com/cfdgirl" target="_blank" rel="noreferrer">
              <i className="fab fa-facebook" />
            </a>
            <a className="p-2" href="https://www.instagram.com/cleanfooddirtygirl/" target="_blank" rel="noreferrer">
              <i className="fab fa-instagram" />
            </a>
            <a className="p-2" href="https://www.pinterest.com/clnfooddrtygrl/" target="_blank" rel="noreferrer">
              <i className="fab fa-pinterest"></i>
            </a>
            <a className="p-2" href="https://open.spotify.com/show/5ViLN4goOjFHyVJs5nMDnG" target="_blank" rel="noreferrer">
              <i className="fab fa-spotify" />
            </a>
            <a className="p-2 strip-right" href="https://cleanfooddirtygirl.threadless.com/" target="_blank" rel="noreferrer">
              <i className="fa-regular fa-bag-shopping"></i>
            </a>
          </nav>
        </div>
      </div>
    );
    return result;
  }

  function main() {
    return authenticated ? (
      <header className="main-menu fixed-top border-0 shadow-sm ps-0">
        <nav className="navbar navbar-expand navbar-light bg-white p-0" id="main-menu">
          <div className="container-fluid px-1 px-md-2">
            <Link className="navbar-brand me-0" to="/">
              <img src={`${process.env.PUBLIC_URL}/images/plant-fueled-life-clean-food-dirty-girl.png`} className="header-logo d-none d-lg-block" alt="Plant Fueled Life by Clean Food Dirty Girl" />
              <img src={`${process.env.PUBLIC_URL}/images/plant-fueled-life-icon.png`} className="d-lg-none" alt="Plant Fueled Life by Clean Food Dirty Girl" style={{ height: "40px" }} />
            </Link>
            <div id="header-icons-group" className="justify-content-end">
              <div className="row align-items-center">
                <div className="col-auto p-0">
                  <button id="header-search-btn" className="btn nav-link" type="button" onClick={() => history.push("/search")}>
                    <span className="fs-4">
                      <i className="fas fa-magnifying-glass" />
                    </span>
                  </button>
                </div>
                <div className="col-auto p-0">
                  <ul className="navbar-nav my-0 my-md-2 my-lg-0 ms-auto">
                    {/* cooking journal icon */}
                    <li className="header-link-btn">
                      <Link to="/cooking-journal?d=now" className="nav-link rounded">
                        <span className="fs-4">
                          <i className="fa-solid fa-book-sparkles" />
                        </span>
                      </Link>
                    </li>
                    {/* saved icon */}
                    <li className="header-link-btn">
                      <Link to="/search?tab=loved" className="nav-link rounded">
                        <span className="fs-4">
                          <i className="fa-solid fa-folder-heart"></i>
                        </span>
                      </Link>
                    </li>
                    {/* menu icon */}
                    <li className="header-link-btn">
                      <button className="btn nav-link rounded" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasMenu" aria-controls="offcanvasMenu" aria-label="Menu">
                        <span className="fs-4">
                          <i className="fa-solid fa-bars"></i>
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
        {offcanvasMenu()}
      </header>
    ) : (
      <header className="main-menu fixed-top border-0 shadow-sm">
        <nav className="navbar navbar-expand navbar-light bg-white p-1 pe-2" id="main-menu">
          <div className="container-fluid p-0 px-md-2">
            <Link className="navbar-brand" to="/">
              <img src={`${process.env.PUBLIC_URL}/images/plant-fueled-life-clean-food-dirty-girl.png`} className="header-logo" alt="Plant Fueled Life by Clean Food Dirty Girl" />
            </Link>
            <div className="justify-content-end" id="navbarScroll">
              <ul className="navbar-nav me-auto my-0 my-md-2 my-lg-0 navbar-nav-scroll ms-auto">
                <li>{anonymousButtons()}</li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    );
  }

  return ["/sign-up", "/sign-in", "/trial", "/thank-you"].indexOf(location.pathname) !== -1 ? null : main();
}
