import {useEffect, useState} from "react";
import axios from "axios";
import Helpers from "../Helpers";

export default function useStash(token, member) {
  const {handleError, isNotEmpty} = Helpers();
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    if (isNotEmpty(token) && member.hasMP) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/stash`,
      }).then(res => {
        setItems(res.data);
        setReady(true);
      }).catch(err => {
        setReady(true);
        handleError(err);
      });
    }
  }, [token, member]);

  function add(data) {
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/stash`,
      data: data,
    }).then(res => {
      setItems(res.data);
    }).catch(() => {
      // do nothing
    });
  }

  function update(data) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/stash/${data.id}`,
      data: data,
    }).then(res => {
      setItems(res.data);
    }).catch(() => {
      // do nothing
    });
  }

  function toggleLove(data) {
    const item = items.find(i => i.documentId === data.documentId && i.documentType === data.documentType);
    if (item === undefined) {
      data["pinned"] = false;
      data["loved"] = true;
      data["saved"] = false;
      add(data);
    } else {
      data["id"] = item.id;
      data["pinned"] = item.pinned;
      data["loved"] = !item.loved;
      data["saved"] = item.saved;
      update(data);
    }
  }

  function togglePin(data) {
    const item = items.find(i => i.documentId === data.documentId && i.documentType === data.documentType);
    if (item === undefined) {
      data["pinned"] = true;
      data["loved"] = false;
      data["saved"] = false;
      add(data);
    } else {
      data["id"] = item.id;
      data["loved"] = item.loved;
      data["pinned"] = !item.pinned;
      data["saved"] = item.saved;
      update(data);
    }
  }

  function toggleSaved(data) {
    const item = items.find(i => i.documentId === data.documentId && i.documentType === data.documentType);
    if (item === undefined) {
      data["pinned"] = false;
      data["loved"] = false;
      data["saved"] = true;
      add(data);
    } else {
      data["id"] = item.id;
      data["loved"] = item.loved;
      data["pinned"] = item.pinned;
      data["saved"] = !item.saved;
      update(data);
    }
  }

  function filterItems(terms) {
    if (isNotEmpty(terms)) {
      setFilteredItems(items.filter(i => i.title.toLowerCase().indexOf(terms.toLowerCase()) !== -1));
    } else {
      setFilteredItems(items);
    }
  }

  const stashStore = {
    items: items,
    filteredItems: filteredItems,
    ready: ready,
    add: add,
    update: update,
    toggleLove: toggleLove,
    togglePin: togglePin,
    toggleSaved: toggleSaved,
    filterItems: filterItems,
  }

  return {stashStore};
}