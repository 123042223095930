import { useEffect, useState } from "react";
import axios from "axios";
import Helpers from "../Helpers";
import Constants from "../Constants";

export default function useReview(token, member) {
  const { documentTypes } = Constants();
  const { isNotEmpty } = Helpers();
  const [memberReviews, setMemberReviews] = useState([]);
  const [reviewData, setReviewData] = useState({ reviews: [], stars: 0, timing: 0, kidFriendly: 0, omniFriendly: 0 });
  const [documentData, setDocumentData] = useState(emptyDocumentData());
  const [working, setWorking] = useState(false);

  useEffect(() => {
    if (isNotEmpty(token) && (member.hasMP || member.hasRV)) {
      get();
    }
  }, [token, member]);

  useEffect(() => {
    setWorking(true);
    getReviewData(documentData);
  }, [documentData, memberReviews.length]);

  useEffect(() => {
    setWorking(false);
  }, [reviewData]);

  function emptyDocumentData() {
    return {
      documentId: 0,
      documentType: "",
    };
  }

  function resetDocumentData() {
    setDocumentData(emptyDocumentData());
  }

  function get() {
    if (isNotEmpty(token)) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/reviews`,
      }).then(res => {
        setMemberReviews(res.data)
      }).catch(() => {
        // do nothing
      });
    }
  }

  function create(data, cb) {
    axios({
      method: "post",
      headers: {
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/reviews`,
      data: data,
    }).then(res => {
      setMemberReviews(res.data);
      if (cb) cb();
    }).catch(() => {
      if (cb) cb();
    });
  }

  function update(id, data, cb) {
    axios({
      method: "put",
      headers: {
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/reviews/${id}`,
      data: data,
    }).then(res => {
      setMemberReviews(res.data)
      if (cb) cb();
    }).catch(() => {
      if (cb) cb();
    });
  }

  function destroy(id, cb) {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/reviews/${id}`,
    }).then(res => {
      setMemberReviews(res.data)
      if (cb) cb();
    }).catch(() => {
      // do nothing
    });
  }

  function getReviewData(data) {
    let url = null;
    if (data.documentType === documentTypes.RECIPE) {
      url = `${process.env.REACT_APP_API}/reviews/recipes/${data.documentId}`;
    } else if (data.documentType === documentTypes.WIZARD_PLAN) {
      url = `${process.env.REACT_APP_API}/reviews/wizard-plans/${data.documentId}`;
    }
    if (isNotEmpty(url)) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: url,
      }).then(res => {
        setReviewData(res.data)
      }).catch(() => {
        // do nothing
      });
    }
  }

  const reviewStore = {
    memberReviews: memberReviews,
    documentData: documentData,
    setDocumentData: setDocumentData,
    reviewData: reviewData,
    working: working,
    resetDocumentData: resetDocumentData,
    create: create,
    update: update,
    destroy: destroy,
  }

  return { reviewStore };
}