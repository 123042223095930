import React, { useCallback, useEffect, useRef, useState } from "react";
import Helpers from "../../../../services/Helpers";
import Constants from "../../../../services/Constants";
import { Link, useHistory } from "react-router-dom";
import HomepageVisibilityToggle from "./HomepageVisibilityToggle";
import NextItems from "./NextItems";
import PreviousItems from "./PreviousItems";
import MiniStars from "./MiniStars";

export default function HomepageComponent({ loading, component, items, visible, editMode, toggleVisibility, modals, member }) {
  let history = useHistory();
  const { assetUrl, isNotEmpty, splitHashtag, shortenedText } = Helpers();
  const { documentTypes, homepageComponentTypes } = Constants();
  const ref = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current !== null && ref.current.scrollWidth - ref.current.scrollLeft <= ref.current.clientWidth + 1) {
        setShowRightArrow(false);
      } else {
        setShowRightArrow(true);
      }
    }, 2000);
  }, []);

  // The scroll listener
  const handleScroll = useCallback(() => {
    // Add small buffer to make sure the icon disappears
    if (ref.current.scrollWidth - ref.current.scrollLeft <= ref.current.clientWidth + 1) {
      setShowRightArrow(false);
    } else if (!showRightArrow) {
      setShowRightArrow(true);
    }
    if (ref.current.scrollLeft > 0) {
      setShowLeftArrow(true);
    } else {
      setShowLeftArrow(false);
    }
  }, []);

  // Attach the scroll listener to the div
  useEffect(() => {
    const div = ref.current;
    if (div !== null) {
      div.addEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  function scrollLeft() {
    ref.current.scrollLeft -= (ref.current.clientWidth * 94) / 100;
  }

  function scrollRight() {
    ref.current.scrollLeft += (ref.current.clientWidth * 94) / 100;
  }

  function renderItems() {
    let result;
    if (component.componentType === homepageComponentTypes.EXTERNAL_LINKS) {
      result = items.map((item, i) => (
        <article key={item.id} className="homepage-component-link homepage-external-link p-0 ps-1" style={{ width: "192px", marginRight: i + 1 === items.length ? "40px" : "0" }}>
          <a href={item.link} target="_blank" rel="noopener noreferrer" className={`mx-auto rounded d-block position-relative ${item.color} p-2`} style={{ height: "180px", width: "180px" }}>
            <span className="text-white fs-5">{item.title}</span>
            <span className="position-absolute bottom-0 end-0 translate-middle text-white">
              <i className="fa-solid fa-arrow-up-right-from-square fs-4" />
            </span>
          </a>
        </article>
      ));
    } else if (component.componentType === homepageComponentTypes.INTERNAL_LINKS) {
      result = items.map((item, i) => (
        <article key={item.id} className="homepage-component-link homepage-internal-link" style={{ width: "125px", marginRight: i + 1 === items.length ? "40px" : "0" }}>
          <Link to={item.link} className={`mx-auto rounded-circle d-block position-relative ${item.color}`} style={{ height: "110px", width: "110px" }}>
            <span className="position-absolute top-50 start-50 translate-middle link-dark">{item.title}</span>
          </Link>
        </article>
      ));
    } else if (component.componentType === homepageComponentTypes.QUICK_LINKS) {
      if (items.length > 0) {
        result = items.map((item, i) => (
          <article key={item.id} className="homepage-component-link homepage-internal-link" style={{ width: "125px", marginRight: i + 1 === items.length ? "40px" : "0" }}>
            <Link to={item.link}>
              {item.photo ? (
                <div className="d-block position-relative" style={{ height: "101px", width: "101px" }}>
                  <img src={assetUrl(item.photo)} className="mx-auto rounded-circle" alt="" style={{ objectFit: "cover", width: "100%", aspectRatio: "1/1" }} />
                  <i className="fa-solid fa-link fs-2 text-light position-absolute top-50 start-50 translate-middle" style={{ opacity: 0.7 }} />
                </div>
              ) : (
                <div className="mx-auto rounded-circle d-block position-relative darkgrey" style={{ height: "101px", width: "101px" }}>
                  <i className="fa-solid fa-link fs-2 text-light position-absolute top-50 start-50 translate-middle" />
                </div>
              )}
              <div className="text-center lh-sm fs-6 mt-1 link-dark" title={item.link.includes("/meal-plans/wizard-plans/") ? splitHashtag(item.title) : item.title}>
                {item.link.includes("/meal-plans/wizard-plans/") ? shortenedText(splitHashtag(item.title), 21) : shortenedText(item.title, 21)}
              </div>
            </Link>
          </article>
        ));
      }
    } else {
      result = items.map((item, i) => (
        <article key={`${item.id}_${i}`} className="homepage-component-item p-0 ps-1 cursor-pointer" onClick={() => {
          if (item.documentType === documentTypes.WIZARD_PLAN && member.hasRV) {
            modals.showFullMembershipRequiredModal();
          } else {
            history.push(item.link);
          }
        }} style={{ marginRight: i + 1 === items.length ? "40px" : "0" }}>
          <div className="position-relative">
            <img src={assetUrl(item.photo)} alt="" style={{ objectFit: "cover", width: "100%", aspectRatio: "1/1", borderRadius: "5px" }} />
            <MiniStars
              stars={item.stars}
              cta={(event) => {
                event.stopPropagation();
                modals.showReviews({
                  documentId: item.documentId,
                  documentType: item.documentType,
                  reviewsFor: item.documentType === documentTypes.RECIPE ? `${item.title}` : `${splitHashtag(item.title)}`,
                });
              }}
            />
          </div>
          <p
            className={isNotEmpty(item.subTitle) ? "document-title lh-sm fs-6 mt-1 mb-0" : "document-title lh-sm fs-6 mt-1"}
            title={item.documentType === documentTypes.WIZARD_PLAN ? splitHashtag(item.title) : item.title}
          >
            {item.documentType === documentTypes.WIZARD_PLAN ? splitHashtag(item.title) : shortenedText(item.title, 45)}
          </p>
          {isNotEmpty(item.subTitle) && (
            <span className="text-muted m-0 p-0" style={{ fontSize: ".9rem" }}>
              {item.subTitle}
            </span>
          )}
        </article>
      ));
    }
    return result;
  }

  function scrollIndicatorHeightClass() {
    let result = "full-height-scroll-indicator";
    if (component.componentType === homepageComponentTypes.INTERNAL_LINKS) {
      result = "short-height-scroll-indicator";
    } else if (component.title) {
      result = "mid-height-scroll-indicator";
    }
    return result;
  }

  return visible || editMode ? (
    <section className="row my-4 homepage-component" style={{ position: "relative" }}>
      <div className="col-12">
        <div>
          {component.title && (
            <div className="row mb-1">
              <div className="col">
                <h5 className="homepage-section-title">
                  {editMode && component.componentType !== homepageComponentTypes.QUICK_LINKS ? <HomepageVisibilityToggle visible={visible} toggle={toggleVisibility} /> : null}
                  <span className={editMode && !visible ? "text-muted" : ""}>{component.title}</span>
                </h5>
              </div>
            </div>
          )}
          <div className="row flex-row flex-nowrap home-row" ref={ref} style={{ opacity: editMode && !visible ? 0.25 : 1 }}>
            {loading ? (
              <span className="spinner-border" />
            ) : (
              // show content
              <>
                {renderItems()}
                {showLeftArrow && <PreviousItems showPreviousItems={scrollLeft} scrollIndicatorHeightClass={scrollIndicatorHeightClass()} />}
                {showRightArrow && <NextItems showNextItems={scrollRight} scrollIndicatorHeightClass={scrollIndicatorHeightClass()} />}
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  ) : null;
}
