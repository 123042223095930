import React, { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import SnailButton from "../../components/SnailButton";
import CreditCardFormButtonGroup from "./CreditCardFormButtonGroup";
import { validateCCN } from "../../../utils/ccn-validator";
import { validateExpMonth } from "../../../utils/exp-month-validator";
import { renderCCYears } from "../../../utils/cc-years-renderer";
import { renderCCMonths } from "../../../utils/cc-months-renderer";
import usStates from "../../../data/us-states.json";
import caStates from "../../../data/ca-states.json";
import auStates from "../../../data/au-states.json";
import zaStates from "../../../data/za-states.json";
import countries from "../../../data/countries.json";

const actions = {
  FULL_NAME: "fullName",
  FIRSTNAME: "firstname",
  LASTNAME: "lastname",
  ADDRESS: "address",
  ADDRESS2: "address2",
  CITY: "city",
  STATE: "state",
  ZIP: "zip",
  COUNTRY: "country",
  NUMBER: "number",
  EXP_MONTH: "expMonth",
  EXP_YEAR: "expYear",
  CVC: "cvc",
  WORKING: "working",
  VALIDATE: "validate",
};

const initialState = {
  firstname: "",
  firstnameInvalid: false,
  lastname: "",
  lastnameInvalid: false,
  address: "",
  addressInvalid: false,
  address2: "",
  city: "",
  cityInvalid: false,
  state: "",
  stateInvalid: false,
  zip: "",
  zipInvalid: false,
  country: "",
  countryInvalid: false,
  number: "",
  formattedNumber: "",
  numberInvalid: false,
  expMonth: "",
  expMonthInvalid: false,
  expYear: "",
  expYearInvalid: false,
  cvc: "",
  cvcInvalid: false,
  working: false,
};

export default function StandaloneCreditCardForm(props) {
  const { isNotEmpty } = Helpers();

  function reducer(state, action) {
    if (action.type === actions.WORKING) {
      return { ...state, working: action.data.working };
    } else if (action.type === actions.FULL_NAME) {
      return { ...state, firstname: action.data.firstname, firstnameInvalid: false, lastname: action.data.lastname, lastnameInvalid: false };
    } else if (action.type === actions.FIRSTNAME) {
      return { ...state, firstname: action.data.value, firstnameInvalid: false };
    } else if (action.type === actions.LASTNAME) {
      return { ...state, lastname: action.data.value, lastnameInvalid: false };
    } else if (action.type === actions.ADDRESS) {
      return { ...state, address: action.data.value, addressInvalid: false };
    } else if (action.type === actions.ADDRESS2) {
      return { ...state, address2: action.data.value };
    } else if (action.type === actions.CITY) {
      return { ...state, city: action.data.value, cityInvalid: false };
    } else if (action.type === actions.STATE) {
      return { ...state, state: action.data.value, stateInvalid: false };
    } else if (action.type === actions.ZIP) {
      return { ...state, zip: action.data.value, zipInvalid: false };
    } else if (action.type === actions.COUNTRY) {
      return { ...state, country: action.data.value, countryInvalid: false };
    } else if (action.type === actions.NUMBER) {
      if (action.data.value === "" || action.data.value.match(/^(\s|\d)+$/) !== null) {
        const formattedNumber = action.data.value
          .replace(/\W/gi, "")
          .replace(/(.{4})/g, "$1 ")
          .replace(/((\s*\S+)*)\s*/, "$1");
        return { ...state, number: formattedNumber, numberInvalid: false };
      } else {
        return state;
      }
    } else if (action.type === actions.EXP_MONTH) {
      return { ...state, expMonth: action.data.value, expMonthInvalid: false };
    } else if (action.type === actions.EXP_YEAR) {
      return { ...state, expYear: action.data.value, expYearInvalid: false };
    } else if (action.type === actions.CVC) {
      if (action.data.value === "" || action.data.value.match(/^\d+$/) !== null) {
        return { ...state, cvc: action.data.value, cvcInvalid: false };
      } else {
        return state;
      }
    } else if (action.type === actions.VALIDATE) {
      const firstnameValid = isNotEmpty(state.firstname);
      const lastnameValid = isNotEmpty(state.lastname);
      const addressValid = isNotEmpty(state.address);
      const cityValid = isNotEmpty(state.city);
      const stateValid = isNotEmpty(state.state);
      const zipValid = isNotEmpty(state.zip);
      const countryValid = isNotEmpty(state.country);
      const numberValid = validateCCN(state.number.replace(/\s/g, ""));
      const expMonthValid = validateExpMonth(state.expMonth, state.expYear);
      const expYearValid = isNotEmpty(state.expYear);
      const cvcValid = isNotEmpty(state.cvc) && state.cvc.length > 2;
      const readyToSubmit =
        firstnameValid &&
        lastnameValid &&
        addressValid &&
        cityValid &&
        stateValid &&
        zipValid &&
        countryValid &&
        numberValid &&
        expMonthValid &&
        expYearValid &&
        cvcValid;

      return {
        ...state,
        firstnameInvalid: !firstnameValid,
        lastnameInvalid: !lastnameValid,
        addressInvalid: !addressValid,
        cityInvalid: !cityValid,
        stateInvalid: !stateValid,
        zipInvalid: !zipValid,
        countryInvalid: !countryValid,
        numberInvalid: !numberValid,
        expMonthInvalid: !expMonthValid,
        expYearInvalid: !expYearValid,
        cvcInvalid: !cvcValid,
        readyToSubmit: readyToSubmit,
      };
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (props.member) {
      if (isNotEmpty(props.member.firstname)) {
        dispatch({
          type: actions.FULL_NAME,
          data: { firstname: props.member.firstname, lastname: props.member.lastname },
        });
      }
    }
  }, [props.member]);

  useEffect(() => {
    if (state.readyToSubmit) {
      dispatch({
        type: actions.WORKING,
        data: { working: true },
      });
    }
  }, [state.readyToSubmit]);

  useEffect(() => {
    if (state.working) {
      const billingAddress = {
        firstname: state.firstname,
        lastname: state.lastname,
        address: state.address,
        address2: state.address2,
        city: state.city,
        state: state.state,
        zip: state.zip,
        country: state.country,
      };
      const payer = {
        ccnumber: state.number.replace(/\s/g, ""),
        expire_month: state.expMonth,
        expire_year: state.expYear,
        code: state.cvc,
      };
      props.addCreditCard({ billingAddress: billingAddress, payer: payer }, () => props.dismiss());
    }
  }, [state.working]);

  function handleSubmit(event) {
    event.preventDefault();
    dispatch({
      type: actions.VALIDATE,
    });
  }

  return (
    <div>
      <form id="credit-card-form" onSubmit={handleSubmit} noValidate={true}>
        <div className="row">
          <div className="col-12 mx-auto">
            <div className="row mb-2">
              <div className="col-6 pe-1">
                <label className="form-label" htmlFor="firstname">
                  First name
                </label>
                <input
                  type="text"
                  className={state.firstnameInvalid ? "form-control is-invalid" : "form-control"}
                  value={state.firstname}
                  onChange={(e) => dispatch({ type: actions.FIRSTNAME, data: { value: e.target.value } })}
                />
                <div className="invalid-feedback">Please provide a first name.</div>
              </div>
              <div className="col-6 ps-1">
                <label className="form-label" htmlFor="lastname">
                  Last name
                </label>
                <input
                  type="text"
                  className={state.lastnameInvalid ? "form-control is-invalid" : "form-control"}
                  value={state.lastname}
                  onChange={(e) => dispatch({ type: actions.LASTNAME, data: { value: e.target.value } })}
                />
                <div className="invalid-feedback">Please provide a last name.</div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <label className="form-label" htmlFor="address">
                  Address line 1
                </label>
                <input
                  type="text"
                  className={state.addressInvalid ? "form-control is-invalid" : "form-control"}
                  value={state.address}
                  onChange={(e) => dispatch({ type: actions.ADDRESS, data: { value: e.target.value } })}
                />
                <div className="invalid-feedback">Please provide an address.</div>
              </div>
            </div>
            <a className="text-secondary cursor-pointer footer-text" data-bs-toggle="collapse" data-bs-target="#address2" aria-controls="address2">
              <span className="d-none d-sm-inline">Click</span>
              <span className="d-inline d-sm-none">Tap</span> to add address line 2
            </a>
            <div className="collapse row mb-2" id="address2">
              <div className="col-12">
                <label className="form-label" htmlFor="address2">
                  Address line 2
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={state.address2}
                  onChange={(e) => dispatch({ type: actions.ADDRESS2, data: { value: e.target.value } })}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-5 pe-1">
                <label className="form-label" htmlFor="city">
                  City
                </label>
                <input
                  type="text"
                  className={state.cityInvalid ? "form-control is-invalid" : "form-control"}
                  value={state.city}
                  onChange={(e) => dispatch({ type: actions.CITY, data: { value: e.target.value } })}
                />
                <div className="invalid-feedback">Please provide a city.</div>
              </div>
              <div className="col-4 px-1">
                <label className="form-label" htmlFor="state">
                  State
                </label>
                <select
                  className={state.stateInvalid ? "form-control form-select is-invalid" : "form-control form-select"}
                  value={state.state}
                  onChange={(e) => dispatch({ type: actions.STATE, data: { value: e.target.value } })}
                >
                  <option value="">Choose...</option>
                  {[...usStates, ...caStates, ...auStates, ...zaStates].map((st) => (
                    <option key={st.title} value={st.value}>
                      {st.title}
                    </option>
                  ))}
                  <option value="_NOTLISTED_">My State is not listed</option>
                </select>
                <div className="invalid-feedback">Please select a state.</div>
              </div>
              <div className="col-3 ps-1">
                <label className="form-label" htmlFor="zip">
                  Zip
                </label>
                <input
                  type="text"
                  className={state.zipInvalid ? "form-control is-invalid" : "form-control"}
                  value={state.zip}
                  onChange={(e) => dispatch({ type: actions.ZIP, data: { value: e.target.value } })}
                />
                <div className="invalid-feedback">Please provide a zip.</div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-12">
                <label className="form-label" htmlFor="country">
                  Country
                </label>
                <select
                  className={state.countryInvalid ? "form-control form-select is-invalid" : "form-control form-select"}
                  value={state.country}
                  onChange={(e) => dispatch({ type: actions.COUNTRY, data: { value: e.target.value } })}
                >
                  <option value="">Choose...</option>
                  {countries.map((st) => (
                    <option key={st.title} value={st.value}>
                      {st.title}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">Please select a country.</div>
              </div>
            </div>
          </div>
          <div className="col-12 mx-auto p-2">
            {props.signUpContext ? (
              <p className="mb-1">
                <strong>Let's get those digits.</strong>
              </p>
            ) : null}
            <div className="row mb-2">
              <div className="col-12">
                <label className="form-label" htmlFor="number">
                  Credit card number
                </label>
                <input
                  type="text"
                  className={state.numberInvalid ? "form-control is-invalid" : "form-control"}
                  name="cc-number"
                  autoComplete="cc-number"
                  maxLength="20"
                  value={state.number}
                  onChange={(e) => dispatch({ type: actions.NUMBER, data: { value: e.target.value } })}
                />
                <div className="invalid-feedback">Please provide a valid credit card number.</div>
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-9 pe-1">
                <label className="form-label" htmlFor="expMonth">
                  Expiration
                </label>
                <div className="row">
                  <div className="col-5 pe-0">
                    <select
                      className={state.expMonthInvalid ? "form-control form-select is-invalid" : "form-control form-select"}
                      name="cc-exp-month"
                      autoComplete="cc-exp-month"
                      value={state.expMonth}
                      onChange={(e) => dispatch({ type: actions.EXP_MONTH, data: { value: e.target.value } })}
                    >
                      <option value="">MM</option>
                      {renderCCMonths()}
                    </select>
                    <div className="invalid-feedback">Please select a valid month.</div>
                  </div>
                  <div className="col-auto px-0 pt-1">
                    <span className="px-1">/</span>
                  </div>
                  <div className="col-6 ps-0">
                    <select
                      className={state.expYearInvalid ? "form-control form-select is-invalid" : "form-control form-select"}
                      name="cc-exp-year"
                      autoComplete="cc-exp-year"
                      value={state.expYear}
                      onChange={(e) => dispatch({ type: actions.EXP_YEAR, data: { value: e.target.value } })}
                    >
                      <option value="">YYYY</option>
                      {renderCCYears()}
                    </select>
                    <div className="invalid-feedback">Please select a year.</div>
                  </div>
                </div>
              </div>
              <div className="col-3 ps-1">
                <label className="form-label" htmlFor="cvc">
                  CVC
                </label>
                <input
                  className={state.cvcInvalid ? "form-control is-invalid" : "form-control"}
                  name="cc-csc"
                  autoComplete="cc-csc"
                  maxLength="4"
                  value={state.cvc}
                  onChange={(e) => dispatch({ type: actions.CVC, data: { value: e.target.value } })}
                />
                <div className="invalid-feedback">Please provide a CVC.</div>
              </div>
            </div>
          </div>
        </div>
        <CreditCardFormButtonGroup
          showCancelButton={props.totalCards > 0}
          disabled={state.working}
          onClick={props.cancel}
          useLargeButtons={props.useLargeButtons || null}
        >
          <SnailButton
            className={`btn btn-secondary${props.useLargeButtons ? " btn-lg fw-bold" : ""}${props.totalCards > 0 ? " col text-nowrap" : ""}`}
            type="submit"
            text={props.signUpContext ? "Review order" : "Save card"}
            disabled={state.working}
            workingTexts={["Processing...", "Hang tight...", "Still working..."]}
            working={state.working}
          />
        </CreditCardFormButtonGroup>
      </form>
    </div>
  );
}

StandaloneCreditCardForm.propTypes = {
  member: PropTypes.object,
  totalCards: PropTypes.number,
  addCreditCard: PropTypes.func,
  cancel: PropTypes.func,
  dismiss: PropTypes.func,
  signUpContext: PropTypes.bool,
};
