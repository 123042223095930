import axios from "axios";
import { useEffect, useState } from "react";
import Helpers from "../Helpers";

export default function useCache() {
  const {isEmpty, isNotEmpty} = Helpers();
  const [characteristics, setCharacteristics] = useState([]);
  const [measurementUnits, setMeasurementUnits] = useState([]);
  const [measurementUnitConversions, setMeasurementUnitConversions] = useState([]);
  const [prepInstructions, setPrepInstructions] = useState([]);
  const [specialScalingIds, setSpecialScalingIds]= useState([]);
  const [tags, setTags] = useState([]);
  const [tools, setTools] = useState([]);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API}/general_data/portal-cache`).then(res => {
      setCharacteristics(res.data.characteristics);
      setMeasurementUnits(res.data.measurementUnits);
      setMeasurementUnitConversions(res.data.measurementUnitConversions);
      setPrepInstructions(res.data.prepInstructions);
      setSpecialScalingIds(res.data.specialScalingIds);
      setTags(res.data.tags);
      setTools(res.data.tools);
      setReady(true);
    });
  }, []);

  function toolName(id) {
    let result = "";
    if (isNotEmpty(id)) {
      for (let i = 0, iLen = tools.length; i < iLen; i++) {
        if (tools[i].id === id) {
          result = tools[i].name;
          break;
        }
      }
    }
    return result;
  }

  function toolId(name) {
    let result = "";
    if (isNotEmpty(name)) {
      for (let i = 0, iLen = tools.length; i < iLen; i++) {
        if (tools[i].name.toLowerCase() === name.toLowerCase()) {
          result = tools[i].id;
          break;
        }
      }
    }
    return result;
  }

  function toolNames(ids) {
    const result = [];
    for (let id of ids) {
      const name = toolName(id);
      if (name !== "") {
        result.push(name);
      }
    }
    return result;
  }

  function measurementUnitName(id) {
    let result = "";
    if (isNotEmpty(id)) {
      for (let i = 0, iLen = measurementUnits.length; i < iLen; i++) {
        if (measurementUnits[i].id === id) {
          result = measurementUnits[i].name;
          break;
        }
      }
    }
    return result;
  }

  function measurementUnitId(name) {
    let result = "";
    if (isNotEmpty(name)) {
      for (let i = 0, iLen = measurementUnits.length; i < iLen; i++) {
        if (measurementUnits[i].name.toLowerCase() === name.toLowerCase()) {
          result = measurementUnits[i].id;
          break;
        }
      }
    }
    return result;
  }

  function measurementUnitSystem(id) {
    if (isEmpty(id)) {
      return "";
    } else {
      for (let i = 0, iLen = measurementUnits.length; i < iLen; i++) {
        if (measurementUnits[i].id === id)
          return measurementUnits[i].system;
      }
    }
  }

  function measurementUnitRoundingValues(id) {
    if (isEmpty(id)) {
      return "";
    } else {
      for (let i = 0, iLen = measurementUnits.length; i < iLen; i++) {
        if (measurementUnits[i].id === id)
          return measurementUnits[i].roundingValues;
      }
    }
  }

  function pluralizedUnit(id) {
    if (isEmpty(id)) {
      return "";
    } else {
      for (let i = 0, iLen = measurementUnits.length; i < iLen; i++) {
        if (measurementUnits[i].id === id)
          return measurementUnits[i].pluralName;
      }
    }
  }

  function changedMeasurementUnitId(measurementUnitId, system) {
    if (isEmpty(measurementUnitId) || isEmpty(system)) {
      return measurementUnitId;
    } else {
      for (let i = 0, iLen = measurementUnitConversions.length; i < iLen; i++) {
        if (measurementUnitConversions[i].measurementUnitFromId === measurementUnitId
          && measurementUnitSystem(measurementUnitConversions[i].measurementUnitToId) === system)
          return measurementUnitConversions[i].measurementUnitToId;
        if (measurementUnitConversions[i].measurementUnitToId === measurementUnitId
          && measurementUnitSystem(measurementUnitConversions[i].measurementUnitFromId) === system)
          return measurementUnitConversions[i].measurementUnitFromId;
      }
    }
    return measurementUnitId;
  }

  function measurementUnitConversionRate(fromUnitId, toUnitId) {
    if (isEmpty(fromUnitId) || isEmpty(toUnitId)) {
      return 1;
    } else {
      for (let i = 0, iLen = measurementUnitConversions.length; i < iLen; i++) {
        if (measurementUnitConversions[i].measurementUnitFromId === fromUnitId && measurementUnitConversions[i].measurementUnitToId === toUnitId)
          return measurementUnitConversions[i].conversionRate;
        if (measurementUnitConversions[i].measurementUnitToId === fromUnitId && measurementUnitConversions[i].measurementUnitFromId === toUnitId)
          return 1 / measurementUnitConversions[i].conversionRate;
      }
    }
  }
  
  function characteristicName(id) {
    if (isEmpty(id)) {
      return "";
    } else {
      for (let i = 0, iLen = characteristics.length; i < iLen; i++) {
        if (characteristics[i].id === id)
          return characteristics[i].name;
      }
    }
  }

  function recipeAllergens(ids) {
    let result = [];
    const allergens = characteristics.filter(c => ids.indexOf(c.id) === -1);
    for (let a of allergens) {
      result.push(a.allergen)
    }
    return result;
  }

  function prepInstructionText(id) {
    let result = "";
    if (isNotEmpty(id)) {
      const pi = prepInstructions.find(p => p.id === id);
      if (pi !== undefined) {
        result = pi.text
      }
    }
    return result;
  }

  function tagIdsFromNames(names) {
    return tags.filter(t => names.indexOf(t.tag.toLowerCase()) !== -1).map(t => t.id);
  }

  function firstLevelTags() {
    return tags.filter(t => t.searchCategory && !t.hideInSearch);
  }

  function secondLevelTags() {
    return tags.filter(t => !t.searchCategory && !t.hideInSearch);
  }

  const cache = {
    characteristics: characteristics,
    ready: ready,
    changedMeasurementUnitId: changedMeasurementUnitId,
    characteristicName: characteristicName,
    firstLevelTags: firstLevelTags,
    measurementUnitConversionRate: measurementUnitConversionRate,
    measurementUnitId: measurementUnitId,
    measurementUnitName: measurementUnitName,
    measurementUnitRoundingValues: measurementUnitRoundingValues,
    measurementUnitSystem: measurementUnitSystem,
    pluralizedUnit: pluralizedUnit,
    prepInstructionText: prepInstructionText,
    recipeAllergens: recipeAllergens,
    secondLevelTags: secondLevelTags,
    tags: tags,
    tagIdsFromNames: tagIdsFromNames,
    toolId: toolId,
    toolName: toolName,
    toolNames: toolNames,
    tools: tools,
    specialScalingIds: specialScalingIds,
  };

  return {cache};
}