import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";

export default function SubscriptionCancellationForm(props) {
  const { isNotEmpty } = Helpers();
  const [didNotCancel, setDidNotCancel] = useState(false);
  const [canceledFinancial, setCanceledFinancial] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [takingBreak, setTakingBreak] = useState(false);
  const [noTimeToCook, setNoTimeToCook] = useState(false);
  const [portal, setPortal] = useState(false);
  const [financial, setFinancial] = useState(false);
  const [notUsing, setNotUsing] = useState(false);
  const [other, setOther] = useState(false);
  const [otherReason, setOtherReason] = useState("");
  const [gift, setGift] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [working, setWorking] = useState(false);

  const takingBreakText = "I'm taking a break, I'll be back";
  const noTimeToCookText = "I'm not finding time to cook this way";
  const portalText = "I find it difficult to use the Portal";
  const financialText = "Financial reasons";
  const notUsingText = "I'm simply not using it";
  const giftText = "Consider me for sponsorship";

  useEffect(() => {
    if (props.shouldReset) {
      setTakingBreak(false);
      setNoTimeToCook(false);
      setPortal(false);
      setFinancial(false);
      setGift(false);
      setNotUsing(false);
      setOther(false);
      setOtherReason("");
      setFeedback("");
      setDidNotCancel(false);
      setCanceled(false);
      setCanceledFinancial(false);
    }
  }, [props.shouldReset]);

  const canCancel =
    !didNotCancel && (takingBreak || noTimeToCook || portal || financial || notUsing || (other && isNotEmpty(otherReason)) || isNotEmpty(feedback));

  function cancel(event) {
    event.preventDefault();
    if (financial && !canceledFinancial) {
      setCanceledFinancial(true);
    } else {
      setWorking(true);
      let text = "Membership cancellation\n";
      if (takingBreak) {
        text += `${takingBreakText}\n`;
      }
      if (noTimeToCook) {
        text += `${noTimeToCookText}\n`;
      }
      if (portal) {
        text += `${portalText}\n`;
      }
      if (financial) {
        text += `${financialText}\n`;
      }
      if (gift) {
        text += `${giftText}\n`;
      }
      if (notUsing) {
        text += `${notUsingText}\n`;
      }
      if (other) {
        text += `Other: ${otherReason}\n`;
      }
      text += `Feedback: ${feedback}`;
      props.cancel({ text: text }, () => {
        setWorking(false);
        setCanceled(true);
      });
    }
  }

  function cancelButton(initialText) {
    let result;
    if (working) {
      result = (
        <button className="btn btn-primary me-2 mb-2 mb-lg-0" type="button" disabled={true}>
          <span className="spinner-border spinner-border-sm" />
          <span className="ms-1">Cancelling...</span>
        </button>
      );
    } else if (canCancel) {
      result = (
        <button className="btn btn-outline-primary me-2 mb-2 mb-lg-0" type="button" onClick={cancel}>
          {initialText}
        </button>
      );
    } else {
      result = (
        <button className="btn btn-outline-primary me-2 mb-2 mb-lg-0" type="button" disabled={true}>
          {initialText}
        </button>
      );
    }
    return result;
  }

  function buttons() {
    const noButton = working ? (
      <button className="btn btn-secondary me-2 mb-2 mb-lg-0" type="button" disabled={true}>
        Nevermind
      </button>
    ) : (
      <button className="btn btn-secondary me-2 mb-2 mb-lg-0" type="button" onClick={() => setDidNotCancel(true)}>
        Nevermind
      </button>
    );
    return (
      <>
        {noButton}
        {cancelButton("No, I want to cancel")}
      </>
    );
  }

  function modalContent() {
    let result = null;
    if (didNotCancel) {
      result = (
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Woot!</h1>
            <button type="button" className="btn-close btn-close-white" onClick={() => props.hide()} />
          </div>
          <div className="modal-body">
            <div>
              <p>
                Your membership <strong>remains active</strong> and we're glad you're here.
              </p>
              <p>
                What can we do to help make the most of your membership? Drop us a note at{" "}
                <a href="mailto:support@cleanfooddirtygirl.com">support@cleanfooddirtygirl.com</a> and we're happy to help!
              </p>
            </div>
            <button className="btn btn-secondary" type="button" onClick={() => props.hide()}>
              Awesome, back to plants
            </button>
          </div>
        </div>
      );
    } else if (canceled) {
      result = (
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">We've enjoyed having you!</h1>
            <button type="button" className="btn-close btn-close-white" onClick={() => props.hide()} />
          </div>
          <div className="modal-body">
            <div>
              <p>
                Your membership <strong>is now canceled</strong>.
              </p>
              <p>
                You will continue to have access to Plant Fueled Life until <strong>{props.lastDay}</strong>. After that date, you must reactivate your
                membership to regain access.
              </p>
              {gift ? <p>We will reach out to you via email if you've been allocated a gift membership.</p> : null}
            </div>
            <button className="btn btn-secondary" type="button" onClick={() => props.hide()}>
              Close
            </button>
          </div>
        </div>
      );
    } else if (canceledFinancial) {
      result = (
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">Sponsorship</h1>
          </div>
          <div className="modal-body">
            <div>
              <p>
                <strong>Would you like to be considered for sponsorship?</strong>
              </p>
              <p>
                Sometimes members purchase gift memberships for Team Dirty to allocate to someone in need. Would you be interested in receiving a gift should
                one become available?
              </p>
            </div>
            <div className="form-check">
              <input className="form-check-input cursor-pointer" type="radio" name="radioGift" id="radioGift1" onChange={(e) => setGift(e.target.checked)} />
              <label className="form-check-label cursor-pointer" htmlFor="radioGift1">
                {giftText}
              </label>
            </div>
            <div className="form-check mb-3">
              <input className="form-check-input cursor-pointer" type="radio" name="radioGift" id="radioGift2" onChange={(e) => setGift(!e.target.checked)} />
              <label className="form-check-label cursor-pointer" htmlFor="radioGift2">
                No, thank you
              </label>
            </div>
            {cancelButton("Complete my cancellation")}
          </div>
        </div>
      );
    } else {
      result = (
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title">One quick thing</h1>
          </div>
          <div className="modal-body">
            <div>
              {props.hasMP && (
                <div className="alert alert-info" role="alert">
                  <strong>Would you like to keep access to the Recipe Vault for just $12/month?</strong>
                  <br />
                  If you don't need all the bells and whistles but want to keep cooking the best recipes around, we got you.
                  <br />
                  <br />
                  <a href="/account?m=become-a-member" className="btn btn-secondary me-2 mb-2 mb-lg-0">
                    Yes, change my plan
                  </a>
                </div>
              )}
              <p className="mb-2">
                <strong>Before you cancel, can you let us know why you're canceling? (required)</strong>
              </p>
              <div className="ps-3 cancel-form">
                <div className="form-check mb-2">
                  <label className="form-check-label">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      checked={takingBreak}
                      onChange={(e) => setTakingBreak(e.target.checked)}
                    />{" "}
                    {takingBreakText}
                  </label>
                </div>
                <div className="form-check mb-2">
                  <label className="form-check-label">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      checked={noTimeToCook}
                      onChange={(e) => setNoTimeToCook(e.target.checked)}
                    />{" "}
                    {noTimeToCookText}
                  </label>
                </div>
                <div className="form-check mb-2">
                  <label className="form-check-label">
                    <input className="form-check-input cursor-pointer" type="checkbox" checked={portal} onChange={(e) => setPortal(e.target.checked)} />{" "}
                    {portalText}
                  </label>
                </div>
                <div className="form-check mb-2">
                  <label className="form-check-label">
                    <input className="form-check-input cursor-pointer" type="checkbox" checked={financial} onChange={(e) => setFinancial(e.target.checked)} />{" "}
                    {financialText}
                  </label>
                </div>
                <div className="form-check mb-2">
                  <label className="form-check-label">
                    <input className="form-check-input cursor-pointer" type="checkbox" checked={notUsing} onChange={(e) => setNotUsing(e.target.checked)} />{" "}
                    {notUsingText}
                  </label>
                </div>
                <div className="input-group mb-3">
                  <div className="input-group-text bg-white border-0">
                    <input className="form-check-input mt-0 ms-0 cursor-pointer" type="checkbox" checked={other} onChange={(e) => setOther(e.target.checked)} />
                  </div>
                  <input
                    type="text"
                    className="form-control rounded"
                    placeholder="Other"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                    readOnly={!other}
                  />
                </div>
              </div>
              <p className="mb-2">
                <strong>Anything else we should know?</strong>
              </p>
              <textarea
                className="form-control mb-3"
                placeholder="We appreciate your honest feedback."
                rows={2}
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />
              <p>
                You will continue to have access to Plant Fueled Life until <strong>{props.lastDay}</strong>. After that date, you must reactivate your
                membership to regain access.
              </p>
              {buttons()}
            </div>
          </div>
        </div>
      );
    }
    return result;
  }

  return modalContent();
}

SubscriptionCancellationForm.propTypes = {
  hasMP: PropTypes.bool,
  lastDay: PropTypes.string,
  shouldReset: PropTypes.bool,
  cancel: PropTypes.func,
  hide: PropTypes.func,
};
