// ***** RecipeStore.js *****
// This store interacts with api endpoints defined in RecipeVaultResource.java
// This store provides data and function to views: StandaloneRecipe.js

import {useReducer} from "react";
import axios from "axios";
import Helpers from "../Helpers";
import Constants from "../Constants";
import {DateTime} from "luxon";

const actions = {
  CHANGE_COOKING_METHOD: "changeCookingMethod",
  CHANGE_PORTIONS: "changePortions",
  LOAD: "load",
  LOAD_PUBLIC_DATA: "loadPublicData",
  LOADING: "loading",
  UPDATE_CUSTOMIZATIONS: "updateCustomizations",
  UPDATE_CHILD_RECIPE_ADDITIONAL_PORTIONS: "updateChildRecipeAdditionalPortions",
  UPDATE_RECIPES_CHECKBOXES: "updateRecipesCheckboxes",
  UPDATE_DELETED_CHILD_RECIPES: "updateDeletedChildRecipes",
  UPDATING_CHECKBOXES: "updatingCheckboxes",
};

const initialState = {
  standaloneRecipe: {
    recipeId: null,
    recipeData: {masters: [], recipes: [], views: []},
    childRecipeIds: [],
    ingredients: [],
    ingredientNutrients: [],
    ratio: 1,
    reviews: [],
    notes: [],
  },
  customizations: {
    portions: 4,
    recipeTools: [],
    recipesCheckboxes: [],
    deletedChildRecipes: [],
    childRecipeAdditionalPortions: [],
  },
  loading: true,
  publicData: {
    id: null,
    name: "",
    description: "",
    photo: "",
    stars:0,
    allergens: [],
  },
  updatingCheckboxes: false,
};

export default function useRecipe(cache, rh, token, member, ss, getPersonalizedContent) {
  const {handleError, isNotEmpty} = Helpers();
  const {recipeComponentCategories, recipeViewContexts} = Constants();
  function reducer(state, action) {
    function reset() {
      state.standaloneRecipe.recipeData.recipes = JSON.parse(JSON.stringify(state.standaloneRecipe.recipeData.masters));
      addDefaultPortions(state.standaloneRecipe.recipeData);
      state.standaloneRecipe.recipeData.views = [];
      state.standaloneRecipe.ratio = 1;
    }
    function scale(data) {
      ss.customizeRecipesComponents(data.standaloneRecipe.recipeData, data.customizations.recipeTools);
      ss.deleteChildRecipes(data.standaloneRecipe.recipeData.recipes, data.customizations.deletedChildRecipes);
      removeDeletedRecipes(data);
      const childRecipeIds = []; // This property has the correct order of preparation for recipes
      addAppearances(data.standaloneRecipe, childRecipeIds);
      data.standaloneRecipe.childRecipeIds = [...childRecipeIds].reverse();
      setRatio(data);
      addAllRatios(data.standaloneRecipe);
      ss.adjustMinimumYield(data.standaloneRecipe.recipeData.recipes, data.customizations.childRecipeAdditionalPortions);
      ss.scale(recipeViewContexts.STANDALONE_RECIPE, data.standaloneRecipe.recipeData.recipes, data.standaloneRecipe.ingredients);
      ss.addMissingProperties(data.standaloneRecipe.recipeData.recipes, data.standaloneRecipe.ingredients);
      data.standaloneRecipe.recipeData.views = data.standaloneRecipe.recipeData.recipes.map(r => ss.generateRecipeView(recipeViewContexts.STANDALONE_RECIPE, r, data.standaloneRecipe.recipeData.recipes, data.standaloneRecipe.ingredients, data.customizations));
    }
    if (action.type === actions.LOADING) {
      return { ...state, loading: action.data.loading};
    } else if (action.type === actions.LOAD) {
      const standaloneRecipe = {
        recipeId: action.data.standaloneRecipe.recipeId,
        recipeData: {masters: action.data.standaloneRecipe.recipes, recipes: JSON.parse(JSON.stringify(action.data.standaloneRecipe.recipes)), views: []},
        ingredients: action.data.standaloneRecipe.ingredients,
        ingredientNutrients: action.data.standaloneRecipe.ingredientNutrients,
        ratio: 1,
        reviews: action.data.standaloneRecipe.recipes.find(r => r.id === action.data.standaloneRecipe.recipeId).reviews,
        notes: action.data.standaloneRecipe.notes,
      };
      const data = { standaloneRecipe: standaloneRecipe, customizations: action.data.customizations};
      addDefaultPortions(data.standaloneRecipe.recipeData);
      scale(data);
      return { ...state, loading: false, standaloneRecipe:  data.standaloneRecipe, customizations: data.customizations};
    } else if (action.type === actions.LOAD_PUBLIC_DATA) {
      const publicData = {
        id: action.data.publicData.id,
        name: action.data.publicData.name,
        description: action.data.publicData.description,
        photo: action.data.publicData.photo,
        stars: action.data.publicData.stars,
        allergens: cache.recipeAllergens(action.data.publicData.characteristicIds),
      };
      return { ...state, loading: false, publicData:  publicData};
    } else if (action.type === actions.UPDATE_CUSTOMIZATIONS) {
      reset();
      const data = {...state, customizations: action.data.customizations};
      scale(data);
      return data;
    } else if (action.type === actions.CHANGE_COOKING_METHOD) {
      reset();
      const customizations = {...state.customizations, recipeTools: action.data.recipeTools};
      const data = {...state, customizations: customizations};
      scale(data);
      return data;
    } else if (action.type === actions.CHANGE_PORTIONS) {
      reset();
      const customizations = {...state.customizations, portions: action.data.portions};
      const data = {...state, customizations: customizations};
      scale(data);
      return data;
    } else if (action.type === actions.UPDATING_CHECKBOXES) {
      return { ...state, updatingCheckboxes: true };
    } else if (action.type === actions.UPDATE_RECIPES_CHECKBOXES) {
      const customizations = {...state.customizations, recipesCheckboxes: action.data.recipesCheckboxes};
      const data = {...state, updatingCheckboxes: false, customizations: customizations};
      const view = data.standaloneRecipe.recipeData.views.find(v => v.id === action.data.recipeId);
      if (view !== undefined) {
        const recipeCheckboxes = action.data.recipesCheckboxes.find(rc => rc.recipeId === action.data.recipeId);
        if (view.checkboxes !== recipeCheckboxes.checkboxes) {
          view.checkboxes = recipeCheckboxes.checkboxes; 
        }
      }
      return data;
    } else if (action.type === actions.UPDATE_DELETED_CHILD_RECIPES) {
      reset();
      const customizations = {...state.customizations, deletedChildRecipes: action.data.deletedChildRecipes};
      const data = {...state, customizations: customizations};
      scale(data);
      return data;
    } else if (action.type === actions.UPDATE_CHILD_RECIPE_ADDITIONAL_PORTIONS) {
      reset();
      const customizations = {...state.customizations, childRecipeAdditionalPortions: action.data.childRecipeAdditionalPortions};
      const data = {...state, customizations: customizations};
      scale(data);
      return data;
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  function addDefaultPortions(recipeData) {
    for (let recipe of recipeData.recipes) {
      const master = recipeData.masters.find(r => r.id === recipe.id);
      if (isNotEmpty(master)) {
        recipe["defaultPortions"] = master.portions;
      }
    }
  }

  function removeDeletedRecipes(data) {
    const deletedRecipeIds = data.customizations.deletedChildRecipes.map(cr => cr.childRecipeId);
    data.standaloneRecipe.recipeData.recipes = data.standaloneRecipe.recipeData.recipes.filter(r => deletedRecipeIds.indexOf(r.id) === -1);
  }

  function setRatio(data) {
    const recipe = data.standaloneRecipe.recipeData.recipes.find(r => r.id === data.standaloneRecipe.recipeId);
    if (cache.specialScalingIds.indexOf(recipe.id) === -1) {
      if (isNotEmpty(data.customizations.portions)) {
        data.standaloneRecipe.ratio = data.customizations.portions / recipe.portions;
        recipe.portions = data.customizations.portions;
      } else if (isNotEmpty(member.preferences.portions)) {
        data.standaloneRecipe.ratio = member.preferences.portions / recipe.portions;
        recipe.portions = member.preferences.portions;
      }
    } else {
      if (isNotEmpty(data.customizations.portions)) {
        data.standaloneRecipe.ratio = data.customizations.portions / recipe.portions;
        recipe.portions = data.customizations.portions;
      }
    }
  }

  function addAllRatios(standaloneRecipe) {
      const recipe = standaloneRecipe.recipeData.recipes.find(r => r.id === standaloneRecipe.recipeId);
      recipe["ratio"] = standaloneRecipe.ratio;
      const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
      processComponents(standaloneRecipe.recipeData.recipes, components, standaloneRecipe.ratio);
  }

  // copied from WMPScalingService
  function addAppearances(standaloneRecipe, childRecipeIds) {
    const recipe = standaloneRecipe.recipeData.recipes.find(r => r.id === standaloneRecipe.recipeId);
    if (!recipe.hasOwnProperty("appearances")) {
      recipe["appearances"] = [];
    }
    recipe.appearances.push({recipeId: recipe.id});
    addChildAppearances(standaloneRecipe.recipeData.recipes, recipe, childRecipeIds);
  }

  function addChildAppearances(recipes, recipe, childRecipeIds) {
    const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
    for (let component of components) {
      if (component.category === recipeComponentCategories.RECIPE) {
        addChildAppearance(recipes, component, childRecipeIds);
      }
    }
  }

  function addChildAppearance(recipes, component, childRecipeIds) {
    const recipe = recipes.find(r => r.id === component.childRecipeId);
    const childRecipeIdIndex = childRecipeIds.indexOf(recipe.id);
    if (childRecipeIdIndex !== -1) {
      // When the child recipe already exist, we remove it and add it back at the end of
      // the array. Since this array will be eventually reversed, we make sure that the 
      // deepest child recipes appear first when making a recipe
      childRecipeIds.splice(childRecipeIdIndex, 1);
    }
    childRecipeIds.push(recipe.id);
    if (!recipe.hasOwnProperty("appearances")) {
      recipe["appearances"] = [];
    }
    if (isNotEmpty(component.volume)) {
      recipe.appearances.push({recipeId: component.parentRecipeId});
      addChildAppearances(recipes, recipe, childRecipeIds);
    }
  }

  // copied from WMPScalingService
  function processComponents(recipes, components, ratio) {
    for (let component of components) {
      if (component.category === recipeComponentCategories.RECIPE) {
        processRecipe(recipes, component, ratio);
      }
    }
  }

  // copied from WMPScalingService
  function processRecipe(recipes, component, ratio) {
    const recipe = recipes.find(r => r.id === component.childRecipeId);
    if (!recipe.hasOwnProperty("ratio")) {
      recipe["ratio"] = 0;
    }
    let appearanceRatio = 0;
    if (isNotEmpty(component.volume)) {
      appearanceRatio = component.volume / recipe.yieldVolume * ratio;
    }
    recipe.ratio += appearanceRatio;
    const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
    processComponents(recipes, components, appearanceRatio);
  }

  function get(name) {
    dispatch({
      type: actions.LOADING,
      data: {loading: true}
    });
    const nameParts = name.split("-");
    if (nameParts.length > 1) {
      const id = nameParts[0];
      if (isNotEmpty(token)) {
        getAllData(id);
      } else {
        getPublicData(id);
      }
    }
  }

  function getAllData(id, cb) {
    const recipePromise = axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${id}`,
    });
    const customizationsPromise = axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${id}/customizations`,
    });
    Promise.all([recipePromise, customizationsPromise])
      .then(responses => {
        dispatch({
          type: actions.LOAD,
          data: {standaloneRecipe: responses[0].data, customizations: responses[1].data}
        });
        if (cb) cb();
      }).catch(err => {
      handleError(err);
    });
  }

  function getPublicData(id) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${id}/public-data`,
    }).then(res => {
      dispatch({
        type: actions.LOAD_PUBLIC_DATA,
        data: { publicData: res.data}
      });
    }).catch(err => {
      handleError(err);
    });
  }

  function changeCookingMethod(recipeId, toolName) {
    const toolId = cache.toolId(toolName);
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${state.standaloneRecipe.recipeId}/recipe-tools/${recipeId}/tools/${toolId}`,
      data: {recipeId: recipeId, toolId: toolId}
    }).then(res => {
      dispatch({
        type: actions.CHANGE_COOKING_METHOD,
        data: { recipeTools: res.data}
      });
    });
  }

  function changePortions(portions) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${state.standaloneRecipe.recipeId}`,
      data: {recipeId: state.standaloneRecipe.recipeId, portions: portions}
    }).then(res => {
      dispatch({
        type: actions.CHANGE_PORTIONS,
        data: { portions: res.data }
      });
    });
  }

  function updateCheckboxes(recipeId, checkboxes) {
    dispatch({type: actions.UPDATING_CHECKBOXES});
    let updatePersonalizedContent = false;
    const recipeMade = { 
      madeAt: DateTime.local().toString(), // get local datetime to record or remove recipe made at
      recipeId: recipeId,
      standaloneRecipeId: state.standaloneRecipe.recipeId,
    };
    if (checkboxes.filter(c => !c).length === 0) { // if all checkboxes are true, record a recipe made
      updatePersonalizedContent = true;
      const recipe = state.standaloneRecipe.recipeData.recipes.find(r => r.id === recipeId);
      let stoveTopOrInstantPot = null;
      if (recipe.hasInstantPotAndStoveTopVersions) {
        stoveTopOrInstantPot = recipe.stoveTopRecipeComponents.length > 0 ? "Stove top" : "Instant Pot";
      }
      recipeMade["portions"] = state.standaloneRecipe.recipeId === recipeId ? recipe.portions : null;
      recipeMade["yieldVolume"] = recipe.scaledYieldVolume;
      recipeMade["yieldUnitId"] = recipe.yieldUnitId;
      recipeMade["stoveTopOrInstantPot"] = stoveTopOrInstantPot;
    }
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${state.standaloneRecipe.recipeId}/recipe-checkboxes/${recipeId}`,
      data: {recipeCheckboxes: {recipeId: recipeId, checkboxes: checkboxes}, recipeMade: recipeMade}
    }).then(res => {
      if (updatePersonalizedContent) {
        getPersonalizedContent();
      }
      dispatch({
        type: actions.UPDATE_RECIPES_CHECKBOXES,
        data: {recipeId: recipeId, recipesCheckboxes: res.data}
      });
    });
  }

  function deleteChildRecipe(parentRecipeId, childRecipeId) {
    const parentRecipe = state.standaloneRecipe.recipeData.recipes.find(r => r.id === state.standaloneRecipe.recipeId);
    if (isNotEmpty(parentRecipe)) {
      const components = parentRecipe.stoveTopRecipeComponents.length > 0 ? parentRecipe.stoveTopRecipeComponents : parentRecipe.instantPotRecipeComponents;
      const rc = components.find(rc => rc.childRecipeId === childRecipeId);
      if (isNotEmpty(rc)) {
        axios({
          method: "delete",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          url: `${process.env.REACT_APP_API}/recipe-vault/${state.standaloneRecipe.recipeId}/child-recipes/${childRecipeId}`,
        }).then(res => {
          dispatch({
            type: actions.UPDATE_DELETED_CHILD_RECIPES,
            data: { deletedChildRecipes: res.data }
          });
        });
      }
    }
  }

  function updateChildRecipeAdditionalPortions(data) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${state.standaloneRecipe.recipeId}/child-recipes/${data.childRecipeId}/additional-portions`,
      data: data,
    }).then(res => {
      dispatch({
        type: actions.UPDATE_CHILD_RECIPE_ADDITIONAL_PORTIONS,
        data: { childRecipeAdditionalPortions: res.data }
      });
    });
  }

  function restoreChildRecipes() {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${state.standaloneRecipe.recipeId}/restore-child-recipes`,
    }).then(res => {
      dispatch({
        type: actions.UPDATE_DELETED_CHILD_RECIPES,
        data: { deletedChildRecipes: res.data }
      });
    });
  }

  function deleteCustomizations() {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${state.standaloneRecipe.recipeId}/customizations`,
    }).then(res => {
      dispatch({
        type: actions.UPDATE_CUSTOMIZATIONS,
        data: { customizations: res.data}
      });
    });
  }

  function prepareDataForNutritionFacts(recipeId) {
    return {
      recipeId: recipeId,
      recipeData: {masters: JSON.parse(JSON.stringify(state.standaloneRecipe.recipeData.masters)), recipes: JSON.parse(JSON.stringify(state.standaloneRecipe.recipeData.masters))},
      recipeTools: state.customizations.recipeTools,
      deletedChildRecipes: state.customizations.deletedChildRecipes,
      ingredients: state.standaloneRecipe.ingredients,
      ingredientNutrients: state.standaloneRecipe.ingredientNutrients,
    };
  }

  const recipeStore = {
    state: state,
    specialScalingIds: cache.specialScalingIds,
    changeCookingMethod: changeCookingMethod,
    changePortions: changePortions,
    deleteChildRecipe: deleteChildRecipe,
    get: get,
    prepareDataForNutritionFacts: prepareDataForNutritionFacts,
    restoreChildRecipes: restoreChildRecipes,
    updateCheckboxes: updateCheckboxes,
    updateChildRecipeAdditionalPortions: updateChildRecipeAdditionalPortions,
    deleteCustomizations: deleteCustomizations,
  }
  return {recipeStore};
}
