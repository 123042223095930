import "./RecipeComponent.scss";
import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../services/Helpers";
import MarkdownViewer from "./MarkdownViewer";
import Popover from "./Popover";
import Constants from "../../services/Constants";

export default function RecipeComponent(props) {
  const { recipeComponentCategories } = Constants();
  const { capitalizeFirstLetter, isEmpty, isNotEmpty } = Helpers();

  let result;
  if (props.component.category === recipeComponentCategories.BLANK_LINE) {
    result = <br key={props.component.id} />;
  } else if (props.component.category === recipeComponentCategories.TEXT_ONLY) {
    result = (
      <div className="text-only-component text-muted" key={props.component.id}>
        <MarkdownViewer key={props.component.id} markdown={props.component.text} />
      </div>
    );
  } else if (props.component.category === recipeComponentCategories.INGREDIENT) {
    let name = isEmpty(props.component.invertedName) ? props.component.name : props.component.invertedName;
    if (props.component.displayQuantityInParentRecipe) {
      if (isEmpty(props.component.volume) && isNotEmpty(props.component.weight)) {
        name = `${props.component.weight} ${name}`;
      } else if (isNotEmpty(props.component.volume)) {
        name = `${props.component.volume} ${name}`;
      }
    } else {
      name = capitalizeFirstLetter(name);
    }
    const popover =
      isNotEmpty(props.component.photo) || isNotEmpty(props.component.ingredientNotesAndTips) ? (
        <Popover title={props.component.name} photo={props.component.photo} content={props.component.ingredientNotesAndTips} />
      ) : (
        ""
      );
    let prepInstruction = "";
    if (isNotEmpty(props.component.prepInstruction)) {
      const commaOrNot = ["juice", "zest", "brine"].indexOf(props.component.prepInstruction) !== -1 ? " " : " ";
      prepInstruction = `${commaOrNot}${props.component.prepInstruction}`;
    }
    const firstPart = (
      <>
        <span className="main-part">
          {name}
          {popover}
        </span>
        <span className="secondary-part text-muted">{prepInstruction}</span>
      </>
    );
    const additionalInstruction = isNotEmpty(props.component.additionalInstruction) ? ` ${props.component.additionalInstruction}` : "";
    const weight = isNotEmpty(props.component.volume) && isNotEmpty(props.component.weight) ? ` (${props.component.weight})` : "";

    result = (
      <div key={props.component.id}>
        {firstPart}
        <span className="secondary-part text-muted">
          {additionalInstruction}
          {weight}
        </span>
      </div>
    );
  } else if (props.component.category === recipeComponentCategories.RECIPE) {
    let name = props.component.name;
    if (props.component.displayQuantityInParentRecipe) {
      if (isEmpty(props.component.volume) && isNotEmpty(props.component.weight)) {
        name = `${props.component.weight} ${name}`;
      } else if (isNotEmpty(props.component.volume)) {
        name = `${props.component.volume} ${name}`;
      }
    } else {
      name = capitalizeFirstLetter(name);
    }
    const popover =
      isNotEmpty(props.component.reheatingInstructions) && props.parentIsMenuMeal ? <Popover content={props.component.reheatingInstructions} iconClassName="fas fa-heat text-secondary" /> : "";
    const prepInstruction = isNotEmpty(props.component.prepInstruction) ? ` ${props.component.prepInstruction}` : "";
    const additionalInstruction = isNotEmpty(props.component.additionalInstruction) ? ` ${props.component.additionalInstruction}` : "";
    const firstPart = (
      <>
        <span className="main-part">
          {name}
          {popover}
        </span>
        <span className="secondary-part text-muted">
          {prepInstruction}
          {additionalInstruction}
        </span>
      </>
    );
    const weight = props.component.displayQuantityInParentRecipe && isNotEmpty(props.component.volume) && isNotEmpty(props.component.weight) ? ` (${props.component.weight})` : "";

    result = (
      <div key={props.component.id}>
        {firstPart}
        <span className="secondary-part text-muted">{weight}</span>
      </div>
    );
  }
  return result;
}

RecipeComponent.propTypes = {
  component: PropTypes.object,
  parentIsMenuMeal: PropTypes.bool,
};
