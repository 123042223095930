import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

export default function NewSectionForm(props) {
  const [name, setName] = useState("");
  const [working, setWorking] = useState(false);

  useEffect(() => {
    const nextNumber = props.store.plan.sections.length + 1;
    setName(`Section ${nextNumber}`);
  }, [props.store.plan.sections])

  function handleSubmit(e) {
    e.preventDefault();
    setWorking(true);
    props.store.createSection(props.store.plan.id, {name: name}, () => {
      props.store.getPlan(props.store.plan.id);
      props.hide();
    });
  }

  const cancelButton = working ? <button type="button" className="btn btn-outline-primary me-2" disabled={true}>Cancel</button> : (
    <button
      type="button"
      className="btn btn-outline-primary me-2"
      onClick={props.hide}
    >
      Cancel
    </button>
  );

  const createButton = working ? (
    <button
      type="button"
      className="btn btn-primary"
      disabled={true}
    >
      <span className="spinner-border spinner-border-sm"/>
      <span className="ms-1">Creating...</span>
    </button>
  ) : <button type="submit" className="btn btn-primary">Create Section</button>;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            value={name}
            required={true}
            onChange={e => setName(e.target.value)}
          />
        </div>
        {cancelButton}
        {createButton}
      </form>
    </div>
  );
}

NewSectionForm.propTypes = {
  store: PropTypes.object,
  hide: PropTypes.func,
}