import "./NutritionFactsModal.css";
import React from "react";
import PropTypes from "prop-types";
import MacroNutrient from "./MacroNutrient";
import MicroNutrient from "./MicroNutrient";
import Helpers from "../../services/Helpers";
import NutritionFactsRecipeComponent from "./NutritionFactsRecipeComponent";

export default function NutritionFactsModal(props) {
  const {isNotEmpty} = Helpers();

  const config = [
    { name: "Energy", friendlyName: "Calories", category: "macro", showUnit: false, unit: ""},
    { name: "Total lipid (fat)", friendlyName: "Fat", category: "macro", showUnit: true, unit: "g"},
    { name: "Carbohydrate, by difference", friendlyName: "Carbs", category: "macro", showUnit: true, unit: "g"},
    { name: "Protein", friendlyName: "Protein", category: "macro", showUnit: true, unit: "g"},
    { name: "Fatty acids, total saturated", friendlyName: "Saturated Fat", category: "other", showUnit: true, unit: "g"},
    { name: "Fatty acids, total trans", friendlyName: "Trans Fat", category: "other", showUnit: true, unit: "g"},
    { name: "Fatty acids, total polyunsaturated", friendlyName: "Polyunsaturated Fat", category: "other", showUnit: true, unit: "g"},
    { name: "Fatty acids, total monounsaturated", friendlyName: "Monounsaturated Fat", category: "other", showUnit: true, unit: "g"},
    { name: "Fiber, total dietary", friendlyName: "Fiber", category: "other", showUnit: true, unit: "g"},
    { name: "Sugars, total including NLEA", friendlyName: "Sugar", category: "other", showUnit: true, unit: "g"},
    { name: "Calcium, Ca", friendlyName: "Calcium", category: "micro", showUnit: true, unit: "mg"},
    { name: "Iron, Fe", friendlyName: "Iron", category: "micro", showUnit: true, unit: "mg"},
    { name: "Potassium, K", friendlyName: "Potassium", category: "micro", showUnit: true, unit: "mg"},
    { name: "Sodium, Na", friendlyName: "Sodium", category: "micro", showUnit: true, unit: "mg"},
    { name: "Vitamin A, IU", friendlyName: "Vitamin A", category: "micro", showUnit: true, unit: "IU"},
    { name: "Vitamin C, total ascorbic acid", friendlyName: "Vitamin C", category: "micro", showUnit: true, unit: "mg"},
    { name: "Vitamin E (alpha-tocopherol)", friendlyName: "Vitamin E", category: "micro", showUnit: true, unit: "mg"},
    { name: "Zinc, Zn", friendlyName: "Zinc", category: "micro", showUnit: true, unit: "mg"},
  ];

  const macros = config.filter(i => i.category === "macro").map(i => i.name);
  const macroNutrients = [];
  const others = config.filter(i => i.category === "other");
  const micros = config.filter(i => i.category === "micro");
  const microNutrients = [];

  for (let i = 0; i < macros.length; i++) {
    const n = props.store.nutritionFacts.find(n => macros[i] === n.name);
    if (isNotEmpty(n)) {
      macroNutrients.push(
        <MacroNutrient key={`macro-${i}`} nutrient={n} config={config} />
      );
    }
  }
  microNutrients.push(
    <tr key="cholesterol">
      <td><strong>Cholesterol</strong></td><td className="nutrient-fact">0mg</td>
    </tr>
  );
  for (let i = 0; i < others.length; i++) {
    const nutrient = props.store.nutritionFacts.find(n => others[i].name === n.name);
    if (isNotEmpty(nutrient) && Math.round(nutrient.amount) > 0) {
      microNutrients.push(
        <MicroNutrient key={`other-${i}`} nutrient={nutrient} config={config} />
      );
    } else {
      microNutrients.push(
        <tr key={`other-${i}`}>
          <td><strong>{others[i].friendlyName}</strong></td><td className="col nutrient-fact">0{others[i].unit}</td>
        </tr>
      );
    }
  }
  for (let i = 0; i < micros.length; i++) {
    const nutrient = props.store.nutritionFacts.find(n => micros[i].name === n.name);
    if (isNotEmpty(nutrient) && Math.round(nutrient.amount) > 0) {
      microNutrients.push(
        <MicroNutrient key={`micro-${i}`} nutrient={nutrient} config={config} />
      );
    } else {
      microNutrients.push(
        <tr key={`micro-${i}`}>
          <td className="col nutrient-name"><strong>{micros[i].friendlyName}</strong></td><td className="col nutrient-fact">0{micros[i].unit}</td>
        </tr>
      );
    }
  }

  return (
    <div className="modal fade" id="nutrition-facts-modal" tabIndex={-1} data-bs-backdrop="static">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Nutrition Info for {props.store.nutritionFactsFor}</h5>
            <button type="button" className="btn-close btn-close-white" onClick={props.hide}/>
          </div>
          <div className="modal-body">
            <div className="row macros">
              {macroNutrients}
            </div>

            <div className="row px-2">
              <div className="col-12 col-lg-6">
                <table className="table micros">
                  <thead>
                    <tr>
                      <th colSpan={2}>
                        <strong>{props.store.serving}</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {microNutrients}
                  </tbody>
                </table>
              </div>

              <div className="col-12 col-lg-6 align-items-center d-flex">
                {props.store.components.length > 0 ? (
                  <div className="mb-4 align-middle">
                    <strong>Serving Breakdown</strong>
                    <ul>
                      {props.store.components.map(c => (
                        <NutritionFactsRecipeComponent key={c.id} component={c}/>
                      ))}
                    </ul>
                    <div className="text-muted">
                      <small>Nutrition data comes from the USDA, we cannot guarantee the accuracy of this data. The totals listed here may be different from the actual totals in the meals you prepare.</small>
                    </div>
                  </div>
                ) : (
                  <div className="mb-4 align-middle">
                    <div className="text-muted">
                      <small>Nutrition data comes from the USDA, we cannot guarantee the accuracy of this data. The totals listed here may be different from the actual totals in the meals you prepare.</small>
                    </div>
                  </div>
                )}
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

NutritionFactsModal.propTypes = {
  store: PropTypes.object,
  hide: PropTypes.func,
}