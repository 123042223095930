import Helpers from "./Helpers";
import Constants from "./Constants";

export default function RecipeHelper(cache, member) {
  const {recipeTypes} = Constants();
  const { isEmpty, isNotEmpty } = Helpers();

  /**
   * Convert measurement value from a unit into another unit
   *
   * @param {number} measurementValue - measurement value to convert
   * @param {number} fromUnitId - measurement unit from which to convert
   * @param {number} toUnitId - measurement unit into which to convert
   * @return {number} converted measurement value
   */
  function convertValue(measurementValue, fromUnitId, toUnitId) {
    let conversionRate = cache.measurementUnitConversionRate(fromUnitId, toUnitId);
    return measurementValue * conversionRate;
  }

  /**
   * Scale measurement value from a unit to another unit depending on a threshold
   *
   * @param {Object} scaledMeasurement - measurement value and unit to scale
   * @param {string} fromUnit - measurement unit name from which to scale
   * @param {string} toUnit - measurement unit name into which to scale
   * @param {string} scaleDirection - < for a scale down, > for a scale up
   * @param {number} scaleThreshold - threshold to validate if scaling is applied
   * @return {Object} scaled measurement value and unit
   */
  function scaleValue(scaledMeasurement, fromUnit, toUnit, scaleDirection, scaleThreshold) {
    if (scaledMeasurement.unit === fromUnit) {
      if ((scaleDirection === "<" && scaledMeasurement.value < scaleThreshold) || (scaleDirection === ">" && scaledMeasurement.value > scaleThreshold)) {
        scaledMeasurement.value = convertValue(scaledMeasurement.value, cache.measurementUnitId(fromUnit), cache.measurementUnitId(toUnit));
        scaledMeasurement.unit = toUnit;
      }
    }
    return scaledMeasurement;
  }

  /**
   * Apply measurement unit rounding rules to measurement value
   *
   * @param {number} measurementValue - measurement value to round
   * @param {number[]} roundingValues - measurement unit rounding values
   * @return {number} rounded measurement value
   */
  function roundValue(measurementValue, roundingValues) {
    let result;
    // If rounding values are empty and measurement value is an integer, return measurement value as is
    if ((isEmpty(roundingValues) || roundingValues.length === 0) && Number.isInteger(measurementValue)) {
      result = parseInt(measurementValue);
    } else if ((isEmpty(roundingValues) || roundingValues.length === 0) && !Number.isInteger(measurementValue)) {
      // If rounding values are empty and measurement value is not integer, round to 1 digit after decimal point
      if (isNotEmpty(measurementValue)) {
        // TODO: investigate why we must add this check for desert in the meal planner
        result = measurementValue.toFixed(1);
      }
    } else if (roundingValues.length === 2 && roundingValues[0] === 0 && Number.isInteger(roundingValues[1])) {
      // If rounding values are 0 and another integer, round to nearest multiple of that integer
      // and if measurement value is below 10 round to nearest integer
      // and if it would return 0, return 1 instead
      const roundingValue = measurementValue < 10 ? 1 : roundingValues[1];
      result = Math.round(measurementValue/roundingValue) * roundingValue;
      if (result === 0) result = roundingValue;
    } else {
      // Else, apply rounding rules with rounding values received
      const measurementPart = measurementValue.toString().split(".");
      if (isNotEmpty(measurementPart[1])) {
        const floatMeasurement = parseFloat("0." + measurementPart[1]);
        let roundedFloatMeasurement = 0;
        let lowValue = 0;
        let highValue = 0;
        for (let i = 0; i < roundingValues.length; i++) {
          if (i === 0) {
            lowValue = roundingValues[i];
            highValue = (roundingValues[i]+roundingValues[i+1])/2;
          } else if (i === (roundingValues.length - 1)) {
            lowValue = (roundingValues[i-1]+roundingValues[i])/2;
            highValue = roundingValues[i];
          } else {
            lowValue = (roundingValues[i-1]+roundingValues[i])/2;
            highValue = (roundingValues[i]+roundingValues[i+1])/2;
          }
          if (floatMeasurement >= lowValue && floatMeasurement < highValue) {
            roundedFloatMeasurement = roundingValues[i];
            break;
          }
        }
        result = parseFloat(parseFloat(parseInt(measurementPart[0], 10) + roundedFloatMeasurement).toFixed(3));
      } else {
        result = parseInt(measurementValue); // The measurement value is an integer, we don't need to apply the rounding rules
      }
    }
    return result;
  }

  /**
   * Convert decimal part of measurement value to fraction character, after rounding has been applied
   *
   * @param {number} measurementValue - measurement value to fraction
   * @return {string} fractioned measurement value
   */
  function fractionValue(measurementValue) {
    let result;
    let fractionedValue = "";
    const measurementPart = measurementValue.toString().split(".");
    if (isNotEmpty(measurementPart[1])) {
      const floatMeasurement = parseFloat("0." + measurementPart[1]);
      switch (floatMeasurement) {
        case 0.125:
          fractionedValue = "⅛";
          break;
        case 0.25:
          fractionedValue = "¼";
          break;
        case 0.33:
          fractionedValue = "⅓";
          break;
        case 0.375:
          fractionedValue = "⅜";
          break;
        case 0.5:
          fractionedValue = "½";
          break;
        case 0.625:
          fractionedValue = "⅝";
          break;
        case 0.66:
          fractionedValue = "⅔";
          break;
        case 0.75:
          fractionedValue = "¾";
          break;
        case 0.875:
          fractionedValue = "⅞";
          break;
        default:
          fractionedValue = "";
      }
      result = measurementPart[0] === "0" ? fractionedValue : measurementPart[0] + fractionedValue;
    } else {
      result = measurementPart[0];
    }
    return result;
  }

  /**
   * Make a measurement look real good: change it, scale it, round it, fraction it, filter it, pluralize it 🎶
   *
   * @param {number} measurementValue - measurement value
   * @param {number} measurementUnitId - measurement unit id
   * @param {string} preferredSystem - preferred measurement system
   * @param {string} measuredItem - name of the ingredient or the recipe for this measurement
   * @return {string} good-looking measurement
   */
  function makeMeasurementLookGood(measurementValue, measurementUnitId, preferredSystem, measuredItem) {
    measuredItem = isEmpty(measuredItem) ? "" : measuredItem;

    // *** change it ***
    // -----------------
    // Change measurement value and unit system according to preferred system
    let changedValue = measurementValue;
    let changedUnitId = measurementUnitId;
    let currentSystem = cache.measurementUnitSystem(measurementUnitId);
    if (isNotEmpty(currentSystem) && isNotEmpty(preferredSystem) && currentSystem !== preferredSystem) {
      // Get unit to convert to in the preferred system
      changedUnitId = cache.changedMeasurementUnitId(measurementUnitId, preferredSystem);
      // Convert measurement value into new unit
      changedValue = convertValue(changedValue, measurementUnitId, changedUnitId);
    }
    // *** scale it ***
    // ------------------
    // Scale checks are made one after the other in a specific order in case more than one is needed
    let scaledMeasurement = {value: changedValue, unit: cache.measurementUnitName(changedUnitId)};
    scaledMeasurement = scaleValue(scaledMeasurement, "cup", "tablespoon", "<", 0.23); // Convert cups down to tablespoons when we are below 1/4 cup
    scaledMeasurement = scaleValue(scaledMeasurement, "tablespoon", "teaspoon", "<", 0.815); // Convert tablespoons down to teaspoons when we reach 2/3 tablespoon
    scaledMeasurement = scaleValue(scaledMeasurement, "teaspoon", "pinch", "<", 0.153); // Convert teaspoons down to pinches when we are below 1/8 teaspoon
    scaledMeasurement = scaleValue(scaledMeasurement, "pinch", "teaspoon", ">", 1.444); // Convert pinches up to teaspoons when we reach 2 pinches
    scaledMeasurement = scaleValue(scaledMeasurement, "teaspoon", "tablespoon", ">", 2.874); // Convert teaspoons up to tablespoons when we are above 2 3/4 teaspoons
    scaledMeasurement = scaleValue(scaledMeasurement, "tablespoon", "cup", ">", 3.824); // Convert tablespoons up to cups when we are above 3 2/3 tablespoons
    scaledMeasurement = scaleValue(scaledMeasurement, "lb", "oz", "<", 0.945); // Convert lb down to oz when we are below 1 lb
    scaledMeasurement = scaleValue(scaledMeasurement, "oz", "lb", ">", 15.112); // Convert oz up to lb when we reach 16 oz
    scaledMeasurement = scaleValue(scaledMeasurement, "l", "ml", "<", 0.950); // Convert l down to ml when we are below 1 l
    scaledMeasurement = scaleValue(scaledMeasurement, "ml", "l", ">", 949); // Convert ml up to l when we reach 1000 ml
    scaledMeasurement = scaleValue(scaledMeasurement, "kg", "g", "<", 0.950); // Convert kg down to g when we are below 1 kg
    scaledMeasurement = scaleValue(scaledMeasurement, "g", "kg", ">", 949); // Convert g up to kg when we reach 1000 g

    // *** round it ***
    // ----------------
    const scaledUnitId = cache.measurementUnitId(scaledMeasurement.unit);
    const roundingValues = cache.measurementUnitRoundingValues(scaledUnitId);
    const roundedValue = roundValue(scaledMeasurement.value, roundingValues);

    // *** fraction it ***
    // -------------------
    let fractionedValue = roundedValue;
    if (scaledMeasurement.unit === "cup" || scaledMeasurement.unit === "tablespoon" || scaledMeasurement.unit === "teaspoon") {
      fractionedValue = fractionValue(roundedValue);
    }

    // *** pluralize it ***
    // --------------------
    let pluralizedUnit = " " + scaledMeasurement.unit;
    if (roundedValue > 1) {
      pluralizedUnit =  " " + cache.pluralizedUnit(scaledUnitId);
    }

    // *** filter it ***
    // -----------------
    if (scaledMeasurement.unit.length > 2 && measuredItem.toLowerCase().indexOf(scaledMeasurement.unit.toLowerCase()) !== -1) {
      pluralizedUnit = "";
    }

    // *** return that good-looking measurement ***
    return fractionedValue + pluralizedUnit;
  }

  function recipeYield(recipe) {
    let result = "";
    if (recipe.type !== recipeTypes.ASSEMBLY) {
      if (isNotEmpty(recipe.scaledYieldVolume) && isNotEmpty(recipe.yieldUnitId)) {
        result = `${makeMeasurementLookGood(recipe.scaledYieldVolume, recipe.yieldUnitId, member.preferences.volumeSystem, "")}`;
      }
    }
    return result;
  }

  function recipeUsedYield(recipe) {
    let result = "";
    if (isNotEmpty(recipe.usedVolume)) {
      result = `${makeMeasurementLookGood(recipe.usedVolume, recipe.yieldUnitId, member.preferences.volumeSystem, "")}`;
    }
    return result;
  }

  function appearanceYield(recipe, ratio) {
    let result = ""
    if (recipe.hasOwnProperty("yieldVolume")) {
      result = `${makeMeasurementLookGood(recipe.yieldVolume*ratio, recipe.yieldUnitId, member.preferences.volumeSystem, "")}`;
    }
    return result;
  }

  function recipeLeftover(recipe) {
    let result = "";
    if (recipe.type === "Component" && isNotEmpty(recipe.yieldVolumeLeftover) && recipe.yieldVolumeLeftover > 0) {
      result = `${makeMeasurementLookGood(recipe.yieldVolumeLeftover, recipe.yieldUnitId, member.preferences.volumeSystem, "")}`;
    }
    return result;
  }

  function yieldUnitName(measurementUnitId, preferredSystem) {
    // *** change it ***
    // -----------------
    // Change measurement value and unit system according to preferred system
    let changedUnitId = measurementUnitId;
    let currentSystem = cache.measurementUnitSystem(measurementUnitId);
    if (isNotEmpty(currentSystem) && isNotEmpty(preferredSystem) && currentSystem !== preferredSystem) {
      // Get unit to convert to in the preferred system
      changedUnitId = cache.changedMeasurementUnitId(measurementUnitId, preferredSystem);
    }
    return cache.measurementUnitName(changedUnitId);
  }

  const rh = {
    appearanceYield: appearanceYield,
    convertValue: convertValue,
    makeMeasurementLookGood: makeMeasurementLookGood,
    yieldUnitName: yieldUnitName,
    recipeLeftover: recipeLeftover,
    recipeUsedYield: recipeUsedYield,
    recipeYield: recipeYield,
  }

  return { rh };
}