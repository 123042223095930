import React from "react";
import PropTypes from "prop-types";

export default function AddSomething(props) {

  return (
    <button className="btn btn-light" type="button" onClick={props.action}>
      <span className="fa fa-plus" /> {props.text}
    </button>    
  );

}

AddSomething.propTypes = {
  text: PropTypes.string,
  action: PropTypes.func,
}