import "./Checkcircle.scss";
import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

export default function Checkcircle(props) {
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setSaving(false);
  }, [props.checked]);

  function toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    setSaving(true);
    props.toggle(!props.checked);
  }

  let result;
  if (saving) {
    result = <i className="fas fa-check-circle check-light d-print-none"/>
  } else {
    if (props.checked) {
      result = <i className="cursor-pointer fas fa-check-circle checkcircle text-muted complete d-print-none" onClick={toggle} />
    } else {
      result = <i className="cursor-pointer far fa-check-circle checkcircle d-print-none" onClick={toggle} />
    }
  }
  return result;
}

Checkcircle.propTypes = {
  checked: PropTypes.bool,
  toggle: PropTypes.func,
}
