import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import { Link, useHistory } from "react-router-dom";
import Constants from "../../../services/Constants";

export default function MagicMealPlanPreview(props) {
  let history = useHistory();
  const { assetUrl, isNotEmpty, makeUrlLookGood } = Helpers();
  const { MP_NAME_CAP: mpNameCap, MP_DESCRIPTION_CAP: mpDescriptionCap } = Constants();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [storageId, setStorageId] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setName(props.mp.name);
    setDescription(props.mp.description);
    setStorageId(props.mp.storageId);
  }, [props.mp]);

  function handleSubmit(e) {
    e.preventDefault();
    setWorking(true);
    props.mmpStore.update({ id: props.mp.id, name: name, description: description, storageId: storageId }, () => {
      setWorking(false);
      setShowForm(false);
    });
  }

  const planUrl = `/meal-plans/meal-planner/${makeUrlLookGood(props.mp.id, props.mp.name)}`;

  const cancelButton = working ? (
    <button type="button" className="btn btn-light ms-2 mb-2 me-2" disabled={true}>
      Cancel
    </button>
  ) : (
    <button
      type="button"
      className="btn btn-light ms-2 mb-2 me-2"
      onClick={() => {
        setShowForm(false);
        setName(props.mp.name);
      }}
    >
      Cancel
    </button>
  );

  const submitButton = working ? (
    <button type="button" className="btn btn-primary ms-2" disabled={true}>
      <span className="spinner-border spinner-border-sm ms-2 mb-2" />
      <span className="ms-1">Saving...</span>
    </button>
  ) : (
    <button type="submit" className="btn btn-secondary mb-2">
      Save Changes
    </button>
  );

  const images = props.mmpStore.state.availableImages.map((i) => (
    <li key={i.id} className="dropdown-item" onClick={() => setStorageId(i.id)}>
      <img src={assetUrl(i.storageKey)} width="70px" alt={i.name} />
    </li>
  ));

  function selectedImage() {
    let result = null;
    if (isNotEmpty(storageId)) {
      const image = props.mmpStore.state.availableImages.find((i) => i.id === storageId);
      result = <img src={assetUrl(image.storageKey)} width="100px" alt={image.name} />;
    }
    return result;
  }

  return showForm ? (
    <div className="tile col mb-4">
      <div className="card h-100 p-2 mp-saved text-center">
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Meal Plan Name</label>
              <input
                type="text"
                autoComplete="off"
                className="form-control"
                maxLength={mpNameCap}
                value={name}
                placeholder="Name your Meal Plan..."
                required={true}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <div className="d-flex flex-row justify-content-center bg-light p-2 py-3 rounded text-center">
                <div>
                  {selectedImage()}
                  <div className="dropdown mt-3" id="weekly-plan-select">
                    <button className="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
                      Select Image
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      {images}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="mb-3">
              <label className="form-label">Description</label>
              <textarea className="form-control" maxLength={mpDescriptionCap} value={description} onChange={(e) => setDescription(e.target.value)} />
            </div>
            {cancelButton}
            {submitButton}
          </form>
        </div>
      </div>
    </div>
  ) : (
    <div className="tile col mb-4">
      <div className="card h-100 text-center brighten border-0 shadow-sm tile-magic bg-white">
        <div className="image-wrap">
          <Link to={planUrl} className="card-link">
            {isNotEmpty(props.mp.photo) ? (
              <img src={assetUrl(props.mp.photo)} className="card-img-top" alt={props.mp.name} />
            ) : (
              <img src={`${process.env.PUBLIC_URL}/images/recipe-placeholder-picture.png`} className="card-img-top rounded-top" alt={props.mp.name} />
            )}
          </Link>
        </div>
        <div className="card-body p-2 bg-white rounded-bottom align-middle">
          <h2 className="card-title-1 py-1">
            <Link to={planUrl} className="card-link">
              {props.mp.name}
            </Link>
          </h2>
          <button type="button" className="btn btn-stacked" onClick={props.showDeleteModal}>
            <p className="fa-action fa-regular fa-circle-minus">
              <br />
              <span className="fa-action-text">Delete</span>
            </p>
          </button>
          <button className="btn btn-stacked" type="button" onClick={() => setShowForm(true)}>
            <p className="fa-action far fa-square-pen">
              <br />
              <span className="fa-action-text">Edit</span>
            </p>
          </button>
          <button className="btn btn-stacked" type="button" onClick={() => history.push(planUrl)}>
            <p className="fa-action far fa-chevron-circle-right">
              <br />
              <span className="fa-action-text">View</span>
            </p>
          </button>
        </div>
      </div>
    </div>
  );
}

MagicMealPlanPreview.propTypes = {
  mmpStore: PropTypes.object,
  mp: PropTypes.object,
  showDeleteModal: PropTypes.func,
};
