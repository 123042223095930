import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import Constants from "../../../services/Constants";

export default function RecipeTiming(props) {
	const {contexts} = Constants();
	const {isNotEmpty, makeMinutesLookGood} = Helpers();
	
	const prepTime = isNotEmpty(props.prepTime) ? (
		<div className="col-auto pe-0">Active: {makeMinutesLookGood(props.prepTime)} </div>
	) : null;
	
	const readyIn = isNotEmpty(props.readyIn) ? (
		<div className="col text-nowrap">Ready in: {makeMinutesLookGood(props.readyIn)}</div>
	) : null;

	function containerStyle() {
		let result = "";
		if (props.context === contexts.RECIPE) {
			result = "row text-muted my-2 small";
		} else if (props.context === contexts.SEARCH_RESULT) {
			result = "row text-muted";
		} else if (props.context === contexts.DOCUMENT) {
      result = "row text-muted";
    }
		return result;
	}

	return isNotEmpty(prepTime) || isNotEmpty(readyIn) ? (
		<div className={containerStyle()} style={props.style}>
			{prepTime}
			{readyIn}
		  </div>
	) : null;
	
}

RecipeTiming.propTypes = {
  context: PropTypes.string,
  style: PropTypes.object,
  prepTime: PropTypes.number,
	readyIn: PropTypes.number,

}