import React, { useEffect } from "react";
import Reviews from "./Reviews";
import Constants from "../../services/Constants";

export default function ReviewsModal({ authenticated, cache, store, hide }) {
  const { documentTypes } = Constants();

  useEffect(()=>{
    window.addEventListener("popstate", hide)
    return ()=>{
      window.removeEventListener("popstate",hide);
    }
  })

  return (
    <div className="modal fade" id="reviews-modal" tabIndex={-1}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title lh-sm">
              <span className="document-type">{store.documentData.documentType === documentTypes.WIZARD_PLAN ? "Wizard plan reviews" : "Recipe reviews"}</span>
              <br />
              <span>{store.documentData.reviewsFor}</span>
            </h5>
            <button type="button" className="btn-close btn-close-white" onClick={hide} />
          </div>
          <div className="modal-body p-3">
            {store.working ? (
              <div className="text-center">
                <span className="spinner-border" />
              </div>
            ) : (
              <Reviews authenticated={authenticated} cache={cache} store={store} hide={hide} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
