import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Constants from "../Constants";
import Helpers from "../Helpers";

export default function useAuth() {
  const { authMessages } = Constants();
  const { handleError, isNotEmpty } = Helpers();
  const [token, setToken] = useState(localStorage.getItem("pfl-token"));
  const [authMessage, setAuthMessage] = useState("");
  const [utmParams, setUtmParams] = useState({ origin: null, campaign: null, source: null, medium: null, content: null, term: null });
  const [gift, setGift] = useState(null);
  const [giftResolved, setGiftResolved] = useState(false);
  const authenticated = token !== null;

  useEffect(() => {
    if (isNotEmpty(gift)) {
      setGiftResolved(true);
    }
  }, [gift]);

  function resetAuthMessage() {
    setAuthMessage("");
  }

  function signIn(data) {
    // reset auth message before attempting sign in
    setAuthMessage("");
    // sign in
    const base64StringOfUserColonPassword = btoa(`${data.email}:${data.password}`);
    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/auth/sign-in`,
      headers: {
        Authorization: "Basic " + base64StringOfUserColonPassword,
      },
    }).then(res => {
      localStorage.setItem("pfl-token", res.data.token);
      setToken(res.data.token);
    }).catch(err => {
      if ([401, 404].indexOf(err.request.status) !== -1) {
        setAuthMessage(authMessages.AUTHENTICATION_FAILED);
      } else {
        setAuthMessage(authMessages.SERVER_ERROR);
      }
    });
  }

  function signUp(data) {
    // reset auth message before attempting sign in
    setAuthMessage("");
    // add utm params
    data["utmParams"] = utmParams;
    // sign up
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/auth/sign-up`,
      data: data,
    }).then(res => {
      localStorage.setItem("pfl-token", res.data.token);
      setToken(res.data.token);
    }).catch(err => {
      if (err.request.status === 409) {
        setAuthMessage(authMessages.EMAIL_ALREADY_USED);
      } else {
        setAuthMessage(authMessages.SERVER_ERROR);
      }
    });
  }

  function startTrial(data) {
    // reset auth message before attempting sign in
    setAuthMessage("");
    // add utm params
    data["utmParams"] = utmParams;
    // start trial
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/auth/start-trial`,
      data: data,
    }).then(res => {
      localStorage.setItem("pfl-token", res.data.token);
      setToken(res.data.token);
    }).catch(err => {
      if (err.request.status === 409) {
        setAuthMessage(authMessages.EMAIL_ALREADY_USED);
      } else if (err.request.status === 401) {
        setAuthMessage(authMessages.AUTHENTICATION_FAILED);
      } else {
        setAuthMessage(authMessages.SERVER_ERROR);
      }
    });
  }

  const signOut = useCallback(() => {
    localStorage.removeItem("pfl-token");
    setToken(null);
    window.location.href = "/";
  }, []);

  function createPasswordReset(data, cb) {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/auth/password_resets`,
      data: data.email,
    }).then(() => {
      if (cb) cb();
    }).catch(() => {
      if (cb) cb();
    });
  }

  function getGift(redeemToken) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/auth/gifts/${redeemToken}`,
    }).then(res => {
      if (isNotEmpty(res.data)) {
        setGift(res.data);
      }
    }).catch(err => {
      handleError(err);
    });
  }

  function redeemGift(redeemToken, cb) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/auth/gifts/${redeemToken}/redeem`,
    }).then(() => {
      // success
      if (cb) cb(true);
    }).catch(() => {
      // error
      if (cb) cb(false);
    });
  }

  function resetPassword(data, cb) {
    axios({
      method: "put",
      url: `${process.env.REACT_APP_API}/auth/password_resets/${data.digest}`,
      data: data,
    }).then(res => {
      if (res.status === 200) {
        localStorage.setItem("pfl-token", res.data.token);
        setToken(res.data.token);
      }
    }).catch(err => {
      if (err.request.status === 401) {
        alert("Oops!\nThe link you clicked on expired faster than an avocado!\nLet’s get you to the reset password page,\nwhere you can initiate a new link\nand get back to your plant fueled life.");
        if (cb) cb();
        window.location.href = "/forgot-password";
      }
    });
  }

  function resetGift() {
    setGift(null);
    setGiftResolved(false);
  }

  const giftStore = {
    gift: gift,
    giftResolved: giftResolved,
    getGift: getGift,
    resetGift: resetGift,
    redeemGift: redeemGift,
  }

  return { token, authenticated, authMessage, giftStore, signIn, signOut, signUp, startTrial, createPasswordReset, setAuthMessage, resetAuthMessage, resetPassword, setUtmParams };
}
