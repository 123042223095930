import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Collapse } from "bootstrap";
import Purchase from "./Purchase";

export default function PurchaseHistory(props) {
  const [show, setShow] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    const element = document.getElementById("purchase-history");
    const collapse = new Collapse(element, { toggle: false });
    show ? collapse.show() : collapse.hide();
    if (show) {
      setWorking(true);
      props.memberStore.getPurchases(() => {
        setWorking(false);
      })
    }
  }, [show]);

  const warning = props.memberStore.purchases.length === 50 ? (
    <small>Only the latest 50 purchases are shown.</small>
  ) : null;

  const purchases = props.memberStore.purchases.filter(p => p.total_price > 0).map(p => (
    <Purchase key={p.invoiceId} purchase={p} />
  ));

  const table = working ? <span className="spinner-border"/> : (
    <div className="table-responsive">
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col" className="border-0 text-dark">Date</th>
            <th scope="col" className="border-0 text-dark">Total</th>
            <th scope="col" className="border-0 text-dark">Status</th>
            <th scope="col" className="border-0 text-dark">Item</th>
            <th scope="col" className="border-0 text-dark">Invoice</th>
          </tr>
        </thead>
        <tbody>{purchases}</tbody>
      </table>
      {warning}
    </div>
  );

  return (
    <div className="accordion-item">
      <div className="accordion-header">
        <button
          className={show ? "accordion-button" : "accordion-button collapsed"}
          type="button"
          onClick={() => setShow(!show)}
        >
          <span>Purchase History</span>
        </button>
      </div>
      <div id="purchase-history" className="accordion-collapse collapse">
        <div className="accordion-body">
          {table}
        </div>
      </div>
    </div>
  );
}

PurchaseHistory.propTypes = {
  memberStore: PropTypes.object,
};
