import PropTypes from "prop-types";
import CreditCard from "./CreditCard";

/**
 * Renders a list of CreditCard components sorted such that the default card (status is 3),
 * if any, appears first. The sorting is based on the status of each card.
 *
 * @param {Object[]} creditCards - Array of credit card objects to be displayed.
 * @param {boolean} changingDefaultCard - flag to indicate a change in the default card is in progress.
 * @param {Function} setDefaultCreditCard - Function to set a card as the default.
 *
 * @returns {JSX.Element[]} An array of CreditCard components, with the default card (if present)
 *                          positioned first, based on its status.
 */
export default function CreditCards({ creditCards, changingDefaultCard, setDefaultCreditCard }) {
  return [...creditCards]
    .sort((a, b) => (a.status % 3) - (b.status % 3)) //this is positioning the default credit card first
    .map((cc) => <CreditCard key={cc.id} card={cc} changingDefaultCard={changingDefaultCard} makeDefault={setDefaultCreditCard} />);
}

CreditCards.propTypes = {
  creditCards: PropTypes.array,
  changingDefaultCard: PropTypes.bool,
  setDefaultCreditCard: PropTypes.func,
};
