import React, {useState} from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import IngredientAppearance from "./IngredientAppearance";
import Popover from "../../components/Popover";
import GroceryItem from "../../components/GroceryItem";


export default function GroceryListIngredient(props) {
  const [show, setShow] = useState(false);
  const {isNotEmpty} = Helpers();

  function renderSubNoteWarning() {
    let result = "";
    let text = "";
    if (isNotEmpty(props.ingredient.subNote)) {
      text = props.ingredient.subNote;
    }
    if (text !== "") {
      result = (
        <>
          <small className="text-muted ml-0 d-block d-md-inline">
          <em>&nbsp;{text}</em>
          </small>
        </>
      );
    }
    return result;
  }

  function render() {
    const appearances = props.ingredient.appearances.map((a, index) => (
      <IngredientAppearance
        key={index}
        appearance={a}
        showAppearancesSubNotes={props.ingredient.showAppearancesSubNotes}
      />
    ));
    const renderPopover =
      isNotEmpty(props.ingredient.photo) ||
      isNotEmpty(props.ingredient.notesAndTips) ? (
        <Popover
          title={props.ingredient.name}
          photo={props.ingredient.photo}
          content={props.ingredient.notesAndTips}
        />
      ) : null;

    const element = (
      <div className="row">
        <div className="col-11 pe-0">
          <span>{props.ingredient.name} - {props.ingredient.total}</span>&nbsp;{renderPopover}{renderSubNoteWarning()}
        </div>
        <div className="col-1 ps-0">
          <button
            className={show ? "accordion-button" : "accordion-button collapsed"}
            type="button"
            onClick={() => setShow(!show)}
          />
        </div>
        <div className="row">
          <div className="col-12">
            <div className={show ? "" : "collapse"} id={`ingredient-${props.ingredient.id}`}>
              <ul>
                {appearances}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <GroceryItem
        item={props.ingredient}
        checked={props.checked}
        element={element}
        custom={false}
        toggle={() => props.updateIngredient(props.ingredient.id, !props.checked)}
      />
    );
  }
  return render();
}

GroceryListIngredient.propTypes = {
  ingredient: PropTypes.object,
  checked: PropTypes.bool,
  updateIngredient: PropTypes.func,
}