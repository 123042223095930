import "./Playlist.css";
import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import usePlaylist from "../../../services/stores/PlaylistStore";
import Video from "./components/Video";
import Helpers from "../../../services/Helpers";
import MarkdownViewer from "../../components/MarkdownViewer";
import Footer from "../../components/Footer";
import {useHistory, useLocation} from "react-router-dom";

export default function Playlist(props) {
  const location = useLocation();
  const history = useHistory();
  const {isNotEmpty, pageTitle} = Helpers();
  const [activeVideoId, setActiveVideoId] = useState(null);
  const [downloads, setDownloads] = useState([]);
  const {assetUrl} = Helpers();
  const {playlist} = usePlaylist(props.token, props.id);

  useEffect(() => {
    document.title = pageTitle(location.pathname);
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get("id");
    if (isNotEmpty(id)) {
      setActiveVideoId(+id);
    }
  }, [location]);

  useEffect(() => {
    if (playlist.videos.length > 0) {
      if (isNotEmpty(activeVideoId)) {
        scrollToVideo(activeVideoId);
      } else {
        setActiveVideoId(playlist.videos[0].id);
      }
    }
  }, [playlist.videos]);

  useEffect(() => {
    if (isNotEmpty(activeVideoId)) {
      const searchParams = new URLSearchParams(location.search);
      const id = searchParams.get("id");
      if (isNotEmpty(id)) {
        // Only scroll if we have a query param
        scrollToVideo(activeVideoId);
      }
    }
  }, [activeVideoId]);

  function scrollToVideo(id) {
    const element = document.getElementById(`video-${id}`);
    if (isNotEmpty(element)) {
      setTimeout(() => {
        element.scrollIntoView();
      }, 400);
    }
  }

  function className() {
    let result = "container-lg";
    if (playlist.cssClass) {
      result = `container-lg ${playlist.cssClass}`;
    }
    return result;
  }

  const videos = playlist.videos.map(v => (
    <Video
      key={v.id}
      token={props.token}
      id={v.id}
      title={v.title}
      active={activeVideoId === v.id}
      setActive={() => history.push(`${location.pathname}?id=${v.id}`)}
      updateDownloads={setDownloads}
    />
  ));

  function renderDownloads() {
    let result = null;
    if (downloads.length > 0) {
      const downloadLinks = downloads.map(d => (
        <li key={d.id} className="list-group-item">
          <a href={assetUrl(d.storageKey)}>{d.name} <i className="fas fa-file-download"/></a>
        </li>
      ));
      result = (
        <ul className="list-group list-group-flush playlist-download mt-4 border">
          <li className="list-group-item green text-white">Downloads</li>
          {downloadLinks}
        </ul>
      );
    }
    return result;
  }

  return (
    <>
      <main id="playlist" className={className()}>
        {props.banner}
        <h1 className="text-center page-title mb-3">{playlist.title}</h1>
        <div className="row">
          <div className="col-md-3 mb-4 mb-md-1">
            <ul className="list-group list-group-flush border-0 rounded-top rounded-bottom">
              <li className="list-group-item purple text-white">What you'll learn</li>
              <li className="list-group-item">
                <MarkdownViewer markdown={playlist.text}/>
              </li>
            </ul>

            {renderDownloads()}

          </div>

          <div className="col-md-9">
            <div className="accordion rounded-top rounded-bottom">
              {videos}
            </div>
          </div>
        </div>
      </main>
      <Footer/>
    </>
  );
}

Playlist.propTypes = {
  token: PropTypes.string,
  id: PropTypes.number,
  banner: PropTypes.element,
}