import React from "react";
import Footer from "../components/Footer";

export default function Error() {

  return (
    <>
      <main id="not-found" className="container-lg">
        <h1>Something went wonky.</h1>
        <p>Try <a href="/src/cleanfooddirtygirl/cfdg-classroom/src/client/public" className="text-nowrap" onClick={() => window.location.reload()}>reloading your membership portal</a>.</p>
        <p>If you still have a problem, contact our <a href="mailto:support@cleanfooddirtygirl.com">support</a>.
        </p>
      </main>
      <Footer />
    </>
  );
}