import "./Account.scss";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Redirect, useLocation } from "react-router-dom";
import { Modal } from "bootstrap";
import { DateTime } from "luxon";
import Helpers from "../../services/Helpers";
import ProfileForm from "../../views/Member/components/ProfileForm";
import PasswordForm from "../../views/Member/components/PasswordForm";
import Subscription from "./components/Subscription";
import Preferences from "../../views/Member/components/Preferences";
import PurchaseHistory from "../../views/Member/components/PurchaseHistory";
import Footer from "../../views/components/Footer";
import BillingInformationAccordion from "../../views/Member/components/BillingInformationAccordion";

export default function Account(props) {
  const location = useLocation();
  const { formatDateInternational, isNotEmpty, pageTitle, profilePhoto } = Helpers();
  const [working, setWorking] = useState(false);

  useEffect(() => {
    return () => {
      // cleanup modal stuff
      const backdrop = document.querySelector(".modal-backdrop");
      if (isNotEmpty(backdrop)) {
        backdrop.remove();
      }
      document.body.classList.remove("modal-open");
      document.body.removeAttribute("style");
    };
  }, []);

  useEffect(() => {
    document.title = pageTitle(location.pathname);
  }, [location]);

  function showPictureModal() {
    const element = document.getElementById("picture-modal");
    const pictureModal = Modal.getOrCreateInstance(element);
    pictureModal.show();
  }

  function hidePictureModal() {
    const element = document.getElementById("picture-modal");
    const pictureModal = Modal.getOrCreateInstance(element);
    pictureModal.hide();
    const pictureInput = document.getElementById("picture-input");
    pictureInput.value = "";
  }

  const closePictureModalButton = working ? (
    <button type="button" className="btn-close" disabled={true} />
  ) : (
    <button type="button" className="btn-close " onClick={hidePictureModal} />
  );

  const pictureUploadButton = working ? (
    <button className="btn btn-primary" type="button" disabled={true}>
      Upload
    </button>
  ) : (
    <button
      className="btn btn-primary"
      type="button"
      onClick={() => {
        setWorking(true);
        const files = document.getElementById("picture-input").files;
        props.memberStore.updatePicture(files, () => {
          setWorking(false);
          hidePictureModal();
        });
      }}
    >
      Upload
    </button>
  );

  function memberSince() {
    let result = null;
    if (props.memberStore.member.hasMP && (!props.memberStore.member.hasTrial || props.memberStore.member.hadMP)) {
      if (isNotEmpty(props.memberStore.member.since)) {
        const since = formatDateInternational(props.memberStore.member.since);
        if (since < "2019-11-01") {
          result = (
            <div className="member-name">
              <h2>
                <img src={`${process.env.PUBLIC_URL}/images/og-dirty.svg`} width="50" alt="OG Dirty Badge" />
              </h2>
              <p>Thank you for being an OG Dirty!</p>
            </div>
          );
        } else {
          result = (
            <div className="member-name">
              <p>
                Plant Fueled Life Member since
                <br />
                {DateTime.fromSeconds(props.memberStore.member.since).toLocaleString()}
              </p>
            </div>
          );
        }
      }
    }
    return result;
  }

  return props.authenticated ? (
    <>
      <main id="account" className="container-lg">
        <h1 className="text-center page-title mb-3">
          {" "}
          Hello, {props.memberStore.member.firstname} {props.memberStore.member.lastname}
        </h1>

        {props.memberStore.member.hasMP ? null : (
          <div className="text-center align-items-center d-sm-none d-md-block">
            <p className="text-muted px-4 px-md-2">We can't wait to help you get that plant glow!</p>
          </div>
        )}

        <div className="row mb-3 px-2">
          <div className="col-12 col-sm-4 col-md-auto ps-0 mb-3 mb-md-0 mt-sm-3 justify-content-center">
            <div className="profile-image mx-auto text-center w-100">
              <img src={profilePhoto(props.memberStore.member)} className="img-fluid circle rounded" alt="" />
              <button className="btn btn-primary shadow" type="button" onClick={showPictureModal}>
                <span className="fas fa-pencil-alt" />
              </button>
            </div>
          </div>
          <div className="col-12 col-sm-8 col-md-4 me-md-auto text-center text-md-start mt-sm-3">{memberSince()}</div>
          <div className="col-12 col-md-5 align-end mt-sm-3 subscription-box">
            <Subscription memberStore={props.memberStore} subStore={props.subStore} />
          </div>
        </div>

        <div className="row">
          <div className="accordion mb-3">
            {props.memberStore.member.hasMP || props.memberStore.member.hasRV ? <Preferences cache={props.cache} memberStore={props.memberStore} /> : null}
            <ProfileForm message={props.message} member={props.memberStore.member} resetMessage={props.resetMessage} update={props.memberStore.updateMember} />
            <PasswordForm member={props.memberStore.member} changePassword={props.memberStore.changePassword} />
            <BillingInformationAccordion memberStore={props.memberStore} subStore={props.subStore} />
            <PurchaseHistory memberStore={props.memberStore} />
          </div>
        </div>

        <div className="modal fade" id="picture-modal" tabIndex={-1} data-bs-backdrop="static">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Upload a picture</h5>
                {closePictureModalButton}
              </div>
              <div className="modal-body">
                <div className="input-group mb-3">
                  <input type="file" className="form-control" id="picture-input" />
                  {pictureUploadButton}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  ) : (
    <Redirect to="/sign-in" />
  );
}

Account.propTypes = {
  authenticated: PropTypes.bool,
  message: PropTypes.string,
  cache: PropTypes.object,
  memberStore: PropTypes.object,
  subStore: PropTypes.object,
  resetMessage: PropTypes.func,
};
