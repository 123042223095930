import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../services/Helpers";

export default function ReviewStars(props) {
  const {isEmpty} = Helpers();

  function render() {
    const stars = [];
    const starCount = props.stars ? props.stars : 0;
    for (let i = 1; i < 6; i++) {
      const className = starCount >= i ? "fas fa-star" : "far fa-star";
      stars.push(<i key={i} className={className} />);
    }
    return isEmpty(props.cta) ? <div className="ratings">{stars}</div> : <div className="ratings cursor-pointer" onClick={props.cta}>{stars}</div>;
  }

  return render();
}

ReviewStars.propTypes = {
  stars: PropTypes.number,
  cta: PropTypes.func,
}