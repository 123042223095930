import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Recipe from "./components/Recipe";
import Helpers from "../../services/Helpers";
import Constants from "../../services/Constants";
import PublicRecipe from "./components/PublicRecipe";
import StandaloneRecipeContainer from "./components/StandaloneRecipeContainer";

export default function StandaloneRecipe(props) {
  let { id } = useParams();
  const { contexts, documentTypes } = Constants();
  const { isNotEmpty } = Helpers();

  useEffect(() => {
    if (isNotEmpty(props.recipeStore.state.standaloneRecipe.recipeId)) {
      const recipe = props.recipeStore.state.standaloneRecipe.recipeData.masters.find((r) => r.id === props.recipeStore.state.standaloneRecipe.recipeId);
      document.title = `${recipe.name} - Plant Fueled Life`;
    }
  }, [props.recipeStore.state.standaloneRecipe.recipeId]);

  useEffect(() => {
    if (isNotEmpty(props.recipeStore.state.publicData.id)) {
      props.reviewStore.setDocumentData({
        documentId: `${props.recipeStore.state.publicData.id}`,
        documentType: documentTypes.RECIPE,
        reviewsFor: `${props.recipeStore.state.publicData.name}`,
      });
    }
  }, [props.recipeStore.state.publicData.id]);

  useEffect(() => {
    if (isNotEmpty(id) && props.cacheReady) {
      props.recipeStore.get(id);
    }
  }, [id, props.cacheReady]);

  return (
    <StandaloneRecipeContainer banner={props.authenticated ? props.banner : null} recipeStore={props.recipeStore}>
      {props.authenticated ? (
        <Recipe
          token={props.token}
          authenticated={props.authenticated}
          member={props.memberStore.member}
          stashStore={props.stashStore}
          context={contexts.STANDALONE_RECIPE}
          recipeId={props.recipeStore.state.standaloneRecipe.recipeId}
          recipes={props.recipeStore.state.standaloneRecipe.recipeData.views}
          rawRecipes={props.recipeStore.state.standaloneRecipe.recipeData.recipes}
          childRecipeIds={props.recipeStore.state.standaloneRecipe.childRecipeIds}
          deletedChildRecipes={props.recipeStore.state.customizations.deletedChildRecipes}
          reviewStore={props.reviewStore}
          noteStore={props.noteStore}
          mmpStore={props.mmpStore}
          modals={props.modals}
          canShowNutritionFacts={props.memberStore.member.preferences.showNutritionFacts}
          showRelatedContent={true}
          updatingCheckboxes={props.recipeStore.state.updatingCheckboxes}
          changePortions={props.recipeStore.changePortions}
          changeCookingMethod={props.recipeStore.changeCookingMethod}
          updateCheckboxes={props.recipeStore.updateCheckboxes}
          deleteChildRecipe={props.recipeStore.deleteChildRecipe}
          restoreChildRecipes={props.recipeStore.restoreChildRecipes}
          updateChildRecipeAdditionalPortions={props.recipeStore.updateChildRecipeAdditionalPortions}
          prepareDataForNutritionFacts={props.recipeStore.prepareDataForNutritionFacts}
          specialScalingIds={props.recipeStore.specialScalingIds}
          recordDownload={props.memberStore.recordDownload}
          deleteCustomizations={props.recipeStore.deleteCustomizations}
        />
      ) : (
        <PublicRecipe recipeStore={props.recipeStore} reviewStore={props.reviewStore} modals={props.modals} />
      )}
    </StandaloneRecipeContainer>
  );
}

StandaloneRecipe.propTypes = {
  token: PropTypes.string,
  authenticated: PropTypes.bool,
  cacheReady: PropTypes.bool,
  memberStore: PropTypes.object,
  recipeStore: PropTypes.object,
  stashStore: PropTypes.object,
  reviewStore: PropTypes.object,
  noteStore: PropTypes.object,
  mmpStore: PropTypes.object,
  modals: PropTypes.object,
  banner: PropTypes.element,
};
