import Helpers from "../services/Helpers";

export function validateExpMonth(month, year) {
  const { isNotEmpty } = Helpers();

  let result = false;
  const now = new Date();
  if (isNotEmpty(month)) {
    if (isNotEmpty(year)) {
      if (+year > now.getFullYear()) {
        // For any future year, a non-empty month is valid
        result = true;
      } else if (+year === now.getFullYear() && +month > now.getMonth()) {
        // For the current year, month should at least be the current month (getMonth() returns months starting with 0)
        result = true;
      }
    } else {
      // When year has not been provided, a non-empty month is valid
      result = true;
    }
  }
  return result;
}
