import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import PaymentLockedWarning from "../../../views/components/PaymentLockedWarning";
import { DateTime } from "luxon";
import Plans from "../../../views/Member/components/Plans";
import Helpers from "../../../services/Helpers";
import BillingInformation from "../../../views/Subscription/BillingInformation";
import AgreeToChange from "./AgreeToChange";
import DismissButton from "../../../views/Member/components/DismissButton";
import SnailButton from "../../../views/components/SnailButton";

export default function EditSubscriptionForm(props) {
  const { isNotEmpty, paddedLast4 } = Helpers();
  const [selectedPlan, setSelectedPlan] = useState("");
  const [currentPlan, setCurrentPlan] = useState({});
  const [working, setWorking] = useState(false);
  const [agreed, setAgreed] = useState(true);
  const [cardReady, setCardReady] = useState(false);

  useEffect(() => {
    const plan = props.subStore.subscription.length > 0 && props.subStore.plans.find((p) => props.subStore.subscription.at(-1).internalName === p.internalName);
    setCurrentPlan(plan);
    setWorking(false);
  }, [props.subStore.subscription]);

  useEffect(() => {
    // automatically check the checkbox when there is no billing date change
    if (currentPlan && selectedPlan && currentPlan.internalName.substring(0, 2) !== selectedPlan.substring(0, 2)) {
      setAgreed(false);
    } else {
      setAgreed(true);
    }

    // get proration from backend
    if (selectedPlan !== "") {
      props.subStore.getProration(selectedPlan);
    }
  }, [selectedPlan]);

  function change(event) {
    event.preventDefault();
    setWorking(true);
    const newPlan = props.subStore.plans.find((p) => p.internalName === selectedPlan);
    props.subStore.changeSubscription(newPlan, () => {
      props.hide();
      setWorking(false);
      setAgreed(false);
    });
  }

  const changeOrUpgrade =
    currentPlan && selectedPlan && (currentPlan.internalName.substring(0, 2) === selectedPlan.substring(0, 2) || selectedPlan.substring(0, 2) === "RV")
      ? "change"
      : "upgrade";

  function purchaseSummary(plan) {
    let result = null;
    if (selectedPlan !== "" && cardReady) {
      if (props.subStore.subscription.length > 0) {
        const now = DateTime.now();
        const currentBillingDate = DateTime.fromSeconds(props.subStore.subscription[0].nextBillingDate).minus({ days: 1 });
        const newPlan = props.subStore.plans.find((p) => p.internalName === selectedPlan);
        const defaultCard = props.subStore.creditCards.find((cc) => cc.status === 3);
        let chargedDate = "now";
        let creditMessage = null;
        let chargeMessage = null;
        let newBillingDate = null;

        if (plan.internalName.substring(0, 2) === selectedPlan.substring(0, 2)) {
          // --- change is within same product, no pro rata ---
          chargedDate = currentBillingDate.startOf("day") <= now.startOf("day") ? "now" : `on ${currentBillingDate.toLocaleString()}`;
          chargeMessage = (
            <p className="px-3">
              Your membership will immediately {changeOrUpgrade} to {newPlan.name} and your membership will renew for{" "}
              <span className="fw-bold">
                ${newPlan.price / 1} {chargedDate}
              </span>
              .
            </p>
          );
        } else {
          if (selectedPlan.substring(0, 2) === "MP") {
            // --- change is an upgrade, charge partial amount now and indicate next billing date ---
            newBillingDate = DateTime.fromSeconds(props.subStore.proration.date);
            creditMessage = <p className="px-3">This pro-rates your membership {changeOrUpgrade}.</p>;
            chargedDate = (
              <>
                Your membership will then renew on {newBillingDate.toLocaleString()} for ${newPlan.price}.
              </>
            );
            chargeMessage = (
              <>
                <p className="px-3">
                  Your membership will immediately {changeOrUpgrade} to {newPlan.name} and your default card ending in{" "}
                  <span className="fw-bold">{paddedLast4(defaultCard.last4)}</span> will be{" "}
                  <span className="fw-bold"> charged ${props.subStore.proration.amount} now</span>. {chargedDate}
                </p>
                {creditMessage}
              </>
            );
          } else {
            // --- change is a downgrade, charge nothing now, display next billing date and amount ---
            newBillingDate = currentBillingDate;
            chargedDate = newBillingDate.toLocaleString();
            const lastDayOfCurrentMembership = newBillingDate.minus({ days: 1 }).toLocaleString();
            chargeMessage = (
              <p className="px-3">
                Your current membership will be <span className="fw-bold">active until {lastDayOfCurrentMembership}</span>. Your membership will change to{" "}
                {newPlan.name} and your default credit card ending in <span className="fw-bold">{paddedLast4(defaultCard.last4)}</span> will be charged{" "}
                <span className="fw-bold">
                  ${newPlan.price / 1} on {chargedDate}
                </span>
                . Your membership will renew monthly.
              </p>
            );
          }
        }

        const showAgreeToChange = newBillingDate && newBillingDate.startOf("day") !== currentBillingDate.startOf("day");

        result = (
          <>
            <p className="mt-2 mb-1 px-3 fw-bold text-uppercase">Important</p>
            {chargeMessage}
            {showAgreeToChange && (
              <AgreeToChange
                checked={agreed}
                onChange={(e) => setAgreed(e.target.checked)}
                changeOrUpgrade={changeOrUpgrade}
                isDateChanging={plan.internalName.substring(3, 7) !== selectedPlan.substring(3, 7)}
              />
            )}
          </>
        );
      }
    }
    return result;
  }

  function handleDismiss() {
    props.hide();
    setAgreed(false);
  }

  function main() {
    return isNotEmpty(currentPlan) ? (
      <div className="pb-2">
        <Plans
          plans={props.subStore.plans}
          intro={
            <div className="test">
              <h4 className="h-alt mb-3">To change your membership, select an option below.</h4>
            </div>
          }
          currentPlan={currentPlan.internalName}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
        />
        <BillingInformation member={props.member} subStore={props.subStore} signUpContext={false} setCardReady={setCardReady} />
        {purchaseSummary(currentPlan)}
        {isNotEmpty(selectedPlan) && cardReady && <p className="px-3">Would you like to {changeOrUpgrade} your membership?</p>}
        <div className="text-center">
          <DismissButton disabled={working} onClick={handleDismiss} />
          <SnailButton
            className={`btn btn-primary ms-2${working ? " my-2" : ""}`}
            text={"Yes, change my membership"}
            type={"button"}
            onClick={change}
            disabled={working || !agreed || selectedPlan === ""}
            working={working}
            workingTexts={["Changing...", "Hang tight...", "Still working..."]}
          />
        </div>
      </div>
    ) : null;
  }

  return <div>{props.member.paymentLocked ? <PaymentLockedWarning /> : main()}</div>;
}

EditSubscriptionForm.propTypes = {
  member: PropTypes.object,
  subStore: PropTypes.object,
  hide: PropTypes.func,
};
