import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import Helpers from "../../services/Helpers";
import { Link } from "react-router-dom";

export default function TrialBanner(props) {
  const { isNotEmpty } = Helpers();

  const calendar = <span style={{ fontSize: "40px" }}>&#128198;</span>;
  const party = <span style={{ fontSize: "40px" }}>&#127881;</span>;
  const hourGlass = <span style={{ fontSize: "40px" }}>&#8987;</span>;

  function renderBannerContent(nextBillingDate) {
    let result = null;
    if (nextBillingDate.minus({ days: 5 }).startOf("day") >= DateTime.now().startOf("day")) {
      // We are not yet in the extended trial
      const diff = nextBillingDate.minus({ days: 5 }).startOf("day").diff(DateTime.now().startOf("day"), "days");
      const d = diff.days;
      if (d === 0) {
        result = (
          <div className="row">
            <div className="col-auto align-self-center p-0">{calendar}</div>
            <div className="col align-self-center">
              <strong>Last day of your trial</strong>
              <br />
              <Link to="/account?m=become-a-member" className="fw-bold text-secondary">
                Become a member
              </Link>{" "}
              to keep it going! Join now for a sweet deal only available while your trial is active.
            </div>
          </div>
        );
      } else if (d < 5) {
        result = (
          <div className="row">
            <div className="col-auto align-self-center p-0">{calendar}</div>
            <div className="col align-self-center">
              <strong>{d + 1} more days left in your trial</strong>
              <br />
              Loving Plant Fueled Life?{" "}
              <Link to="/account?m=become-a-member" className="fw-bold text-secondary">
                Become a member
              </Link>{" "}
              during your trial and get a special price!
            </div>
          </div>
        );
      }
    } else {
      // We are in the extended trial
      const diff = nextBillingDate.minus({ days: 1 }).startOf("day").diff(DateTime.now().startOf("day"), "days");
      const d = diff.days;
      if (d === 0) {
        result = (
          <div className="row">
            <div className="col-auto align-self-center p-0">{hourGlass}</div>
            <div className="col align-self-center">
              <strong>Your trial is ending!</strong>
              <br />
              This is the last day of your trial.{" "}
              <Link to="/account?m=become-a-member" className="fw-bold text-secondary">
                Become a member
              </Link>{" "}
              today and scoop up our sweet trial deal!
            </div>
          </div>
        );
      } else {
        result = (
          <div className="row">
            <div className="col-auto align-self-center p-0">{party}</div>
            <div className="col align-self-center">
              <strong>Trial extended!</strong>
              <br />
              You have {d + 1} extra days to slice and dice. We've also extended the special trial deal so if you{" "}
              <Link to="/account?m=become-a-member" className="fw-bold text-secondary">
                sign up
              </Link>{" "}
              during this extension you will save some money!
            </div>
          </div>
        );
      }
    }
    return result;
  }

  function main() {
    let result = null;
    // if (props.member.hasTrial) {
    if (props.subscription.at(-1)?.internalName === "PFL_TRIAL") {
      const nextBillingDate = DateTime.fromSeconds(props.subscription.at(-1).nextBillingDate);
      if (isNotEmpty(nextBillingDate)) {
        const bannerContent = renderBannerContent(nextBillingDate);
        if (isNotEmpty(bannerContent)) {
          result = (
            <div className="row bg-light pb-3">
              <div className="d-flex justify-content-center">
                <div className="alert alert-light alert-dismissible pe-3 mb-0 d-inline-block center-block">
                  {renderBannerContent(nextBillingDate)}
                  <button type="button" className="btn-ex-small btn-close p-2" onClick={props.hide} />
                </div>
              </div>
            </div>
          );
        }
      }
    }
    return result;
  }
  return props.show ? main() : null;
}

TrialBanner.propTypes = {
  member: PropTypes.object,
  subscription: PropTypes.array,
  show: PropTypes.bool,
  hide: PropTypes.func,
};
