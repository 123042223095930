import React from "react";
import PropTypes from "prop-types";

export default function VimeoVideo(props) {
  return props.vimeoId === null ? null : (
    <iframe
      src={`https://player.vimeo.com/video/${props.vimeoId}`}
      width="560"
      height="315"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  )
}

VimeoVideo.propTypes = {
  title: PropTypes.string,
  vimeoId: PropTypes.string,
}