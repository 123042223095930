import React, { useEffect } from "react";
import PropTypes from "prop-types";
import useCollection from "../../services/stores/CollectionStore";
import Footer from "../components/Footer";
import Constants from "../../services/Constants";
import Document from "../Search/components/Document";
import { Helmet } from "react-helmet";
import MarkdownViewer from "../components/MarkdownViewer";

export default function Collection(props) {
  const { collectionTypes, documentTypes } = Constants();
  const { collection } = useCollection(
    props.token,
    props.documentType === documentTypes.WIZARD_PLAN ? collectionTypes.WIZARD_PLAN : collectionTypes.RECIPE,
    props.id
  );

  useEffect(() => {
    document.title = `${collection.title} - Plant Fueled Life`;
  }, [collection.title]);

  function className() {
    let result = "container-lg";
    if (collection.cssClass) {
      result = `container-lg ${collection.cssClass}`;
    }
    return result;
  }

  const items = collection.items.map((d) => (
    <Document key={d.id} authenticated={props.authenticated} member={props.member} stashStore={props.stashStore} modals={props.modals} document={d} />
  ));

  const anonymousWizardPlanCollectionHeader = (
    <div className="row">
      <div className="col p-3 my-4 mx-2 p-md-4 bg-white rounded shadow-sm">
        <h1>{collection.title}</h1>
        <h3 className="h-alt">Plant based meal plan collection</h3>
        <p className="lead">
          Eating plant based is simple and delicious when you have a hand-crafted meal plan to follow, like those in our {collection.title} collection.
        </p>
        <a
          className="btn btn-primary"
          href={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=wizard+plan+collection+trial&utm_term=try+our+meal+plans+${props.id}`}
        >
          Try our meal plans
        </a>
        <p className="text-muted pt-2">
          <small>
            No credit card <em>or</em> perfection required.
          </small>
        </p>
      </div>
    </div>
  );
  const anonymousRecipeCollectionHeader = (
    <div className="row">
      <div className="col p-3 my-4 mx-2 p-md-4 bg-white rounded shadow-sm">
        <h1>{collection.title}</h1>
        <h3 className="h-alt">Plant based recipe collection</h3>
        <p className="lead">Eating more plants is a win-win for everyone when you have delcious recipes, like these in our {collection.title} collection.</p>
        <a
          className="btn btn-primary"
          href={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=recipe+collection+trial&utm_term=make+these+recipes+${props.id}`}
        >
          Unlock recipes with free trial
        </a>
        <p className="text-muted pt-2">
          <small>
            No credit card <em>or</em> perfection required.
          </small>
        </p>
      </div>
    </div>
  );
  const anonymousWizardPlanCollectionFooter = (
    <div className="row">
      <div className="col p-3 my-3 mx-2 p-md-4 bg-white rounded shadow-sm">
        <h1>Ready to fall in love with plant based cooking?</h1>
        <p className="lead">
          With our{" "}
          <a href="https://cleanfooddirtygirl.com/plant-based-meal-plans/" target="_blank">
            whole food plant based meal plans
          </a>{" "}
          (AKA Wizard Plans) we do the planning for you. Just go grocery shopping and spend 2-4 hours batch cooking each week. Say goodbye to scrambling to
          piece together dinner and hello to five nights of flavor.
        </p>
        <a
          className="btn btn-primary"
          href={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=wizard+plan+collection+trial&utm_term=get+cooking+with+a+free+trial+${props.id}`}
        >
          Get cooking with a free trial
        </a>
        <p className="text-muted pt-2">
          <small>
            No credit card <em>or</em> perfection required. <br />
            Already a member? <a href="/sign-in">Sign in and let's cook</a>!
          </small>
        </p>
      </div>
    </div>
  );
  const anonymousRecipeCollectionFooter = <div className="row mb-2"></div>;

  return props.authenticated ? (
    <>
      <main id={props.documentType === documentTypes.WIZARD_PLAN ? "wizard-plan-collection" : "recipe-collection"} className={className()}>
        <Helmet>
          <link rel="canonical" href={`https://members.cleanfooddirtygirl.com/${collection.slug}`} />
        </Helmet>
        {props.banner}
        <h1 className="text-center page-title mb-3">{collection.title}</h1>
        {collection.text && (
          <p className="text-center">
            <MarkdownViewer markdown={collection.text} />
          </p>
        )}
        <div className="row bg-white py-2 px-sm-2 py-sm-3">{items}</div>
      </main>
      <Footer />
    </>
  ) : (
    <main id={props.documentType === documentTypes.WIZARD_PLAN ? "wizard-plan-collection" : "recipe-collection"} className={className()}>
      <div className="container-lg">
        {props.documentType === documentTypes.WIZARD_PLAN ? anonymousWizardPlanCollectionHeader : anonymousRecipeCollectionHeader}
        <div className="row bg-white py-2 px-sm-2 py-sm-3">{items}</div>
        {props.documentType === documentTypes.WIZARD_PLAN ? anonymousWizardPlanCollectionFooter : anonymousRecipeCollectionFooter}
        <div className="text-center footer-anonymous pb-3">
          <a href="/">
            <img
              src={`${process.env.PUBLIC_URL}/images/plant-fueled-life-clean-food-dirty-girl.png`}
              className="text-center p-3"
              width="200"
              alt="Plant Fueled Life by Clean Food Dirty Girl"
            />
          </a>
          <nav className="ms-md-auto footer-icons pt-1 pt-md-0">
            <a className="p-2" href="https://cleanfooddirtygirl.com/" target="_blank" rel="noreferrer">
              <i className="fa fa-globe" />
            </a>
            <a className="p-2" href="https://www.facebook.com/cfdgirl" target="_blank" rel="noreferrer">
              <i className="fab fa-facebook" />
            </a>
            <a className="p-2" href="https://www.instagram.com/cleanfooddirtygirl/" target="_blank" rel="noreferrer">
              <i className="fab fa-instagram" />
            </a>
            <a className="p-2" href="https://open.spotify.com/show/5ViLN4goOjFHyVJs5nMDnG" target="_blank" rel="noreferrer">
              <i className="fab fa-spotify" />
            </a>
            <a className="p-2 strip-right" href="http://cleanfooddirtygirl.com/contact" target="_blank" rel="noreferrer">
              <i className="fas fa-envelope-square" />
            </a>
          </nav>
        </div>
      </div>
    </main>
  );
}

Collection.propTypes = {
  authenticated: PropTypes.bool,
  token: PropTypes.string,
  member: PropTypes.object,
  stashStore: PropTypes.object,
  modals: PropTypes.object,
  id: PropTypes.number,
  documentType: PropTypes.string,
  banner: PropTypes.element,
};
