import {useEffect, useReducer} from "react";
import axios from "axios";
import Helpers from "../Helpers";

const actions = {
  LOAD: "load",
}

const initialState = {
  redirects: [],
  routes: [],
}

export default function useRouting() {
  const {handleError} = Helpers();
  function reducer(state, action) {
    if (action.type === actions.LOAD) {
      return {...state, redirects: action.data.redirects, routes: action.data.routes}
    }
    return state;
  }
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const redirectsPromise = axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API}/cms/redirects`,
    });
    const routesPromise = axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: `${process.env.REACT_APP_API}/cms/routes`,
    });
    Promise.all([redirectsPromise, routesPromise]).then(responses => {
      dispatch({
        type: actions.LOAD,
        data: { redirects: responses[0].data, routes: responses[1].data }
      });
    }).catch(err => {
      handleError(err);
    });
  }, []);

  return state;
}