import React from "react";
import PropTypes from "prop-types";

export default function NoResults(props) {

  const message = props.store.noResultsMessage();

  return message === null ? null : (
    <div className="text-muted text-center">
      {message}
    </div>
  );
}

NoResults.propTypes = {
  store: PropTypes.object,
}