import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Redirect, useLocation} from "react-router-dom";

export default function PortalRedirect(props) {
  let location = useLocation();
  const [destination, setDestination] = useState("/");
  const [canRedirect, setCanRedirect] = useState(false);

  useEffect(() => {
    if (props.redirect.sourceUrl.indexOf("*") !== -1 && props.redirect.targetUrl.indexOf("*") !== -1) {
      // We remove legacy classroom plan section
      const pathname = location.pathname.indexOf("/pfmp/") !== -1 ? (
        location.pathname.replace("/menu", "").replace("/notes_and_tips", "").replace("/grocery_list", "").replace("/prep", "").replace("/batching", "")
      ) : location.pathname;
      // We keep everything after the *
      const keptPath = pathname.replace(props.redirect.sourceUrl.replace("*",""), "");
      const nextLocation = `${props.redirect.targetUrl.replace("*", "")}${keptPath}`.replace("//", "/").concat(location.search);
      setDestination(nextLocation);
    } else {
      // For all other use cases, we just use the targetUrl
      setDestination(props.redirect.targetUrl.replace("*","").concat(location.search));
    }
  }, [props.redirect]);

  useEffect(() => {
    if (destination !== "/") {
      setCanRedirect(true);
    }
  }, [destination])

  return canRedirect ? <Redirect to={destination} /> : (
    <main className="text-center">
      <span className="spinner-border"/>
    </main>
  );
}

PortalRedirect.propTypes = {
  redirect: PropTypes.object,
}