import "./Popover.css";
import React, {useState} from "react";
import PropTypes from "prop-types";
import Tippy from '@tippyjs/react';
import Helpers from "../../services/Helpers";
import MarkdownViewer from "./MarkdownViewer";

export default function Popover(props) {
  const {assetUrl, isNotEmpty} = Helpers();
  const [visible, setVisible] = useState(false);

  const photo = isNotEmpty(props.photo) ? (
    <div>
      <img src={assetUrl(props.photo)} alt={props.title} className="img-fluid pb-2"/>
    </div>
  ) : (
    ""
  );
  const content = isNotEmpty(props.content) ? (
    <div>
      <MarkdownViewer markdown={props.content}/>
    </div>
  ) : (
    ""
  );
  const dataContent = (
    <div>
      {props.title}
      {photo}
      {content}
    </div>
  );
  const iconClassName = isNotEmpty(props.iconClassName) ? props.iconClassName : "fa-regular fa-circle-info";

  return isNotEmpty(props.photo) || isNotEmpty(props.content) ? (
    <span className="cursor-pointer">
      <Tippy
        onClickOutside={() => setVisible(false)}
        visible={visible}
        content={dataContent}
      >
        <span style={{ marginLeft: "3px" }} className={iconClassName} onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setVisible(true);
        }} />
      </Tippy>
    </span>
  ) : null;
}

Popover.propTypes = {
  title: PropTypes.string,
  photo: PropTypes.string,
  content: PropTypes.string,
  iconClassName: PropTypes.string,
}