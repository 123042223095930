import {useEffect, useState} from "react";
import axios from "axios";
import Constants from "../Constants";
import Helpers from "../Helpers";
import {Userpilot} from "userpilot";

export default function useMember(token, setAuthMessage, cacheReady, signOut) {
  const {authMessages, cookingMethods, measurementSystems} = Constants();
  const {isEmpty , isNotEmpty} = Helpers();
  const [member, setMember] = useState(emptyMember());
  const [waitingForMember, setWaitingForMember] = useState(true);
  const [purchases, setPurchases] = useState([]);

  function emptyMember() {
    return {
      id: null,
      admin: false,
      email: "",
      firstname: "",
      lastname: "",
      displayName: "",
      photo: "",
      since: null,
      paymentLocked: false,
      preferences: {
        portions: 4,
        stoveTopOrInstantPot: cookingMethods.INSTANT_POT,
        volumeSystem: measurementSystems.US,
        weightSystem: measurementSystems.METRIC,
        showNutritionFacts: false,
        mealPlannerListView: false,
        searchListViewOnMobile: true,
        allergens: [],
      },
      hasMP: false,
      hasRV: false,
      hadMP: false,
      hasTrial: false,
      hadTrial: false,
    };
  }

  useEffect(() => {
    if (isEmpty(token)){
      setMember(emptyMember());
    } else {
      if (cacheReady) {
        getMember();
      }
    }
  }, [token, cacheReady]);

  useEffect(() => {
    if (isNotEmpty(member.id)) {
      setWaitingForMember(false);
    } else {
      // Artificial delay introduced to avoid view flickering between forms during the sign-up process
      setTimeout(() => {setWaitingForMember(false)}, 2000);
    }
  }, [member]);

  function getMember() {
    setWaitingForMember(true);
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/members/self`,
    }).then(res => {
      setMember(res.data);
      Userpilot.identify(res.data.id, {
        name: res.data.firstname,
        last_name: res.data.lastname,
        email: res.data.email,
        nutrition_info: res.data.preferences.showNutritionFacts ? "yes" : "no",
        friends_and_family: res.data.mpFriend ? "yes" : "no",
        admin: res.data.admin ? "yes" : "no",
        plan: res.data.plan, //values: monthly, quarterly, annual, trial, none
        started_mp_trial_at: res.data.startedMpTrial ? new Date(res.data.startedMpTrial * 1000).toISOString() : null,
        started_pfl_trial_at: res.data.startedPflTrial ? new Date(res.data.startedPflTrial * 1000).toISOString() : null,
        started_membership_at: res.data.startedMembership ? new Date(res.data.startedMembership * 1000).toISOString() : null,
        membership_ends_at: res.data.membershipEndsOn ? new Date(res.data.membershipEndsOn * 1000).toISOString() : null
      })
    }).catch(err => {
      if (err.response && err.response.status === 401) {
        // bad token
        signOut();
      }
    });
  }

  function updatePicture(files, cb) {
    const data = new FormData();
    if (files.length > 0) {
      data.append("content-type", files[0].type);
      data.append("file", files[0]);
    }
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/members/${member.id}/picture`,
      data: data
    }).then(res => {
      setMember(res.data);
      if (cb) cb();
    }).catch(() => {
      if (cb) cb();
    });
  }

  function updateMember(data) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/members/${member.id}`,
      data: data
    }).then(() => {
      getMember();
    }).catch(err => {
      if (err.request.status === 409) {
        setAuthMessage(authMessages.EMAIL_ALREADY_USED);
      } else {
        setAuthMessage(authMessages.SERVER_ERROR);
      }
    });
  }

  function changePassword(data, cb) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/members/${member.id}/password`,
      data: data
    }).then(() => {
      if (cb) cb();
    }).catch(() => {
      // we do nothing
    });
  }

  function updatePreferences(data, cb) {
    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/members/${member.id}/preferences`,
      data: data
    }).then(res => {
      setMember(res.data);
      if (cb) cb();
    }).catch(() => {
      // we do nothing
    });
  }

  function getPurchases(cb) {
    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/members/${member.id}/purchases`,
    }).then(res => {
      setPurchases(res.data);
      if (cb) cb();
    }).catch(() => {
      if (cb) cb();
    });
  }

  function recordDownload(data) {
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/members/${member.id}/downloads`,
      data: data,
    });
  }

  function allergenIds() {
    return member.preferences.allergens.map(a => a.id);
  }

  const memberStore = {
    member: member,
    waitingForMember: waitingForMember,
    purchases: purchases,
    getMember: getMember,
    updatePicture: updatePicture,
    updateMember: updateMember,
    changePassword: changePassword,
    updatePreferences: updatePreferences,
    getPurchases: getPurchases,
    recordDownload: recordDownload,
    allergenIds: allergenIds,
  }

  return { memberStore };
}
