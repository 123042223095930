import React, {useState} from "react";
import PropTypes from "prop-types";
import {useHistory} from "react-router-dom";
import Helpers from "../../../services/Helpers";

export default function CopyPublicPlan(props) {
  let history = useHistory();
  const {makeUrlLookGood} = Helpers();
  const [working, setWorking] = useState(false);

  const addButton = working ? (
    <button
      className="btn btn-primary"
      type="button"
      disabled={true}
    >
      <span className="spinner-border spinner-border-sm"/>
      <span className="ms-1">Adding...</span>
    </button>
  ) : (
    <button
      className="btn btn-primary"
      type="button"
      onClick={() => {
        setWorking(true);
        props.mmpStore.copyPublicPlan(plan => {
          if (plan.hasOwnProperty("id") && plan.hasOwnProperty("name")) {
            history.push(`/meal-plans/meal-planner/${makeUrlLookGood(plan.id, plan.name)}`);
          } else {
            history.push("/meal-plans/meal-planner");
          }
        });
      }}
    >
      Add to Meal Planner
    </button>
  );

  return (
    <div className="text-sm-center col-12 items-align-middle meal-planner-options my-2">
      {props.mmpStore.state.plans.length < 20 || props.member.admin ? addButton : (
        <p>You already have 20 plans in your Meal Planner, please delete an existing plan to add a new one.</p>
      )}
    </div>
  );
}

CopyPublicPlan.propTypes = {
  mmpStore: PropTypes.object,
  member: PropTypes.object,
}