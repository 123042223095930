import React, {useEffect} from "react";
import useVideo from "../../../../services/stores/VideoStore";
import PropTypes from "prop-types";
import Helpers from "../../../../services/Helpers";
import {Collapse} from "bootstrap";
import MarkdownViewer from "../../../components/MarkdownViewer";

export default function Video(props) {
  const {assetUrl, isNotEmpty} = Helpers();
  const {video} = useVideo(props.token, props.id, props.active);

  useEffect(() => {
    video.files.find(f => f.contentType.indexOf("audio/") !== -1)
    props.updateDownloads(video.files.filter(f => f.contentType.indexOf("audio/") === -1));
  }, [video]);

  useEffect(() => {
    const element = document.getElementById(`v-${props.id}`);
    const collapse = new Collapse(element, {toggle: false});
    props.active ? collapse.show() : collapse.hide();
    if (!props.active) {
      props.updateDownloads([]);
    }
  }, [props.active, props.id]);

  function iframe() {
    let result = null;
    if (props.active && isNotEmpty(video.vimeoId)) {
      result =
        <div className="ratio ratio-16x9">
          <iframe
            id="intro-video"
            src={`https://player.vimeo.com/video/${video.vimeoId}`}
            frameBorder="0"
            title="Meal Plans Classroom Tutorial"
            allowFullScreen=""
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>

    }
    return result;
  }

  const audioFile = video.files.find(f => f.contentType.indexOf("audio/") !== -1);

  const audio = isNotEmpty(audioFile) ? (
    <div>
      <p>Listen to audio</p>
      <audio controls={true}>
        <source
          src={assetUrl(audioFile.storageKey)}
          type="audio/mpeg"
        />
      </audio>
    </div>
  ) : null;

  return props.active ? (
    <div id={`video-${props.id}`} className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button"
          type="button"
          onClick={() => props.setActive(null)}
        >
          <h3 className="mt-1 h-playlist">{props.title}</h3>
        </button>
      </h2>
      <div id={`v-${props.id}`} className="accordion-collapse collapse">
        <div className="accordion-body accordion-video">
          <div className="video-wrap">{iframe()}</div>
          <div className="mt-4 mb-3"><MarkdownViewer markdown={video.text} /></div>
          <div className="text-center">{audio}</div>
        </div>
      </div>
    </div>
  ) : (
    <div id={`video-${props.id}`}  className="accordion-item">
      <h2 className="accordion-header">
        <button
          className="accordion-button collapsed"
          type="button"
          onClick={props.setActive}
        >
          <h4 className="mt-1 h-playlist">{props.title}</h4>
        </button>
      </h2>
      <div id={`v-${props.id}`} className="accordion-collapse collapse">
        <div className="accordion-body">
        </div>
      </div>
    </div>
  );

}

Video.propTypes = {
  token: PropTypes.string,
  id: PropTypes.number,
  title: PropTypes.string,
  active: PropTypes.bool,
  setActive: PropTypes.func,
  updateDownloads: PropTypes.func,
}