import Constants from "./Constants";
import Helpers from "./Helpers";

export default function ScalingService(cache, rh, member) {
  const {cookingMethods, recipeComponentCategories, recipeTypes,recipeViewContexts} = Constants();
  const {groupByArray, isEmpty, isNotEmpty} = Helpers();

  function adjustMinimumYield(recipes, childRecipeAdditionalPortions) {
    for (let crap of childRecipeAdditionalPortions) {
      const recipe = recipes.find(r => r.id === crap.childRecipeId);
      if (
        isNotEmpty(recipe) &&
        recipe.ratio > 0 &&
        crap.additionalPortions > 0 &&
        cache.specialScalingIds.indexOf(recipe.id) === -1
      ) {
        recipe["additionalPortions"] = crap.additionalPortions;
        if (isNotEmpty(recipe.minimumYieldPossible)) {
          if (recipe.minimumYieldPossible > (recipe.yieldVolume * recipe.ratio)) {
            const minimumYieldPortions = recipe.minimumYieldPossible * recipe.portions / recipe.yieldVolume;
            if (recipe.portions * recipe.ratio > minimumYieldPortions) {
              // work with regular yield
              const totalPortions = (recipe.portions * recipe.ratio) + crap.additionalPortions;
              recipe.minimumYieldPossible = totalPortions * recipe.yieldVolume / recipe.portions;
            } else {
              // work with minimum yield
              const totalPortions = minimumYieldPortions + crap.additionalPortions;
              recipe.minimumYieldPossible = totalPortions * recipe.minimumYieldPossible / minimumYieldPortions;
            }
          } else {
            // work with regular yield
            const totalPortions = (recipe.portions * recipe.ratio) + crap.additionalPortions;
            recipe.minimumYieldPossible = totalPortions * recipe.yieldVolume / recipe.portions;
          }
        } else {
          // work with regular yield
          const totalPortions = (recipe.portions * recipe.ratio) + crap.additionalPortions;
          recipe.minimumYieldPossible = totalPortions * recipe.yieldVolume / recipe.portions;
        }
      }
    }
  }

  function getMealCustomPortions(meal, customizations) {
    let portions;
    if (customizations.recipePortions.hasOwnProperty(meal.id)) {
      portions = customizations.recipePortions[meal.id];
    } else if (cache.specialScalingIds.indexOf(meal.id) === -1) {
      portions = member.preferences.portions;
    }
    return isNotEmpty(portions) ? portions : meal.portions;
  }

  function setMealsRatio(recipes, customizations) {
    for (let meal of recipes) {
      const customPortions = getMealCustomPortions(meal, customizations);
      meal["customPortions"] = customPortions;
      meal["ratio"] = customPortions === 0 ? 0 : customPortions / meal.portions;
    }
  }

  function customizeRecipesComponents(recipeData, recipeTools) {
    const preferST = isNotEmpty(member.preferences.stoveTopOrInstantPot) && member.preferences.stoveTopOrInstantPot === cookingMethods.STOVE_TOP;
    for (let recipe of recipeData.recipes) {
      if (recipe.hasOwnProperty("hasInstantPotAndStoveTopVersions") && recipe.hasInstantPotAndStoveTopVersions) {
        const recipeTool = recipeTools.find(rt => rt.recipeId === recipe.id);
        if (isNotEmpty(recipeTool)) {
          const toolName = cache.toolName(recipeTool.toolId);
          if (toolName === cookingMethods.INSTANT_POT) {
            recipe.instantPotRecipeComponents = JSON.parse(JSON.stringify(recipeData.masters.find(master => master.id === recipe.id).instantPotRecipeComponents));
            recipe.stoveTopRecipeComponents = [];
          } else if (toolName === cookingMethods.STOVE_TOP) {
            recipe.stoveTopRecipeComponents = JSON.parse(JSON.stringify(recipeData.masters.find(master => master.id === recipe.id).stoveTopRecipeComponents));
            recipe.instantPotRecipeComponents = [];
          }
        } else if (preferST) {
          recipe.stoveTopRecipeComponents = JSON.parse(JSON.stringify(recipeData.masters.find(master => master.id === recipe.id).stoveTopRecipeComponents));
          recipe.instantPotRecipeComponents = [];
        } else {
          recipe.instantPotRecipeComponents = JSON.parse(JSON.stringify(recipeData.masters.find(master => master.id === recipe.id).instantPotRecipeComponents));
          recipe.stoveTopRecipeComponents = [];
        }
      }
    }
  }

  function deleteChildRecipes(recipes, deletedChildRecipes) {
    if (deletedChildRecipes.length > 0) {
      for (let recipe of recipes) {
        const deleted = deletedChildRecipes.filter(dcr => dcr.parentRecipeId === recipe.id);
        if (deleted.length > 0) {
          recipe.stoveTopRecipeComponents = recipe.stoveTopRecipeComponents.filter(component => deleted.find(dcr => dcr.childRecipeId === component.childRecipeId) === undefined);
          recipe.instantPotRecipeComponents = recipe.instantPotRecipeComponents.filter(component => deleted.find(dcr => dcr.childRecipeId === component.childRecipeId) === undefined);
        }
      }
    }
  }

  function addChildRecipeAppearances(recipes, childRecipeIds) {
    for (let meal of recipes.meals) {
      if (meal.ratio > 0) {
        processComponents(recipes, meal.displayOrder, meal.instantPotRecipeComponents, meal.ratio, childRecipeIds);
      }
    }
  }

  function processComponents(recipes, displayOrder, components, ratio, childRecipeIds) {
    for (let rc of components) {
      if (rc.category === "Recipe") {
        processRecipe(recipes, displayOrder, rc, ratio, childRecipeIds);
      }
    }
  }

  function processRecipe(recipes, displayOrder, rc, parentRatio, childRecipeIds) {
    const batching = recipes.batchings.find(b => b.id === rc.childRecipeId);
    if (isNotEmpty(batching)) {
      const childRecipeIdIndex = childRecipeIds.indexOf(batching.id);
      if (childRecipeIdIndex !== -1) {
        // The recipe has already been added from a previous meal, messing up the order. We remove it before adding it at the end of the array
        childRecipeIds.splice(childRecipeIdIndex, 1);
      }
      childRecipeIds.push(batching.id);
      // initialize appearances if needed
      if (!batching.hasOwnProperty("appearances")) batching["appearances"] = [];
      addRecipeAppearance(recipes, displayOrder, rc, parentRatio, batching);
      if (batching.instantPotRecipeComponents.length > 0) {
        processComponents(
          recipes,
          displayOrder,
          batching.instantPotRecipeComponents,
          batching.appearances[batching.appearances.length - 1].ratio,
          childRecipeIds
        );
      } else if (batching.stoveTopRecipeComponents.length > 0) {
        processComponents(
          recipes,
          displayOrder,
          batching.stoveTopRecipeComponents,
          batching.appearances[batching.appearances.length - 1].ratio,
          childRecipeIds
        );
      }
    } else {
      const nom = recipes.nightOfMeals.find(
        nom => nom.id === rc.childRecipeId
      );
      if (isNotEmpty(nom)) {
        const childRecipeIdIndex = childRecipeIds.indexOf(nom.id);
        if (childRecipeIdIndex !== -1) {
          // The recipe has already been added from a previous meal, messing up the order. We remove it before adding it at the end of the array
          childRecipeIds.splice(childRecipeIdIndex, 1);
        }
        childRecipeIds.push(nom.id);
        // initialize appearances if needed
        if (!nom.hasOwnProperty("appearances")) nom["appearances"] = [];
        addRecipeAppearance(recipes, displayOrder, rc, parentRatio, nom);
        if (nom.instantPotRecipeComponents.length > 0) {
          processComponents(
            recipes,
            displayOrder,
            nom.instantPotRecipeComponents,
            nom.appearances[nom.appearances.length - 1].ratio,
            childRecipeIds
          );
        }
      }
    }
  }

  function addRecipeAppearance(recipes, displayOrder, rc, parentRatio, recipe) {
    const parentIsMenuMeal = recipes.meals.find(m => m.id === rc.parentRecipeId) !== undefined;
    let appearanceRatio = 0;
    if (isNotEmpty(rc.volume)) {
      appearanceRatio = (rc.volume / recipe.yieldVolume) * parentRatio;
    }
    const ra = {
      displayOrder: displayOrder,
      recipeId: rc.parentRecipeId,
      parentIsMenuMeal: parentIsMenuMeal,
      ratio: appearanceRatio,
    };
    recipe.appearances.push(ra);
  }


  function scaleMeals(recipes, ingredients) {
    for (let meal of recipes) {
      if (meal.ratio > 0) {
        if (meal.type === recipeTypes.ASSEMBLY) {
          scaleRC(meal, meal.ratio, ingredients);
        } else {
          scaleRCWithYield(meal, ingredients);
        }
      }
    }
  }

  function scaleChildRecipes(recipes, ingredients) {
    for (let recipe of recipes) {
      if (!recipe.hasOwnProperty("appearances")) {
        recipe["appearances"] = [];
      }
      let appearancesRatioSum = recipe.appearances.map(ra => ra.ratio).reduce((r, i) => r + i, 0);
      let specialScalingRatio = 0;
      if (cache.specialScalingIds.indexOf(recipe.id) !== -1 && appearancesRatioSum > 0) {
        const remainder = appearancesRatioSum % 1;
        if (remainder > 0) {
          let ratioCorrection;
          if (remainder < .5) {
            ratioCorrection = .5;
          } else if (remainder > .5) {
            ratioCorrection = 1;
          }
          specialScalingRatio = Math.floor(appearancesRatioSum) + ratioCorrection;
        }
      }

      let usedVolume = recipe.yieldVolume * appearancesRatioSum;
      let scaledYieldVolume = 0;
      let yieldVolumeLeftover = 0;
      if (specialScalingRatio > 0) {
        // Adjust minimum yield
        recipe.minimumYieldPossible = recipe.yieldVolume * specialScalingRatio;
      }
      if (
        isNotEmpty(recipe.minimumYieldPossible) &&
        recipe.minimumYieldPossible > usedVolume
      ) {
        scaledYieldVolume = recipe.minimumYieldPossible;
      } else {
        scaledYieldVolume = usedVolume;
      }
      if (scaledYieldVolume > usedVolume) {
        yieldVolumeLeftover = scaledYieldVolume - usedVolume;
      }
      recipe["scaledYieldVolume"] = scaledYieldVolume;
      recipe["yieldVolumeLeftover"] = yieldVolumeLeftover;
      if (scaledYieldVolume > 0 && recipe.yieldVolume > 0) {
        scaleRC(recipe, scaledYieldVolume / recipe.yieldVolume, ingredients);
      }
    }
  }

  /**
   * Modify the recipe component quantities according to a ratio
   *
   * @param {Object} recipe - a recipe
   * @param {number} ratio - the ratio used to modify quantities
   * @param {array} ingredients - list of ingredients
   */
  function scaleRC(recipe, ratio, ingredients) {
    let scaledYieldOrPortions;
    switch (recipe.type) {
      case recipeTypes.COMPONENT:
        scaledYieldOrPortions = recipe.scaledYieldVolume;
        break;
      case recipeTypes.ASSEMBLY:
        scaledYieldOrPortions = recipe.portions;
        break;
      default:
        scaledYieldOrPortions = 1;
    }
    if (recipe.stoveTopRecipeComponents.length > 0) {
      for (let rc of recipe.stoveTopRecipeComponents) {
        if (isNotEmpty(rc.volume)) rc.volume *= ingredientRatio(rc.ingredientId, scaledYieldOrPortions, ratio, ingredients);
        if (isNotEmpty(rc.weight)) rc.weight *= ingredientRatio(rc.ingredientId, scaledYieldOrPortions, ratio, ingredients);
      }
    } else {
      for (let rc of recipe.instantPotRecipeComponents) {
        if (isNotEmpty(rc.volume)) rc.volume *= ingredientRatio(rc.ingredientId, scaledYieldOrPortions, ratio, ingredients);
        if (isNotEmpty(rc.weight)) rc.weight *= ingredientRatio(rc.ingredientId, scaledYieldOrPortions, ratio, ingredients);
      }
    }
  }

  /**
   * Modify the recipe component quantities according to a ratio
   * while taking into account the minimum yield of the recipe
   *
   * @param {Object} recipe - a recipe
   * @param {array} ingredients - a list of ingredients
   */
  function scaleRCWithYield(recipe, ingredients) {
    if (isNotEmpty(recipe.minimumYieldPossible)) {
      if (recipe.yieldVolume * recipe.ratio < recipe.minimumYieldPossible) {
        recipe.scaledYieldVolume = recipe.minimumYieldPossible;
        recipe.yieldVolumeLeftover =
          recipe.minimumYieldPossible - recipe.yieldVolume * recipe.ratio;
      } else {
        recipe.scaledYieldVolume = recipe.yieldVolume * recipe.ratio;
      }
    } else {
      recipe.scaledYieldVolume = recipe.yieldVolume * recipe.ratio;
    }
    scaleRC(recipe, recipe.scaledYieldVolume / recipe.yieldVolume, ingredients);
  }

  function scale(context, recipes, ingredients) {
    for (let recipe of recipes) {
      let scaledYieldOrPortions = 1;
      let ratio = recipe.ratio;
      if (context === recipeViewContexts.MAGIC_MEAL_PLANNER) {
        ratio = recipe.appearances.map(a => a.ratio).reduce((a, b) => a + b, 0);
      }
      // Add scaled volume and leftover volume when possible
      if (recipe.type !== recipeTypes.ASSEMBLY) {
        let specialScalingRatio = 0;
        if (recipe.ratio > 0 && cache.specialScalingIds.indexOf(recipe.id) !== -1) {
          const remainder = recipe.ratio % 1;
          if (remainder > 0) {
            let ratioCorrection;
            if (remainder < .5) {
              ratioCorrection = .5;
            } else if (remainder > .5) {
              ratioCorrection = 1;
            }
            specialScalingRatio = Math.floor(recipe.ratio) + ratioCorrection;
          }
        }
        if (specialScalingRatio > 0) {
          // Adjust minimum yield
          recipe.minimumYieldPossible = recipe.yieldVolume * specialScalingRatio;
        }
        let usedVolume = recipe.yieldVolume * recipe.ratio;
        recipe["usedVolume"] = usedVolume;
        if (isNotEmpty(recipe.minimumYieldPossible) && recipe.minimumYieldPossible > usedVolume) {
          recipe["scaledYieldVolume"] = recipe.minimumYieldPossible;
          recipe["yieldVolumeLeftover"] = recipe.minimumYieldPossible - usedVolume;
        } else {
          recipe["scaledYieldVolume"] = usedVolume;
          recipe["yieldVolumeLeftover"] = 0;
        }
        scaledYieldOrPortions = recipe.scaledYieldVolume;
        if (recipe.scaledYieldVolume > 0 && recipe.yieldVolume > 0) {
          ratio = recipe.scaledYieldVolume / recipe.yieldVolume;
        }
      } else {
        scaledYieldOrPortions = recipe.portions * recipe.ratio;
      }
      const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
      for (let component of components) {
        if (isNotEmpty(component.volume)) component.volume *= ingredientRatio(component.ingredientId, scaledYieldOrPortions, ratio, ingredients);
        if (isNotEmpty(component.weight)) component.weight *= ingredientRatio(component.ingredientId, scaledYieldOrPortions, ratio, ingredients);
      }
    }
  }

  function addMissingProperties(recipes, ingredients) {
    for (let recipe of recipes) {
      const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
      for (let component of components) {
        if (component.category === recipeComponentCategories.RECIPE) {
          const childRecipe = recipes.find(r => r.id === component.childRecipeId);
          if (isNotEmpty(childRecipe)) {
            component["childRecipeName"] = childRecipe.name;
            component["childRecipeType"] = childRecipe.type;
          }
        } else if (component.category === recipeComponentCategories.INGREDIENT) {
          const ingredient = ingredients.find(i => i.id === component.ingredientId);
          if (isNotEmpty(ingredient)) {
            component["ingredientName"] = ingredient.name;
            component["ingredientInvertedName"] = ingredient.invertedName;
            component["ingredientPhoto"] = ingredient.photo;
            component["ingredientNotesAndTips"] = ingredient.notesAndTips;
            component["leafyGreenToWash"] = ingredient.leafyGreenToWash;
          }
        }
      }
    }
  }

  /**
   * Get the specific scaling ratio of an ingredient for a given number of portions
   * Current ratio is returned if there is no specific scaling ratio for the ingredient or if the current ratio is 1 or less
   *
   * @param {number} ingredientId - id of the ingredient we want to scale
   * @param {number} scaledYieldOrPortions - scaled yield of batching/night of meal recipe or selected portions of the menu meal/bonus recipe
   * @param {number} ratio - current calculated scaling ratio of the recipe
   * @param {array} ingredients - list of ingredients
   * @return {number} ingredient specific scaling ratio
   */
  function ingredientRatio(ingredientId, scaledYieldOrPortions, ratio, ingredients) {
    let ingredientRatio = ratio;
    if (isNotEmpty(ingredientId) && ratio > 1) {
      const ingredient = ingredients.find(i => i.id === ingredientId);
      let ingredientSpecificScalingRatio = isNotEmpty(ingredient) ? ingredient.specificScalingRatio : null;
      if (isNotEmpty(ingredientSpecificScalingRatio)) {
        ingredientRatio = 1 + (ingredientSpecificScalingRatio - 1) / (scaledYieldOrPortions / ratio) * (scaledYieldOrPortions - (scaledYieldOrPortions / ratio));
      }
    }
    return ingredientRatio;
  }

  function setIngredientsAppearances(ingredients, recipes) {
    for (let ingredient of ingredients) {
      // initialize appearances if needed
      if (!ingredient.hasOwnProperty("appearances")) ingredient["appearances"] = [];
    }
    for (let recipe of recipes) {
      if ((recipe.hasOwnProperty("appearances") && recipe.appearances.length > 0) || (recipe.hasOwnProperty("ratio") && recipe.ratio > 0)) {
        if (recipe.stoveTopRecipeComponents.length > 0) {
          addIngredientAppearances(ingredients, recipe.stoveTopRecipeComponents);
        } else {
          addIngredientAppearances(ingredients, recipe.instantPotRecipeComponents);
        }
      }
    }
  }

  function addIngredientAppearances(ingredients, components) {
    for (let rc of components) {
      if (rc.category === recipeComponentCategories.INGREDIENT) {
        const ingredient = ingredients.find(i => i.id === rc.ingredientId);
        if (isNotEmpty(ingredient)) {
          const prepVideo = ingredient.prepVideos.find(pv => pv.prepInstructionId === rc.prepInstructionId);
          const ia = {
            parentRecipeId: rc.parentRecipeId,
            volume: rc.volume,
            volumeUnitId: rc.volumeUnitId,
            weight: rc.weight,
            weightUnitId: rc.weightUnitId,
            prepInstruction: isNotEmpty(prepVideo) ? prepVideo.prepInstructionText : "",
            subNote: isEmpty(rc.groceryListSubNote) ? null : rc.groceryListSubNote,
          };
          ingredient.appearances.push(ia);
        }
      }
    }
  }

  function addAllChildRecipeNames(recipes, ingredients) {
    const childRecipes = recipes.batchings
      .slice()
      .concat(recipes.nightOfMeals.slice());
    for (let meal of recipes.meals) {
      addMissingRCProperties(childRecipes, meal.instantPotRecipeComponents, ingredients);
    }
    for (let nom of recipes.nightOfMeals) {
      addMissingRCProperties(childRecipes, nom.instantPotRecipeComponents, ingredients);
    }
    for (let batching of recipes.batchings) {
      if (batching.hasInstantPotAndStoveTopVersions) {
        if (batching.instantPotRecipeComponents.length > 0) {
          addMissingRCProperties(childRecipes, batching.instantPotRecipeComponents, ingredients);
        } else if (batching.stoveTopRecipeComponents.length > 0) {
          addMissingRCProperties(childRecipes, batching.stoveTopRecipeComponents, ingredients);
        }
      } else {
        addMissingRCProperties(childRecipes, batching.instantPotRecipeComponents, ingredients);
      }
    }
  }

  function addMissingRCProperties(childRecipes, components, ingredients) {
    for (let rc of components) {
      if (rc.category === "Recipe") {
        const childRecipe = childRecipes.find(r => r.id === rc.childRecipeId);
        if (childRecipe !== undefined) {
          rc["childRecipeName"] = childRecipe.name;
          rc["childRecipeType"] = childRecipe.type;
        }
      } else if (rc.category === "Ingredient") {
        const ingredient = ingredients.find(i => i.id === rc.ingredientId);
        if (ingredient !== undefined) {
          rc["ingredientPhoto"] = ingredient.photo;
          rc["ingredientNotesAndTips"] = ingredient.notesAndTips;
          rc["leafyGreenToWash"] = ingredient.leafyGreenToWash;
        }
      }
    }
  }

  function generateMeal(recipes, recipe, ingredients, mealPlanId, customizations) {
    const result = {
      id: recipe.id,
      type: recipe.type,
      displayOrder: recipe.displayOrder,
      name: recipe.name,
      description: recipe.description,
      defaultPortions: recipe.portions,
      portions: recipe.customPortions,
      photo: recipe.photo,
      allergens: [],
      characteristics: [],
      recipes: [],
      checkboxes: [],
      reviews: recipe.reviews,
      stars: recipe.stars,
      notes: recipe.notes,
    };

    const allChildRecipes = recipes.batchings.recipes.concat(recipes.nightOfMeals.recipes);
    const view = generateAssemblyRecipeView(recipeViewContexts.WIZARD_PLAN, recipe, allChildRecipes, ingredients, customizations, mealPlanId);
    result.allergens = view.allergens;
    
    if (recipe.customPortions > 0) {
      const childRecipeIds = [];
      ss.generateChildRecipeIds(recipe, allChildRecipes, childRecipeIds);
      // Add batching used in menu meal in batching order
      for (let batching of recipes.batchings.viewRecipes) {
        if (childRecipeIds.indexOf(batching.id) !== -1) {
          result.recipes.push(batching);
        }
      }
      for (let nom of recipes.nightOfMeals.viewRecipes) {
        if (childRecipeIds.indexOf(nom.id) !== -1) {
          result.recipes.push(nom);
        }
      }
      result.checkboxes = view.checkboxes;
    }

    // Add menu meal
    result.recipes.push(view);

    return result;
  }

  function generateRecipeView(context, recipe, recipes, ingredients, customizations, planId) {
    // TODO: change how we check which function should be called
    let view;
    if (recipe.type === recipeTypes.ASSEMBLY) {
      view = generateAssemblyRecipeView(context, recipe, recipes, ingredients, customizations, planId);
    } else {
      view = generateComponentRecipeView(context, recipe, recipes, ingredients, customizations, planId);
    }
    const childRecipeIds = [];
    ss.generateChildRecipeIds(recipe, recipes, childRecipeIds);
    view["childRecipeIds"] = [...childRecipeIds].reverse();
    return view;
  }

  function generateAssemblyRecipeView(context, recipe, recipes, ingredients, customizations, planId) {
    const view = {
      id: recipe.id,
      type: recipe.type,
      name: recipe.name,
      description: recipe.description,
      defaultPortions: recipe.hasOwnProperty("defaultPortions") ? recipe.defaultPortions : recipe.portions,
      portions: recipe.portions,
      customPortions: recipe.hasOwnProperty("customPortions") ? recipe.customPortions : recipe.portions,
      yield: rh.recipeYield(recipe),
      notesAndTips: recipe.notesAndTips,
      photo: recipe.photo,
      instructions: recipe.instructions,
      reheatingInstructions: recipe.reheatingInstructions,
      prepTime: recipe.prepTime,
      readyIn: recipe.readyIn,
      allergens: [],
      characteristics: [],
      checkboxes: [],
      components: [],
      reviews: recipe.reviews,
      stars: recipe.stars,
      notes: recipe.notes,
    }
    for (let rc of recipe.instantPotRecipeComponents) {
      view.components.push(ss.prepareRCData(rc, recipes, ingredients));
    }
    view.allergens = cache.recipeAllergens(recipe.characteristicIds);
    for (let c of recipe.characteristicIds) {
      view.characteristics.push(cache.characteristicName(c))
    }
    view.characteristics.sort((a, b) => a.localeCompare(b));
    recipeCheckboxes(context, recipe, view, customizations, planId);
    return view;
  }

  function generateComponentRecipeView(context, recipe, recipes, ingredients, customizations, planId) {
    const usedIn = [];
    if (recipe.hasOwnProperty("appearances")) {
      for (let appearance of recipe.appearances.filter(a => a.recipeId !== recipe.id)) {
        const parentRecipe = recipes.find(r => r.id === appearance.recipeId);
        // A recipe can appear multiple times in the same recipe if the parent recipe is assigned to multiple meal slot
        const existingUsedIn = usedIn.find(i => i.name === parentRecipe.name);
        if (isEmpty(existingUsedIn)) {
          // Create a new used in
          usedIn.push({id: parentRecipe.id, type: parentRecipe.type, name: parentRecipe.name, ratio: appearance.ratio});
        } else {
          // Increase the ratio for this parent recipe
          existingUsedIn.ratio += appearance.ratio;
        }
      }
    }
    // Calculate used yield for all appearances
    for (let usage of usedIn) {
      usage["usedYield"] = rh.appearanceYield(recipe, usage.ratio);
    }
    const view = {
      id: recipe.id,
      type: recipe.type,
      name: recipe.name,
      description: recipe.description,
      defaultPortions: recipe.hasOwnProperty("defaultPortions") ? recipe.defaultPortions : recipe.portions,
      portions: recipe.portions,
      customPortions: recipe.hasOwnProperty("customPortions") ? recipe.customPortions : recipe.portions,
      yield: rh.recipeYield(recipe),
      leftovers: rh.recipeLeftover(recipe),
      notesAndTips: recipe.notesAndTips,
      photo: recipe.photo,
      hasTwoVersions: recipe.hasInstantPotAndStoveTopVersions,
      isStoveTopVersion: recipe.stoveTopRecipeComponents.length > 0,
      instructions: recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopInstructions : recipe.instructions,
      reheatingInstructions: recipe.reheatingInstructions,
      prepTime: recipe.prepTime,
      readyIn: recipe.readyIn,
      usedIn: usedIn,
      components: [],
      tools: recipe.tools.sort((a, b) => a.name.localeCompare(b.name)),
      allergens: [],
      characteristics: [],
      checkboxes: [],
      reviews: recipe.reviews,
      stars: recipe.stars,
      notes: recipe.notes,
    };

    if (context === recipeViewContexts.STANDALONE_RECIPE) {
      view["usedYield"] = rh.recipeUsedYield(recipe);
    } else if (context === recipeViewContexts.MAGIC_MEAL_PLANNER) {
      let servings = recipe.portions * recipe.ratio;
      if (isNotEmpty(servings) && recipe.hasOwnProperty("additionalPortions")) {
        servings += recipe.additionalPortions;
      }
      view["servings"] = servings;
    } else if (context === recipeViewContexts.WIZARD_PLAN) {
      view["displayOrder"] = recipe.displayOrder;
    }

    const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
    for (let rc of components) {
      view.components.push(ss.prepareRCData(rc, recipes, ingredients));
    }
    view.allergens = cache.recipeAllergens(recipe.characteristicIds);
    for (let c of recipe.characteristicIds) {
      view.characteristics.push(cache.characteristicName(c))
    }
    view.characteristics.sort((a, b) => a.localeCompare(b));
    recipeCheckboxes(context, recipe, view, customizations, planId);
    return view;
  }

  function generateChildRecipeIds(recipe, recipes, childRecipeIds) {
    const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
    for (let component of components) {
      if (component.category === recipeComponentCategories.RECIPE) {
        const childRecipe = recipes.find(r => r.id === component.childRecipeId);
        childRecipeIds.push(childRecipe.id);
        generateChildRecipeIds(childRecipe, recipes, childRecipeIds);
      }
    }
  }

  function recipeCheckboxes(context, recipe, view, customizations, planId) {
    let recipeCheckboxes = [];
    if (context === recipeViewContexts.STANDALONE_RECIPE) {
      recipeCheckboxes = customizations.recipesCheckboxes.find(r => r.recipeId === recipe.id);
    } else if (context === recipeViewContexts.WIZARD_PLAN) {
      recipeCheckboxes = customizations.recipesCheckboxes.find(r => r.recipeId === recipe.id && r.mealPlanId === planId);
    } else if (context === recipeViewContexts.MAGIC_MEAL_PLANNER) {
      recipeCheckboxes = customizations.recipesCheckboxes.find(r => r.recipeId === recipe.id && r.weeklyMealPlanId === planId);
    }
    let checkboxes = [];
    // Only recipes and ingredients should have a checkbox
    const componentsLength = view.components.filter(c => [recipeComponentCategories.RECIPE, recipeComponentCategories.INGREDIENT].indexOf(c.category) !== -1).length;
    checkboxes = checkboxes.concat(Array(componentsLength).fill(false));
    // We inspect the instructions to detect checkboxes
    let instructions = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopInstructions : recipe.instructions;
    if (isEmpty(instructions)) {
      instructions = "";
    }
    const instructionsLength = (instructions.match(/\n\* |^\* /g) || []).length;
    checkboxes = checkboxes.concat(Array(instructionsLength).fill(false));
    if (recipeCheckboxes === undefined) {
      // Contact never checked a recipe checkbox for this menu meal
      view.checkboxes = checkboxes;
    } else {
      if (recipeCheckboxes.checkboxes.length === checkboxes.length) {
        // Contact have checked recipe checkboxes for this recipe and the recipe has not been edited by admins
        view.checkboxes = recipeCheckboxes.checkboxes;
      } else {
        // Contact have checked recipe checkboxes for this recipe but the recipe has been modified, reset the checkboxes
        view.checkboxes = checkboxes;
      }
    }
  }

  function prepareRCData(rc, recipes, ingredients) {
    let data = {
      id: rc.id,
      category: rc.category
    };
    if (rc.category === recipeComponentCategories.TEXT_ONLY) {
      data["text"] = rc.text;
    } else {
      if (rc.category === recipeComponentCategories.INGREDIENT) {
        const ingredient = ingredients.find(i => i.id === rc.ingredientId);
        const prepVideo = ingredient.prepVideos.find(pv => pv.prepInstructionId === rc.prepInstructionId);
        data["ingredientId"] = rc.ingredientId;
        data["name"] = isNotEmpty(ingredient) ? ingredient.name : "";
        data["invertedName"] = isNotEmpty(ingredient) ? ingredient.invertedName : "";
        data["photo"] = isNotEmpty(ingredient) ? ingredient.photo : "";
        data["ingredientNotesAndTips"] = isNotEmpty(ingredient) ? ingredient.notesAndTips : "";
        data["includeInPrep"] = rc.includeInPrep;
        data["prepInstruction"] = isNotEmpty(prepVideo) ? prepVideo.prepInstructionText : cache.prepInstructionText(rc.prepInstructionId);
        data["ingredientPrepVideoTitle"] = isNotEmpty(prepVideo) ? prepVideo.videoTitle : "";
        data["ingredientPrepVimeoId"] = isNotEmpty(prepVideo) ? prepVideo.vimeoId : "";
        data["displayQuantityInParentRecipe"] = rc.displayQuantityInParentRecipe;
      } else if (rc.category === recipeComponentCategories.RECIPE) {
        const childRecipe = recipes.find(r => r.id === rc.childRecipeId);
        data["name"] = isNotEmpty(childRecipe) ? childRecipe.name : "";
        data["recipeId"] = rc.childRecipeId;
        data["recipeType"] = rc.childRecipeType;
        data["reheatingInstructions"] = isNotEmpty(childRecipe) ? childRecipe.reheatingInstructions : "";
        data["displayQuantityInParentRecipe"] = rc.displayQuantityInParentRecipe;
        data["prepInstruction"] = cache.prepInstructionText(rc.prepInstructionId);
      }
      data["volume"] = prepareVolumeData(rc, data.name);
      data["weight"] = prepareWeightData(rc, data.name);
      data["additionalInstruction"] = isEmpty(rc.additionalInstruction) ? "" : rc.additionalInstruction;
    }
    return data;
  }

  function prepareVolumeData(rc, measuredItem) {
    let volume = "";
    if (isNotEmpty(rc.volume)) {
      volume = rh.makeMeasurementLookGood(rc.volume, rc.volumeUnitId, member.preferences.volumeSystem, measuredItem);
    }
    return volume;
  }

  function prepareWeightData(rc, measuredItem) {
    let weight = "";
    if (isNotEmpty(rc.weight)) {
      weight = rh.makeMeasurementLookGood(rc.weight, rc.weightUnitId, member.preferences.weightSystem, measuredItem);
    }
    return weight;
  }

  function generatePreps(context, recipes, ingredients, customizations) {
    const individualPreps = [];
    let keptRecipes = recipes;
    if (context === recipeViewContexts.WIZARD_PLAN) {
      keptRecipes = recipes.filter(r => r.usedIn.length > 0);
    }
    for (let recipe of keptRecipes) {
      for (let c of recipe.components) {
        if (c.includeInPrep) {
          individualPreps.push({
            recipeComponentId: c.id,
            recipeId: recipe.id,
            recipeName: recipe.name,
            ingredientId: c.ingredientId,
            ingredientName: c.name,
            ingredientPhoto: c.photo,
            ingredientNotesAndTips: c.ingredientNotesAndTips,
            volume: c.volume,
            weight: c.weight,
            prepInstruction: c.prepInstruction,
            ingredientPrepVideoTitle: c.ingredientPrepVideoTitle,
            ingredientPrepVimeoId: c.ingredientPrepVimeoId,
          });
        }
      }
    }
    individualPreps.sort((a, b) => a.ingredientName.localeCompare(b.ingredientName));
    const groupedPreps = groupByArray(individualPreps, "ingredientName");
    const preps = [];
    for (let p of groupedPreps) {
      let ingredientInstructions = {
        ingredientName: p.key,
        ingredientPhoto: p.values[0].ingredientPhoto,
        instructions: []
      };
      for (let instruction of p.values) {
        if (keptRecipes.find(r => r.id === instruction.recipeId) !== undefined) {
          const volume = instruction.volume;
          const weight = instruction.weight;
          const commaOrNot = ["juice", "zest", "brine", "aquafaba"].indexOf(instruction.prepInstruction) !== -1 ? " " : ", ";
          let text = "";
          if (volume === "" && weight === "") {
            text = instruction.prepInstruction;
          } else if (weight === "") {
            text = `${volume}${commaOrNot}${instruction.prepInstruction}`;
          } else if (volume === "") {
            text = `${weight}${commaOrNot}${instruction.prepInstruction}`;
          } else {
            text = `${volume}${commaOrNot}${instruction.prepInstruction} (${weight})`;
          }
          const prep = {
            recipeId: instruction.recipeId,
            recipeComponentId: instruction.recipeComponentId,
            ingredientId: instruction.ingredientId,
            text: text,
            additionalInstruction: isEmpty(instruction.additionalPrepInstruction) ? "" : instruction.additionalPrepInstruction.text,
            recipeName: instruction.recipeName,
            ingredientPrepVideoTitle: instruction.ingredientPrepVideoTitle,
            ingredientPrepVimeoId: instruction.ingredientPrepVimeoId
          };
          let checked = false;
          let customizedPrep;
          if (context === recipeViewContexts.WIZARD_PLAN) {
            customizedPrep = customizations.contactPreps.find(cp => cp.recipeComponentId === instruction.recipeComponentId);
          } else if (context === recipeViewContexts.MAGIC_MEAL_PLANNER) {
            customizedPrep = customizations.preps.find(p => p.recipeId === instruction.recipeId && p.ingredientId === instruction.ingredientId);
          }
          if (isNotEmpty(customizedPrep)) {
            checked = customizedPrep.checked;
          }
          prep["checked"] = checked;
          ingredientInstructions.instructions.push(prep);
        }
      }
      preps.push(ingredientInstructions);
    }
    return preps;
  }

  function ingredientsQuantities(ingredients) {
    for (let ingredient of ingredients) {
      let totalWeight = 0;
      let weightToBuy = 0;
      let weightUnitId = null;
      let marketContainers = 0;
      let marketContainerUnitId = null;
      let totalVolume = 0;
      let volumeUnitId = null;
      if (ingredient.appearances.length > 0) {
        // weight and market containers
        if (ingredient.noMarketContainerFormat || isEmpty(ingredient.marketContainerFormat)) {
          if (isNotEmpty(ingredient.standardWeightPreppedUnitId)) {
            weightUnitId = ingredient.standardWeightPreppedUnitId;
            for (let ia of ingredient.appearances) {
              totalWeight += ingredientAppearanceStandardWeight(ingredient, ia);
            }
            if (isNotEmpty(ingredient.standardWeightUnprepped) && isNotEmpty(ingredient.standardWeightPrepped)) {
              if (ingredient.standardWeightUnprepped === ingredient.standardWeightPrepped) {
                weightToBuy = totalWeight;
              } else {
                weightToBuy = totalWeight * ingredient.standardWeightUnprepped / ingredient.standardWeightPrepped;
              }
            }
          } else {
            // i.e. Pasta, we have a weight but nothing on the ingredient to standardize
            // we can only sum the normalized quantities
            const iaNoWeight = ingredient.appearances.find(ia => isEmpty(ia.weight));
            const iaNoWeightUnitId = ingredient.appearances.find(ia => isEmpty(ia.weightUnitId));
            if (iaNoWeight === undefined && iaNoWeightUnitId === undefined) {
              const chosenUnitId = ingredient.appearances[0].weightUnitId;
              for (let ia of ingredient.appearances) {
                if (ia.weightUnitId === chosenUnitId) {
                  totalWeight += ia.weight;
                } else {
                  totalWeight += rh.convertValue(ia.weight, ia.weightUnitId, chosenUnitId);
                }
              }
              weightToBuy = totalWeight;
              weightUnitId = chosenUnitId;
            }
          }
        } else if (isNotEmpty(ingredient.standardWeightPreppedUnitId)) {
          marketContainers = calculateMarketContainers(ingredient);
          marketContainerUnitId = ingredient.marketContainerFormatUnitId;
          totalWeight = marketContainers * ingredient.standardWeightPrepped / ingredient.marketContainerFormat;
          weightToBuy = marketContainers * ingredient.standardWeightUnprepped / ingredient.marketContainerFormat;
          weightUnitId = ingredient.standardWeightPreppedUnitId;
        } else {
          marketContainers = calculateMarketContainers(ingredient);
          marketContainerUnitId = ingredient.marketContainerFormatUnitId;
        }

        // volume
        if (isNotEmpty(ingredient.standardVolumePreppedUnitId)) {
          totalVolume = calculateTotalVolume(ingredient);
          volumeUnitId = ingredient.standardVolumePreppedUnitId;
        } else {
          const iaNoVolume = ingredient.appearances.find(ia => isEmpty(ia.volume));
          const iaNoVolumeUnitId = ingredient.appearances.find(ia => isEmpty(ia.volumeUnitId));
          if (iaNoVolume === undefined && iaNoVolumeUnitId === undefined) {
            // just sum volumes
            const chosenUnitId = ingredient.appearances[0].volumeUnitId;
            for (let ia of ingredient.appearances) {
              if (ia.volumeUnitId === chosenUnitId) {
                totalVolume += ia.volume;
              } else {
                totalVolume += rh.convertValue(ia.volume, ia.volumeUnitId, chosenUnitId);
              }
            }
            volumeUnitId = chosenUnitId;
          }
        }

        // fluid oz where we can
        if (
          volumeUnitId !== null
          && isNotEmpty(ingredient.groceryListVolumeMeasurementUnitId)
          && volumeUnitId !== ingredient.groceryListVolumeMeasurementUnitId
          && cache.measurementUnitName(ingredient.groceryListVolumeMeasurementUnitId) === "fl oz"
        ) {
          if (cache.measurementUnitName(volumeUnitId) === "tablespoon" && totalVolume > 3) {
            totalVolume = rh.convertValue(totalVolume, volumeUnitId, ingredient.groceryListVolumeMeasurementUnitId);
            volumeUnitId = ingredient.groceryListVolumeMeasurementUnitId
          } else {
            const tablespoonVolumeUnitId = cache.measurementUnitId("tablespoon");
            const tablespoonVolume = rh.convertValue(totalVolume, volumeUnitId, tablespoonVolumeUnitId);
            if (tablespoonVolume > 3) {
              totalVolume = rh.convertValue(tablespoonVolume, tablespoonVolumeUnitId, ingredient.groceryListVolumeMeasurementUnitId);
              volumeUnitId = ingredient.groceryListVolumeMeasurementUnitId
            }
          }
        }
      }
      marketContainers = Math.ceil(marketContainers);
      ingredient["totalWeight"] = totalWeight;
      ingredient["weightToBuy"] = weightToBuy;
      ingredient["weightUnitId"] = weightUnitId;
      ingredient["marketContainers"] = marketContainers;
      ingredient["marketContainerUnitId"] = marketContainerUnitId;
      ingredient["totalVolume"] = totalVolume;
      ingredient["volumeUnitId"] = volumeUnitId;
    }
  }

  function ingredientAppearanceStandardWeight(ingredient, appearance) {
    let weight = 0;
    if (
      isNotEmpty(appearance.volume)
      && isNotEmpty(appearance.volumeUnitId)
      && isNotEmpty(ingredient.marketContainerFormatUnitId)
      && appearance.volumeUnitId === ingredient.marketContainerFormatUnitId
      && isNotEmpty(ingredient.marketContainerFormat)
      && isNotEmpty(ingredient.standardWeightUnprepped)
    ) {
      weight += appearance.volume * ingredient.standardWeightUnprepped / ingredient.marketContainerFormat;
    } else if (isNotEmpty(appearance.weightUnitId) && appearance.weightUnitId === ingredient.standardWeightPreppedUnitId) {
      weight += appearance.weight;
    } else if (isNotEmpty(appearance.weightUnitId)) {
      weight += rh.convertValue(appearance.weight, appearance.weightUnitId, ingredient.standardWeightPreppedUnitId);
    } else if (isNotEmpty(appearance.volume) && isNotEmpty(appearance.volumeUnitId)) {
      if (isNotEmpty(ingredient.standardVolumePrepped) && isNotEmpty(ingredient.standardVolumePreppedUnitId) && isNotEmpty(ingredient.standardWeightPrepped)) {
        if (appearance.volumeUnitId === ingredient.standardVolumePreppedUnitId) {
          weight += appearance.volume * ingredient.standardWeightPrepped / ingredient.standardVolumePrepped;
        } else {
          const convertedVolume = rh.convertValue(appearance.volume, appearance.volumeUnitId, ingredient.standardVolumePreppedUnitId);
          weight += convertedVolume * ingredient.standardWeightPrepped / ingredient.standardVolumePrepped;
        }
      }
    }
    return weight;
  }

  function calculateMarketContainers(ingredient) {
    let wholeContainers = 0;
    let residualContainers = 0;
    let wetContainers = 0;
    let weightContainers = 0;
    let volumeContainers = 0;
    let marketContainers = 0;
    for (let ia of ingredient.appearances) {
      if (ia.prepInstruction === "zest") {
        residualContainers += calculateZestContainers(ingredient, ia);
      } else if (ingredient.category === "Packaged" && ["juice", "brine", "aquafaba"].indexOf(ia.prepInstruction) !== -1) {
        // taking juice, brine or aquafaba count as residual as it doesn't take from the produce
        residualContainers += calculateWetContainers(ingredient, ia);
      } else if (ia.prepInstruction === "juice") {
        // here, taking juice affects the produce quantity
        wetContainers += calculateWetContainers(ingredient, ia);
      } else if (
        isNotEmpty(ia.volume)
        && isNotEmpty(ia.volumeUnitId)
        && isNotEmpty(ingredient.marketContainerFormat)
        && isNotEmpty(ingredient.marketContainerFormatUnitId)
        && ia.volumeUnitId === ingredient.marketContainerFormatUnitId
      ) {
        // i.e. 1 medium avocado, we can simply add to dryMarketContainers
        wholeContainers += ia.volume;
      } else if (isNotEmpty(ia.weight) && ia.weight > 0) {
        weightContainers += calculateWeightContainers(ingredient, ia);
      } else if (isNotEmpty(ia.volume) && ia.volume > 0) {
        volumeContainers += calculateVolumeContainers(ingredient, ia);
      }
    }
    const produceContainers = wholeContainers + wetContainers + weightContainers + volumeContainers;
    if (residualContainers > produceContainers) {
      marketContainers = residualContainers;
    } else {
      marketContainers = produceContainers;
    }
    return marketContainers;
  }

  function calculateZestContainers(ingredient, appearance) {
    let result = 0;
    if (
      isNotEmpty(ingredient.marketContainerFormat)
      && isNotEmpty(ingredient.standardZestVolume)
      && isNotEmpty(ingredient.standardZestVolumeUnitId)
      && isNotEmpty(appearance.volume)
      && isNotEmpty(appearance.volumeUnitId)
    ) {
      if (ingredient.standardZestVolumeUnitId === appearance.volumeUnitId) {
        result = appearance.volume * ingredient.marketContainerFormat / ingredient.standardZestVolume;
      } else {
        const convertedVolume = rh.convertValue(appearance.volume, appearance.volumeUnitId, ingredient.standardZestVolumeUnitId);
        result = convertedVolume * ingredient.marketContainerFormat / ingredient.standardZestVolume;
      }
    }
    return result;
  }

  function calculateWetContainers(ingredient, appearance) {
    let result = 0;
    if (
      isNotEmpty(ingredient.marketContainerFormat)
      && isNotEmpty(ingredient.standardJuiceVolume)
      && isNotEmpty(ingredient.standardJuiceVolumeUnitId)
      && isNotEmpty(appearance.volume)
      && isNotEmpty(appearance.volumeUnitId)
    ) {
      if (ingredient.standardJuiceVolumeUnitId === appearance.volumeUnitId) {
        result = appearance.volume * ingredient.marketContainerFormat / ingredient.standardJuiceVolume;
      } else {
        const convertedVolume = rh.convertValue(appearance.volume, appearance.volumeUnitId, ingredient.standardJuiceVolumeUnitId);
        result = convertedVolume * ingredient.marketContainerFormat / ingredient.standardJuiceVolume;
      }
    }
    return result;
  }

  function calculateWeightContainers(ingredient, appearance) {
    let result = 0;
    if (
      isNotEmpty(ingredient.marketContainerFormat)
      && isNotEmpty(ingredient.standardWeightPrepped)
      && isNotEmpty(ingredient.standardWeightPreppedUnitId)
      && isNotEmpty(appearance.weight)
      && isNotEmpty(appearance.weightUnitId)
    ) {
      if (ingredient.standardWeightPreppedUnitId === appearance.weightUnitId) {
        result = appearance.weight * ingredient.marketContainerFormat / ingredient.standardWeightPrepped;
      } else {
        const convertedWeight = rh.convertValue(appearance.weight, appearance.weightUnitId, ingredient.standardWeightPreppedUnitId);
        result = convertedWeight * ingredient.marketContainerFormat / ingredient.standardWeightPrepped;
      }
    }
    return result;
  }

  function calculateVolumeContainers(ingredient, appearance) {
    let result = 0;
    if (
      isNotEmpty(ingredient.marketContainerFormat)
      && isNotEmpty(ingredient.standardVolumePrepped)
      && isNotEmpty(ingredient.standardVolumePreppedUnitId)
      && isNotEmpty(appearance.volume)
      && isNotEmpty(appearance.volumeUnitId)
    ) {
      if (ingredient.standardVolumePreppedUnitId === appearance.volumeUnitId) {
        result = appearance.volume * ingredient.marketContainerFormat / ingredient.standardVolumePrepped;
      } else {
        const convertedVolume = rh.convertValue(appearance.volume, appearance.volumeUnitId, ingredient.standardVolumePreppedUnitId);
        result = convertedVolume * ingredient.marketContainerFormat / ingredient.standardVolumePrepped;
      }
    }
    return result;
  }

  function calculateTotalVolume(ingredient) {
    let totalVolume = 0;
    for (let ia of ingredient.appearances) {
      if (isNotEmpty(ia.volume)) {
        if (isNotEmpty(ia.volumeUnitId) && ia.volumeUnitId === ingredient.standardVolumePreppedUnitId) {
          totalVolume += ia.volume;
        } else {
          totalVolume += rh.convertValue(ia.volume, ia.volumeUnitId, ingredient.standardVolumePreppedUnitId);
        }
      }
    }
    return totalVolume;
  }

  function generateGroceryList(ingredientData, recipes, customizedIngredients) {
    const ingredientsToInclude = ingredientData.ingredients.filter(i => !i.excludeFromGroceryList);
    for (let i of ingredientsToInclude) {
      const customizedIngredient = customizedIngredients.find(ci => ci.ingredientId === i.id);
      if (isNotEmpty(customizedIngredient) && customizedIngredient.checked) {
        addCategoryIngredient(ingredientData.views.itemsYouHave, i, recipes);
      } else if (i.category === "Produce") {
        addCategoryIngredient(ingredientData.views.produce, i, recipes);
      } else if (i.category === "Bulk") {
        addCategoryIngredient(ingredientData.views.bulk, i, recipes);
      } else if (i.category === "Spices and Herbs") {
        addCategoryIngredient(ingredientData.views.spicesHerbs, i, recipes);
      } else if (i.category === "Packaged") {
        addCategoryIngredient(ingredientData.views.packaged, i, recipes);
      }
    }
  }

  function addCategoryIngredient(category, ingredient, recipes) {
    if (ingredient.appearances.length > 0) {
      const distinctSubNotes = ingredient.appearances.map(a => a.subNote).filter((value, index, self) => self.indexOf(value) === index);
      const showAppearancesSubNotes = distinctSubNotes.length > 1;
      const ingredientSubNote = distinctSubNotes.length === 1 ? distinctSubNotes[0] : "";
      const item = {
        id: ingredient.id,
        name: ingredient.name,
        photo: ingredient.photo,
        notesAndTips: ingredient.notesAndTips,
        total: ingredientTotalText(ingredient),
        subNote: ingredientSubNote,
        showAppearancesSubNotes: showAppearancesSubNotes,
        appearances: ingredientAppearancesTexts(recipes, ingredient),
      };
      category.push(item);
    }
  }

  function ingredientTotalText(ingredient) {
    const mc = isEmpty(ingredient.marketContainers) || ingredient.marketContainers === 0 ? "" : rh.makeMeasurementLookGood(ingredient.marketContainers, ingredient.marketContainerUnitId, member.preferences.volumeSystem, "");
    const totalVolume = isEmpty(ingredient.totalVolume) || ingredient.totalVolume === 0 ? "" : rh.makeMeasurementLookGood(ingredient.totalVolume, ingredient.volumeUnitId, member.preferences.volumeSystem, "");
    const volume = mc === "" ? totalVolume : mc;
    const weight = isEmpty(ingredient.weightToBuy) || ingredient.weightToBuy === 0 ? "" : rh.makeMeasurementLookGood(ingredient.weightToBuy, ingredient.weightUnitId, member.preferences.weightSystem, "");
    let result = "";
    if (isNotEmpty(volume) && isNotEmpty(weight)) {
      result = volume + " (" + weight + ")";
    } else if (isNotEmpty(volume)) {
      result = volume;
    } else if (isNotEmpty(weight)) {
      result = weight;
    }
    return result;
  }

  function ingredientAppearancesTexts(recipes, ingredient) {
    const appearances = [];
    for (let a of ingredient.appearances) {
      const parentRecipe = recipes.find(r => r.id === a.parentRecipeId);
      appearances.push({
        parentRecipeId: a.parentRecipeId,
        parentRecipeName: parentRecipe.name,
        quantity: ingredientAppearanceQuantityText(a),
        subNote: a.subNote,
        usedIn: "",
        usedOn: "",
      });
    }
    return appearances;
  }

  function ingredientAppearanceQuantityText(appearance) {
    let result = "";
    let prepInstruction = "";
    if (["juice", "brine", "zest", "aquafaba"].indexOf(appearance.prepInstruction) !== -1) {
      prepInstruction = ` ${appearance.prepInstruction}`;
    }
    const volume = isEmpty(appearance.volume) ? "" : rh.makeMeasurementLookGood(appearance.volume, appearance.volumeUnitId, member.preferences.volumeSystem, "");
    const weight = isEmpty(appearance.weight) ? "" : rh.makeMeasurementLookGood(appearance.weight, appearance.weightUnitId, member.preferences.weightSystem, "");
    if (volume !== "" && weight !== "") {
      result = volume + prepInstruction + " (" + weight + ")";
    } else if (volume !== "") {
      result = volume + prepInstruction;
    } else if (weight !== "") {
      result = weight;
    }
    return result;
  }

  function generateNutritionFacts(recipes, ingredients, ingredientNutrients, nutritionFacts) {
    // recipe id 389 gives NAN for amounts
    for (let recipe of recipes) {
      const components = recipe.stoveTopRecipeComponents.length > 0 ? recipe.stoveTopRecipeComponents : recipe.instantPotRecipeComponents;
      for (let component of components.filter(c => c.category === recipeComponentCategories.INGREDIENT)) {
        const nutrients = ingredientNutrients.filter(n => n.ingredientId === component.ingredientId);
        const ingredient = ingredients.find(i => i.id === component.ingredientId);
        let amountRatio = null;
        if (isNotEmpty(component.weight) && isNotEmpty(component.weightUnitId) && component.weightUnitId === 12) {
          // We are already in grams, use weight to calculate nutrition facts
          amountRatio = component.weight / 100;
        } else if (isNotEmpty(component.volume) && isNotEmpty(component.volumeUnitId)) {
          let volume = null;
          if (component.volumeUnitId === ingredient.volumePer100GramsUnitId) {
            // We have the same volume unit no need for conversion
            volume = component.volume;
          } else if (isNotEmpty(ingredient.volumePer100GramsUnitId)) {
            // We don't have the same volume unit, try to convert
            volume = rh.convertValue(component.volume, component.volumeUnitId, ingredient.volumePer100GramsUnitId);
          }
          if (isNotEmpty(volume) && !Number.isNaN(volume)) {
            amountRatio = volume / ingredient.volumePer100Grams;
          } else {
            console.log(`Could not get amount ratio for ingredient ${ingredient.name} of recipe ${recipe.name}`);
          }
        }
        if (isNotEmpty(amountRatio)) {
          for (let nutrient of nutrients) {
            // Find the recipe facts
            const recipeNutritionFacts = nutritionFacts.find(nf => nf.recipeId === recipe.id);
            if (isNotEmpty(recipeNutritionFacts)) {
              // Find the nutrient
              const recipeNutrient = recipeNutritionFacts.nutrients.find(n => n.name === nutrient.name);
              if (isNotEmpty(recipeNutrient)) {
                // Update amount
                recipeNutrient.amount += nutrient.amount * amountRatio;
              } else {
                // Add new nutrient
                recipeNutritionFacts.nutrients.push({
                  name: nutrient.name,
                  amount: nutrient.amount * amountRatio,
                  unit: nutrient.unitName
                })
              }
            } else {
              // Add new recipe with its new nutrient
              nutritionFacts.push({
                recipeId: recipe.id,
                nutrients: [{name: nutrient.name, amount: nutrient.amount * amountRatio, unit: nutrient.unitName}]
              });
            }
          }
        }
      }
    }
  }


  const ss = {
    addAllChildRecipeNames: addAllChildRecipeNames,
    addChildRecipeAppearances: addChildRecipeAppearances,
    addMissingProperties: addMissingProperties,
    adjustMinimumYield: adjustMinimumYield,
    customizeRecipesComponents: customizeRecipesComponents,
    deleteChildRecipes: deleteChildRecipes,
    generateChildRecipeIds: generateChildRecipeIds,
    generateGroceryList: generateGroceryList,
    generatePreps: generatePreps,
    generateNutritionFacts: generateNutritionFacts,
    generateMeal: generateMeal,
    generateRecipeView: generateRecipeView,
    ingredientsQuantities: ingredientsQuantities,
    prepareRCData: prepareRCData,
    scale: scale,
    scaleMeals: scaleMeals,
    scaleChildRecipes: scaleChildRecipes,
    setIngredientsAppearances: setIngredientsAppearances,
    setMealsRatio: setMealsRatio,
  }

  return {ss};
}
