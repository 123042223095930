import "../MealPreview.css";
import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import Constants from "../../../services/Constants";
import {Link} from "react-router-dom";
import StashButton from "../../Search/components/StashButton";

export default function PublicMealPreview(props) {
  const {documentTypes, mealTypes} = Constants();
  const {assetUrl, capitalizeFirstLetter, isNotEmpty, makeUrlLookGood} = Helpers();

  const stashItem = props.stashStore.items.filter(i => i.documentType === documentTypes.RECIPE).find(i => i.documentId === props.meal.recipeId);

  const saveButton = (
    <StashButton
      documentId={props.meal.recipeId}
      documentType={documentTypes.RECIPE}
      smallButton={true}
      margin=" me-1"
      title="Save"
      active={stashItem === undefined ? false : stashItem.saved}
      activeClassName="fa fa-folder-bookmark"
      inactiveClassName="far fa-folder-bookmark"
      toggle={props.stashStore.toggleSaved}
    />
  );

  const loveButton = (
    <StashButton
      documentId={props.meal.recipeId}
      documentType={documentTypes.RECIPE}
      smallButton={true}
      margin=" me-1"
      title="Love"
      active={stashItem === undefined ? false : stashItem.loved}
      activeClassName="fa fa-heart"
      inactiveClassName="far fa-heart"
      toggle={props.stashStore.toggleLove}
    />
  );

  const title = (
    <h2 className="h2-recipe public-plan-recipe-link">
      <Link to={`/recipes/recipe-vault/${makeUrlLookGood(props.meal.recipeId, props.meal.name)}`}>{props.meal.name}</Link>
    </h2>
  );

  const imageStyle = {objectFit: "cover", width: "100%", maxHeight: "200px", minWidth: "220px", borderRadius: ".35rem"}
  const image = (
    <img
      src={isNotEmpty(props.meal.photo) ? assetUrl(props.meal.photo) : `${process.env.PUBLIC_URL}/images/recipe-placeholder-picture.jpg`}
      alt={props.meal.name}
      style={imageStyle}
    />
  );

  return props.listView ? (
    <div className="list-group-item pt-0 pb-1 mb-2">
      <div className="row bg-light meal-list-actions">
        <div className="col-auto bg-light">
          {saveButton}{loveButton}
        </div>
      </div>
      <div className="row">
        <div className="col py-1">{title}</div>
      </div>
    </div>
  ) : (
    <div>
      {/* Print only */}
      <div className="d-none d-print-block mb-2 row">
        {image}
      </div>
      <div className="col mb-4 d-print-none">
        <div className="card meal-preview shadow-sm border magic-meal-plan-card">
          <div className="card-body p-0">
            <div className="gx-0 px-2 rounded-top">
              <div className="row">
                {/* left signet */}
                <div className="col-auto">
                  <div className="row bg-light">
                    {/* save button */}
                    {saveButton}
                  </div>
                  <div className="row bg-light bottom-rounded">
                    {/* love button */}
                    {loveButton}
                  </div>
                </div>
                {/* right content */}
                <div className="col py-2 pe-2 pe-sm-3">
                  <div className="row">
                    <div className="col">
                      {/* meal type */}
                      <span className="text-muted">
                        <small>{props.mealType === mealTypes.EXTRA ? null : capitalizeFirstLetter(props.mealType)}</small>
                      </span>
                      {/* recipe name */}
                      {title}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      {/* photo */}
                      {image}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

PublicMealPreview.propTypes = {
  stashStore: PropTypes.object,
  listView: PropTypes.bool,
  meal: PropTypes.object,
  mealType: PropTypes.string,
}