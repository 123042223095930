import { useEffect, useState } from "react";
import axios from "axios";
import Helpers from "../Helpers";

export default function useMenu(token, member) {
  const { isNotEmpty } = Helpers();
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (isNotEmpty(token)) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        url: `${process.env.REACT_APP_API}/cms/menu-items`,
      })
        .then((res) => {
          let filteredItems = [...res.data];
          if (member.hasMP) {
            filteredItems = [...res.data.filter((i) => i.fullAccessMember === true)];
          } else if (member.hasRV) {
            filteredItems = [...res.data.filter((i) => i.recipeVaultMember === true)];
          }
          setItems(filteredItems);
        })
        .catch(() => {
          // we do nothing
        });
    }
  }, [token, member]);

  return { items };
}
