import "../RecipePlanner.css";
import React from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import Constants from "../../../services/Constants";
import ReviewStars from "../../components/ReviewStars";
import Checkcircle from "../../components/Checkcircle";
import PortionSelector from "../../components/PortionSelector";
import StashButton from "../../Search/components/StashButton";
import Allergens from "../../Recipe/components/Allergens";

export default function MealPreview(props) {
  const {documentTypes, recipeTypes} = Constants();
  const {assetUrl} = Helpers();

  function ChildRecipe(props) {
    let result;
    if (props.recipe.checkboxes.filter(i => !i).length === 0) {
      result = <li><span className="text-muted"><i className="fas fa-check" aria-hidden="true"/> {props.recipe.name}</span></li>;
    } else if (props.recipe.type === recipeTypes.COMPONENT) {
      result = <li>{props.recipe.name}</li>
    } else {
      result = <li>{props.recipe.name}</li>
    }
    return result;
  }

  const batchingRecipes =  props.recipe.recipes.filter(r => r.id !== props.recipe.id && props.batchings.find(b => b.id === r.id) !== undefined).map(r => (
    <ChildRecipe key={r.id} recipe={r} />
  ));

  const mealNightRecipes = props.recipe.recipes.filter(r => r.id !== props.recipe.id && props.batchings.find(b => b.id === r.id) === undefined).map(r => (
    <ChildRecipe key={r.id} recipe={r} />
  ));

  const stashItem = props.stashStore.items.filter(i => i.documentType === documentTypes.RECIPE).find(i => i.documentId === props.recipe.id);

  // TODO: kinda dirty to have recipe and props.recipe, find a way to simplify this.
  const recipe = props.recipe.recipes.find(r => r.id === props.recipe.id);

  const checked = props.recipe.checkboxes.filter(x => !x).length === 0;
  const recipeName = props.recipe.portions > 0 ? <span className="cursor-pointer h2-recipe h2-recipe-hover" onClick={props.show}>{props.recipe.name}</span> : <span className="h2-inactive-recipe">{props.recipe.name}</span>;
  const title = props.recipe.portions > 0 ? <div className="mt-2 mb-1"><Checkcircle checked={checked} toggle={() => toggleCheckboxes(!checked)}/> {recipeName}</div> : <div className="mt-2 mb-1">{recipeName}</div>;
  const image = props.recipe.portions > 0 ? (
    <img
      className="pb-2 pb-md-0 cursor-pointer recipe-featured-image rounded meal-preview-image"
      src={assetUrl(props.recipe.photo)}
      alt=""
      onClick={props.show}
    />
  ) : (
    <img
      className="pb-2 pb-md-0 recipe-featured-image rounded meal-preview-image"
      src={assetUrl(props.recipe.photo)}
      alt=""
    />
  );

  /**
   * Toggle all checkboxes of a recipe
   *
   * The new state checked/unchecked of the recipe is used to check/uncheck all checkboxes of the recipe. The new array
   * is sent to a parent function which takes care of saving data and refreshing the state.
   *
   * @param {boolean} checked - new state of the recipe checkbox
   */
  function toggleCheckboxes(checked) {
    const checkboxes = Array(props.recipe.checkboxes.length).fill(checked);
    props.updateCheckboxes(props.recipe.id, checkboxes); // TODO: pass selected portions, actual yield volume with leftovers, actual yield unit id, stoveTopOrInstantPot
  }

  function changePortions(portions) {
    props.changePortions(props.recipe.id, portions);
  }

  const allergens = <Allergens allergens={props.recipe.allergens} />

  return (
    <div className="meals-list">
      <div className="p-3 py-md-3 mb-4 bg-white recipe-preview shadow-sm" tabIndex={1}>
        <div className="row">
          <div className="col-sm-12 col-md-5" style={{ position: "relative" }}>
            <div className="fw-bold recipe-day"><span>{props.day}</span></div>
            {image}
          </div>
          <div className="col-sm-12 col-md-7 pe-md-1 my-auto">
            <div className="my-md-2 mb-md-1">
              <ReviewStars
                stars={props.recipe.stars}
                cta={() => props.modals.showReviews({
                  documentId: recipe.id,
                  documentType: documentTypes.RECIPE,
                  reviewsFor: `${recipe.name}`,
                })}
              />
            </div>
            {title}
            {allergens === null ? null : <p className="mb-1">{allergens}</p>}
            <div>
              <button
                className="btn btn-light me-1"
                type="button"
                title="Add to Meal Planner"
                onClick={() => props.modals.showAddToMealPlan({
                  documentId: props.recipe.id,
                  documentType: documentTypes.RECIPE,
                  name: props.recipe.name
                })}
              >
                <span className="fa fa-plus"/>
              </button>
              <StashButton
                documentId={props.recipe.id}
                documentType={documentTypes.RECIPE}
                smallButton={false}
                margin=" me-1"
                title="Save"
                active={stashItem === undefined ? false : stashItem.saved}
                activeClassName="fa fa-folder-bookmark"
                inactiveClassName="far fa-folder-bookmark"
                toggle={props.stashStore.toggleSaved}
              />
              <StashButton
                documentId={props.recipe.id}
                documentType={documentTypes.RECIPE}
                smallButton={false}
                margin=" me-1"
                title="Love"
                active={stashItem === undefined ? false : stashItem.loved}
                activeClassName="fa fa-heart"
                inactiveClassName="far fa-heart"
                toggle={props.stashStore.toggleLove}
              />
            </div>
            <div className="d-flex pt-2">
              <div className="pe-2">
                <PortionSelector
                  allowZero={true}
                  specialScaling={props.specialScalingIds.indexOf(props.recipe.id) !== -1}
                  defaultPortions={props.recipe.defaultPortions}
                  portions={props.recipe.portions}
                  change={changePortions}
                />
              </div>
              <div className="pe-2">
                {props.recipe.portions > 0 ? (
                  <button className="btn btn-primary" type="button" onClick={props.show}>
                    <i className="far fa-chevron-circle-right"></i> View Recipe
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          {props.recipe.displayOrder < 6 ? (
            <div className="row pt-2 pt-md-3 px-2 px-md-3">
              {batchingRecipes.length > 0 ? (
                <div className={mealNightRecipes.length > 0 ? "col-12 col-md-6" : "col-12"}>
                  <ul className="nobullet p-0 m-0">
                    <p className="mt-2 mt-md-0 mb-1 child-recipes"><strong>Batching Recipes</strong></p>
                    {batchingRecipes}
                  </ul>
                </div>
              ) : null}
              {mealNightRecipes.length > 0 ? (
                <div className={batchingRecipes.length > 0 ? "col-12 col-md-6 pt-3 pt-md-0" : "col-12"}>
                  <ul className="nobullet p-0 m-0">
                    <p className="mb-1 child-recipes"><strong>Meal Night Recipes</strong></p>
                    {mealNightRecipes}
                  </ul>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

MealPreview.propTypes = {
  stashStore: PropTypes.object,
  modals: PropTypes.object,
  recipe: PropTypes.object,
  batchings: PropTypes.array,
  day: PropTypes.number,
  specialScalingIds: PropTypes.array,
  show: PropTypes.func,
  changePortions: PropTypes.func,
  updateCheckboxes: PropTypes.func,
}