import React from "react";

export default function ReviewStars({ stars, cta }) {
  function render() {
    return (
      <div className="badge bg-white text-dark position-absolute m-0 cursor-pointer" style={{ bottom: "5px", left: "5px", filter: "drop-shadow(0 0 4px #6c757d)" }} onClick={cta}>
        {stars > 0 ? (
          <>
            <i className="fa-solid fa-star" style={{ color: "#ff4400" }} /> <span style={{ fontWeight: 400 }}>{stars}</span>
          </>
        ) : (
          <>
            <i className="far fa-star" style={{ color: "#ff4400" }} /> <span style={{ fontWeight: 400 }}>0</span>
          </>
        )}
      </div>
    );
  }

  return render();
}
