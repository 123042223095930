import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Collapse} from "bootstrap";
import Constants from "../../../services/Constants";
import AllergenCheckbox from "./AllergenCheckbox";
import Helpers from "../../../services/Helpers";

export default function Preferences(props) {
  const {cookingMethods, measurementSystems} = Constants();
  const {isEmpty} = Helpers();
  const [show, setShow] = useState(true);
  const [portions, setPortions] = useState(4);
  const [stoveTopOrInstantPot, setStoveTopOrInstantPot] = useState(cookingMethods.INSTANT_POT);
  const [volumeSystem, setVolumeSystem] = useState(measurementSystems.US);
  const [weightSystem, setWeightSystem] = useState(measurementSystems.METRIC);
  const [showNutritionFacts, setShowNutritionFacts] = useState(false);
  const [mealPlannerListView, setMealPlannerListView] = useState(false);
  const [searchListViewOnMobile, setSearchListViewOnMobile] = useState(true);
  const [allergens, setAllergens] = useState([]);
  const [excludePicturesFromPDF, setExcludePicturesFromPDF] = useState(false);
  const [excludeGroceryListItemsYouHaveFromPDF, setExcludeGroceryListItemsYouHaveFromPDF] = useState(false);
  const [excludePrepInstructionsFromPDF, setExcludePrepInstructionsFromPDF] = useState(false);
  const [excludeBatchingFromPDF, setExcludeBatchingFromPDF] = useState(false);
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setPortions(props.memberStore.member.preferences.portions);
    setStoveTopOrInstantPot(props.memberStore.member.preferences.stoveTopOrInstantPot);
    setVolumeSystem(props.memberStore.member.preferences.volumeSystem);
    setWeightSystem(props.memberStore.member.preferences.weightSystem);
    setShowNutritionFacts(props.memberStore.member.preferences.showNutritionFacts);
    setMealPlannerListView(props.memberStore.member.preferences.mealPlannerListView);
    setSearchListViewOnMobile(props.memberStore.member.preferences.searchListViewOnMobile);
    setAllergens(props.memberStore.member.preferences.allergens);
    setExcludePicturesFromPDF(props.memberStore.member.preferences.excludePicturesFromPDF);
    setExcludeGroceryListItemsYouHaveFromPDF(props.memberStore.member.preferences.excludeGroceryListItemsYouHaveFromPDF);
    setExcludePrepInstructionsFromPDF(props.memberStore.member.preferences.excludePrepInstructionsFromPDF);
    setExcludeBatchingFromPDF(props.memberStore.member.preferences.excludeBatchingFromPDF);
  }, [props.memberStore.member]);

  useEffect(() => {
    const element = document.getElementById("preferences");
    const collapse = new Collapse(element, { toggle: false });
    show ? collapse.show() : collapse.hide();
  }, [show]);

  function toggleAllergen(allergenId) {
    let newAllergens;
    const allergen = allergens.find(a => a.id === allergenId);
    if (isEmpty(allergen)) {
      newAllergens = allergens.slice();
      newAllergens.push(props.cache.characteristics.find(a => a.id === allergenId));
    } else {
      newAllergens = allergens.filter(a => a.id !== allergenId);
    }
    setAllergens(newAllergens);
  }

  const allAllergens = props.cache.characteristics.map(a => (
    <AllergenCheckbox key={a.id} allergens={allergens} allergen={a} toggle={toggleAllergen} />
  ));

  const saveButton = working ? (
    <button
      className="btn btn-primary ms-2"
      type="button"
      disabled={true}
    >
      <span className="spinner-border spinner-border-sm"/>
      <span className="ms-1">Saving...</span>
    </button>
  ) : (
    <button
      className="btn btn-secondary ms-2"
      type="button"
      onClick={() => {
        setWorking(true);
        props.memberStore.updatePreferences({
          portions: portions,
          stoveTopOrInstantPot: stoveTopOrInstantPot,
          volumeSystem: volumeSystem,
          weightSystem: weightSystem,
          excludePicturesFromPDF: excludePicturesFromPDF,
          excludeGroceryListItemsYouHaveFromPDF: excludeGroceryListItemsYouHaveFromPDF,
          excludePrepInstructionsFromPDF: excludePrepInstructionsFromPDF,
          excludeBatchingFromPDF: excludeBatchingFromPDF,
          showNutritionFacts: showNutritionFacts,
          mealPlannerListView: mealPlannerListView,
          searchListViewOnMobile: searchListViewOnMobile,
          allergens: allergens,
        }, () => {
          setWorking(false);
        });
      }}
    >
      <span>Save</span>
    </button>
  );

  const cancelButton = working ? (
    <button
      type="button"
      className="btn btn-outline-secondary"
      disabled={true}
    >
      Cancel
    </button>
  ) : (
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => {
        setShow(false);
      }}
    >
      Cancel
    </button>
  );

  return (
    <div className="accordion-item">
      <div className="accordion-header">
        <button
          className={show ? "accordion-button" : "accordion-button collapsed"}
          type="button"
          onClick={() => setShow(!show)}
        >
          <span>Preferences</span>
        </button>
      </div>
      <div id="preferences" className="accordion-collapse collapse">
        <div className="accordion-body">

          <form>
            <div className="row">
              <h4 className="h-alt">Recipe settings</h4>
              <div className="col-auto mb-3">
                <div>
                  <label htmlFor="portions" className="form-label">Default number of servings</label>
                  <select
                    id="portions"
                    className="form-select w-auto"
                    value={portions}
                    onChange={e => setPortions(e.target.value)}
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </select>
                </div>
              </div>
              <div className="col-auto mb-3">
                <div>
                  <label htmlFor="stoveTopOrInstantPot" className="form-label">Default cooking method</label>
                  <select
                    id="stoveTopOrInstantPot"
                    className="form-select w-auto"
                    value={stoveTopOrInstantPot}
                    onChange={e => setStoveTopOrInstantPot(e.target.value)}
                  >
                    <option value={cookingMethods.INSTANT_POT}>{cookingMethods.INSTANT_POT}</option>
                    <option value={cookingMethods.STOVE_TOP}>{cookingMethods.STOVE_TOP}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12">
                <div className="form-check">
                  <label className="form-check-label">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      checked={showNutritionFacts}
                      onChange={e => setShowNutritionFacts(e.target.checked)}
                    /> Show nutrition information in recipes
                  </label>
                </div>
              </div>
            </div>
            <h4 className="h-alt">Magic Meal Planner</h4>
            <div className="row mb-3">
              <div className="col-12">
                {/* both radio buttons are linked to the same database boolean properties */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="mmp-show-mode"
                    id="mmp-show-detailed"
                    checked={!mealPlannerListView}
                    onChange={e => setMealPlannerListView(!e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="mmp-show-detailed"
                  >
                    Default to detailed view
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="mmp-show-mode"
                    id="mmp-show-list"
                    checked={mealPlannerListView}
                    onChange={e => setMealPlannerListView(e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="mmp-show-list"
                  >
                    Default to list view
                  </label>
                </div>
              </div>
            </div>
            <h4 className="h-alt">Search results</h4>
            <div className="row mb-3">
              <div className="col-12">
                {/* both radio buttons are linked to the same database boolean properties */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="search-show-mode"
                    id="search-show-card"
                    checked={!searchListViewOnMobile}
                    onChange={e => setSearchListViewOnMobile(!e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="search-show-card"
                  >
                    Show in card view on mobile
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="search-show-mode"
                    id="search-show-list"
                    checked={searchListViewOnMobile}
                    onChange={e => setSearchListViewOnMobile(e.target.checked)}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="search-show-list"
                  >
                    Show in list view on mobile
                  </label>
                </div>
              </div>
            </div>
            <div className="row my-3">
              <h4 className="h-alt">Measurement system</h4>
              <div className="col-auto">
                <div>
                  <label htmlFor="volumeSystem" className="form-label">Volumes</label>
                  <select
                    id="volumeSystem"
                    className="form-select w-auto"
                    value={volumeSystem}
                    onChange={e => setVolumeSystem(e.target.value)}
                  >
                    <option value={measurementSystems.METRIC}>{measurementSystems.METRIC}</option>
                    <option value={measurementSystems.US}>{measurementSystems.US}</option>
                  </select>
                </div>
              </div>
              <div className="col-auto">
                <div>
                  <label htmlFor="weightSystem" className="form-label">Weights</label>
                  <select
                    id="weightSystem"
                    className="form-select w-auto"
                    value={weightSystem}
                    onChange={e => setWeightSystem(e.target.value)}
                  >
                    <option value={measurementSystems.METRIC}>{measurementSystems.METRIC}</option>
                    <option value={measurementSystems.US}>{measurementSystems.US}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-12 col-md-6">
                <h4 className="h-alt mt-md-1">Allergens</h4>
                <p>Check the boxes next to any allergens you'd like to automatically filter out of recipe search results. <em>Please note: allergen filters do not work on Wizard Plans yet.</em></p>
                <div className="ms-2">
                  {allAllergens}
                </div>
              </div>
              <div className="col-12 mt-3 mt-md-1 col-md-6">
                <h4 className="h-alt">PDF customizations</h4>
                <div className="form-check ms-2">
                  <label className="form-check-label">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      checked={excludePicturesFromPDF}
                      onChange={e => setExcludePicturesFromPDF(e.target.checked)}
                    /> Exclude pictures
                  </label>
                </div>
                <div className="form-check ms-2">
                  <label className="form-check-label">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      checked={excludeGroceryListItemsYouHaveFromPDF}
                      onChange={e => setExcludeGroceryListItemsYouHaveFromPDF(e.target.checked)}
                    /> Exclude items you have from grocery list
                  </label>
                </div>
                <div className="form-check ms-2">
                  <label className="form-check-label">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      checked={excludePrepInstructionsFromPDF}
                      onChange={e => setExcludePrepInstructionsFromPDF(e.target.checked)}
                    /> Exclude prep instructions
                  </label>
                </div>
                <div className="form-check ms-2">
                  <label className="form-check-label">
                    <input
                      className="form-check-input cursor-pointer"
                      type="checkbox"
                      checked={excludeBatchingFromPDF}
                      onChange={e => setExcludeBatchingFromPDF(e.target.checked)}
                    /> Exclude batching
                  </label>
                </div>
              </div>
            </div>
            <div>
              {cancelButton}
              {saveButton}
            </div>
          </form>

        </div>
      </div>
    </div>
  );
}

Preferences.propTypes = {
  cache: PropTypes.object,
  memberStore: PropTypes.object,
};
