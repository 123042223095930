import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Helpers from "../../../services/Helpers";
import NotesAndTips from "./NotesAndTips";
import RecipeDetails from "./RecipeDetails";
import YieldAndLeftovers from "./YieldAndLeftovers";
import RecipeComponent from "../../components/RecipeComponent";
import RecipeInstruction from "./RecipeInstruction";
import MarkdownViewer from "../../components/MarkdownViewer";
import Constants from "../../../services/Constants";

export default function ChildlessRecipe(props) {
  const { assetUrl, isNotEmpty, arrElementValue, toggleCheckbox } = Helpers();
  const { recipeComponentCategories } = Constants();
  const [working, setWorking] = useState(false);

  useEffect(() => {
    setWorking(false);
  }, [props.recipe]);

  /**
   * Toggle all checkboxes of a recipe
   *
   * The new state checked/unchecked of the recipe is used to check/uncheck all checkboxes of the recipe. The new array
   * is sent to a parent function which takes care of saving data and refreshing the state.
   *
   * @param {boolean} checked - new state of the recipe checkbox
   */
  function toggleCheckboxes(checked) {
    const checkboxes = Array(props.recipe.checkboxes.length).fill(checked);
    props.updateCheckboxes(props.recipe.id, checkboxes);
  }

  const checked = props.recipe.checkboxes.filter((x) => !x).length === 0;

  function cookingMethodSwitcher() {
    let result = null;
    if (props.recipe.hasTwoVersions) {
      const buttonText = props.recipe.isStoveTopVersion ? "Switch to Instant Pot Directions" : "Switch to Stove Top Directions";
      const otherMethod = props.recipe.isStoveTopVersion ? "Instant Pot" : "Stove Top";
      const button = working ? (
        <button className="btn btn-sm btn-outline-secondary ms-2" type="button" disabled={true}>
          Switching...
        </button>
      ) : (
        <button
          className="btn btn-sm btn-outline-secondary ms-2"
          type="button"
          onClick={() => {
            setWorking(true);
            props.changeCookingMethod(props.recipe.id, otherMethod);
          }}
        >
          {buttonText}
        </button>
      );
      const text = props.recipe.isStoveTopVersion ? "Stove Top Directions" : "Instant Pot Directions";
      result = (
        <p className="text-muted small text-center text-md-start py-md-2 d-print-none">
          {text} {button}
        </p>
      );
    }
    return result;
  }

  const tools = props.recipe.hasOwnProperty("tools")
    ? props.recipe.tools.map((t, index) => (
        <li key={index} className="list-inline-item">
          <img style={{ width: "32px", margin: ".3em .2em .5em .2em" }} src={assetUrl(t.icon)} title={t.name} alt={t.name} />
        </li>
      ))
    : null;

  let checkboxIterator = 0;
  const components = [];
  for (const [i, c] of props.recipe.components.entries()) {
    const content = <RecipeComponent component={c} parentIsMenuMeal={false} />;
    if (c.category === recipeComponentCategories.RECIPE || c.category === recipeComponentCategories.INGREDIENT) {
      const checked = arrElementValue(props.recipe.checkboxes, checkboxIterator);
      components.push(
        <RecipeInstruction
          key={i}
          content={content}
          showCheckbox={true}
          checked={checked}
          checkboxIterator={checkboxIterator}
          updatingCheckboxes={props.updatingCheckboxes}
          toggleCheckbox={(checkboxIterator) => toggleCheckbox(props.recipe.id, props.recipe.checkboxes, checkboxIterator, props.updateCheckboxes)}
        />
      );
      checkboxIterator++;
    } else {
      components.push(<RecipeInstruction key={i} content={content} showCheckbox={false} />);
    }
  }

  const instructions = [];
  if (isNotEmpty(props.recipe.instructions) && props.recipe.instructions.match(/\n\* |^\* /g)) {
    const splitInstructions = props.recipe.instructions.split("\n");
    for (const [i, element] of splitInstructions.entries()) {
      const content = <MarkdownViewer markdown={element} />;
      // When markdown element starts with a *, we render the checkbox
      if (element.match(/^\* /g)) {
        const checked = arrElementValue(props.recipe.checkboxes, checkboxIterator);
        instructions.push(
          <RecipeInstruction
            key={i}
            content={content}
            showCheckbox={true}
            checked={checked}
            checkboxIterator={checkboxIterator}
            updatingCheckboxes={props.updatingCheckboxes}
            toggleCheckbox={(checkboxIterator) => toggleCheckbox(props.recipe.id, props.recipe.checkboxes, checkboxIterator, props.updateCheckboxes)}
          />
        );
        checkboxIterator++;
      } else {
        instructions.push(<RecipeInstruction key={i} content={content} showCheckbox={false} />);
      }
    }
  }
  const recipeSideBar =
    (props.recipe.hasOwnProperty("tools") && props.recipe.tools.length > 0) || isNotEmpty(props.recipe.yield) || isNotEmpty(props.recipe.leftovers) ? (
      <div className="col-sm-12 col-md-4 recipe-actions order-md-last mb-4 mb-md-0 d-print-none">
        <div className="recipe-deets bg-light mt-4">
          <div className="py-2 px-3 text-center recipe-sidebar">
            {isNotEmpty(props.recipe.yield) || isNotEmpty(props.recipe.leftovers) ? (
              <div className="my-2 lead single-recipe-yield">
                <p>
                  <YieldAndLeftovers yield={props.recipe.yield} leftovers={props.recipe.leftovers} />
                </p>
              </div>
            ) : null}
            <ul className="list-inline mb-1 text-center">{tools}</ul>
          </div>
        </div>
      </div>
    ) : (
      /* We preserve an empty col for alignment */
      <div className="col-sm-12 col-md-4 recipe-actions order-md-last mb-4 mb-md-0 d-print-none" />
    );

  return (
    <div>
      <p className="d-none d-print-block ps-2">
        <YieldAndLeftovers yield={props.recipe.yield} leftovers={props.recipe.leftovers} />
      </p>
      {props.member && (props.member.hasRV || props.member.hasTrial) && (
        <div className="d-none d-print-block display-2">
          <p>
            Hey there, awesome human,
            <br />
            This recipe is <em>so</em> good, but we didn't design Plant Fueled Life for the ol' printer. Upgrade to Full Access to download and print this
            recipe with optimal formatting.
          </p>
        </div>
      )}
      <div
        className={`row bg-white justify-content-md-center p-3 p-md-4 gx-0 ${
          props.member && (props.member.hasRV || props.member.hasTrial) ? "d-print-none" : ""
        }`}
      >
        {recipeSideBar}
        <div className="col-sm-12 col-md-7 pe-2 order-md-first">
          <NotesAndTips notesAndTips={props.recipe.notesAndTips} />
          {cookingMethodSwitcher()}
          <RecipeDetails components={components} instructions={instructions} recipeType={props.recipe.type} />
          {/*Add a checkbox that acts like the check circle to mark the recipe as done*/}
          <div className="ms-2 d-print-none">
            <RecipeInstruction
              content={<MarkdownViewer markdown="- I'm done! Mark this recipe as complete." />}
              showCheckbox={true}
              checked={checked}
              checkboxIterator={0}
              updatingCheckboxes={props.updatingCheckboxes}
              toggleCheckbox={() => toggleCheckboxes(!checked)}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ChildlessRecipe.propTypes = {
  noteStore: PropTypes.object,
  parentRecipeId: PropTypes.number,
  recipe: PropTypes.object,
  recipes: PropTypes.array,
  modals: PropTypes.object,
  updatingCheckboxes: PropTypes.bool,
  updateCheckboxes: PropTypes.func,
  changeCookingMethod: PropTypes.func,
};
