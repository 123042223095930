export function renderCCYears() {
  const yearsSpan = 10;
  const currentYear = new Date().getFullYear();
  const yearsToRender = Array.from({ length: yearsSpan }, (_, idx) => idx + currentYear);
  return yearsToRender.map((y) => (
    <option key={`year-${y}`} value={y.toString()}>
      {y}
    </option>
  ));
}
