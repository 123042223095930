import React from "react";
import PropTypes from "prop-types";

export default function SignUpPlan(props) {

  const button = props.plan.internalName === props.selectedPlan ? (
    <button
      className="btn btn-secondary"
      type="button"
    >
      Selected <i className="fa-solid fa-check"></i>
    </button>
  ) : (
    <button
      className="btn btn-secondary"
      type="button"
      onClick={() => props.selectPlan(props.plan.internalName)}
    >
      Select
    </button>
  );
  return (
    <div 
      className={`card mb-3 cursor-pointer ${props.plan.internalName === props.selectedPlan ? "border-secondary border-5" : null}`}
      onClick={() => props.selectPlan(props.plan.internalName)}
    >
      <div className="card-body p-2">
        <p className={`card-title fs-4 ${props.plan.internalName === props.selectedPlan ? "text-secondary" : "text-dark text-opacity-50"}`}>{props.title}</p>
        <div className="row">
          <div className="col">
            {props.content}
          </div>
          <div className="d-grid gap-2 col-6 col-sm-4 col-md-3 m-auto">
            {button}
          </div>
        </div>
      </div>
    </div>
  );
}

SignUpPlan.propTypes = {
  plan: PropTypes.object,
  title: PropTypes.string,
  content: PropTypes.element,
  selectedPlan: PropTypes.string,
  selectPlan: PropTypes.func,
}