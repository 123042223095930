import { Link } from "react-router-dom";
import ReviewStars from "../../components/ReviewStars";
import Review from "../../components/Review";
import AnonymousFooter from "../../components/AnonymousFooter";
import AnonymousCallToActions from "../../components/AnonymousCallToActions";
import Constants from "../../../services/Constants";
import Helpers from "../../../services/Helpers";

export default function PublicRecipe({ recipeStore, reviewStore, modals }) {
  const { documentTypes } = Constants();
  const { assetUrl, isEmpty } = Helpers();
  const reviews = reviewStore.reviewData.reviews.filter((r) => r.text || r.photo);

  return (
    <div className="container-fluid p-0 p-sm-3 p-md-4 strip-padding">
      <div className="row gx-0 justify-content-md-center recipe-wrap bg-white">
        <div className="col-md-5 bg-white">
          <img
            className="img-fluid recipe-featured-image rounded"
            src={assetUrl(recipeStore.state.publicData.photo)}
            alt={`Whole Food Plant Based ${recipeStore.state.publicData.name}`}
          />
        </div>

        <div className="col-sm-12 col-md-7 p-3 p-md-4 my-auto bg-white">
          <ReviewStars
            stars={recipeStore.state.publicData.stars}
            cta={(event) => {
              event.stopPropagation();
              modals.showReviews({
                documentId: `${recipeStore.state.publicData.id}`,
                documentType: documentTypes.RECIPE,
                reviewsFor: `${recipeStore.state.publicData.name}`,
              });
            }}
          />
          <h1>{recipeStore.state.publicData.name}</h1>
          <span className="allergens text-muted d-print-none small">Oil free, dairy free, no animal products, damn delicious</span>
          <p className="lead">{recipeStore.state.publicData.description}</p>
          {/* <Allergens allergens={recipeStore.state.publicData.allergens} /> */}
          <Link
            to={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=recipe+trial&utm_term=${recipeStore.state.publicData.id}`}
            className="btn btn-primary btn-lg m-1 ms-0"
          >
            View recipe with a free trial
          </Link>
          <p className="text-muted">
            <small>
              No credit card <em>or</em> perfection required.
            </small>
          </p>
        </div>
      </div>
      <div className="row gx-0 justify-content-md-center recipe-wrap bg-white mt-5">
        <div className="col-12 col-md-8 p-3">
          <div>
            <div className="public-reviews">
              <h2>Reviews</h2>
              {reviews.length ? (
                <ul className="list-group ratings">
                  {reviews.map((r) => (
                    <li key={r.id} className="list-group-item">
                      <Review review={r} memberReview={false} noPhotoLayout={isEmpty(r.photo)} />
                    </li>
                  ))}
                </ul>
              ) : (
                <p>
                  No reviews yet! Let’s change that.{" "}
                  <Link
                    to={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=recipe+trial&utm_term=${recipeStore.state.publicData.id}`}
                  >
                    Make this recipe with a free trial
                  </Link>
                  .
                </p>
              )}
            </div>
            <h2 className="mt-4">Why can’t I see this recipe?</h2>
            <p>
              Plant Fueled Life is a paid membership. We have hundreds of{" "}
              <a href="https://cleanfooddirtygirl.com/plant-based-meal-plans/" target="_blank">
                plant based meal plans
              </a>{" "}
              and thousands of recipes waiting for you. We charge a monthly fee so our small team of women across the world can create and maintain our
              ever-growing library of yum. Start a free trial today and see you around!
            </p>

            {/* Bright card: Link to batching funnel */}

            <div className="card bg-light mt-5">
              <div className="card-body sales-card text-center">
                <a
                  href="https://plantfueledlife.members-only.online/batching?utm_campaign=guides&utm_source=portal&utm_medium=public&utm_content=batching+handbook"
                  target="_blank"
                  className="whiteLink"
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/images/Find-Joy-in-Plant-Based-Cooking-With-Plant-Fueled-Life-Meal-Plans.png`}
                    alt="Download your free Beginner's Guide"
                  />
                </a>
                <h2 className="card-title">About batch cooking</h2>
                <p className="card-text mb-2">
                  Our mouthgasmic plant based meal plans are designed around batch cooking. Get your free Batching Handbook and dive in.
                </p>
                <a
                  href="https://plantfueledlife.members-only.online/batching?utm_campaign=guides&utm_source=portal&utm_medium=public&utm_content=batching+handbook"
                  className="btn btn-secondary btn-md"
                  target="_blank"
                >
                  Get your free Handbook
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12 col-md-4 p-3">
          <div>
            <div className="card bg-primary mb-3">
              <div className="card-body sales-card text-center">
                <a
                  href="https://plantfueledlife.members-only.online/1?utm_campaign=guides&utm_source=portal&utm_medium=public&utm_content=beginners+guide"
                  target="_blank"
                >
                  <img src={`${process.env.PUBLIC_URL}/images/Beginners-Guide-to-Eating-More-Plants.png`} alt="Download your free Beginner's Guide" />
                </a>
                <h2 className="card-title text-white">New?</h2>
                <p className="card-text text-white mb-2">
                  Download your free Beginner's Guide and start the most delicious and rewarding journey of your life, fueled by plants.
                </p>
                <a
                  href="https://plantfueledlife.members-only.online/1?utm_campaign=guides&utm_source=portal&utm_medium=public&utm_content=beginners+guide"
                  className="btn btn-light"
                  target="_blank"
                >
                  Get your Guide
                </a>
              </div>
            </div>

            <h2 className="mt-4">Plant Fueled Life can help you</h2>
            <h5 className="h-alt">Become a confident plant based cook</h5>
            <p>Cook surprisingly delicious whole food plant based meals you and your (omni and plant based) family will enjoy.</p>
            <h5 className="h-alt">Create your own meal plans</h5>
            <p>Find the meals and recipes you want to make and easily add them to our Magic Meal Planner.</p>
            <h5 className="h-alt">Find joy in eating plant based</h5>
            <p>Experience the joy that bubbles up when the food you love loves you back.</p>
            <h5 className="h-alt">Crave healthy plant based meals</h5>
            <p>We'll show you how to truly prefer healthy plant based meals to anything else</p>
            <h5 className="h-alt">Stop being stressed about what to make</h5>
            <p>
              Over 4,000 of the most delicious plant based recipes you won't find anywhere else. Say goodbye to pesky ads and Pinterest fails. Say hello to your
              kitchen BFF.
            </p>
            {/* end right column -> */}
          </div>
        </div>
      </div>
      <AnonymousFooter />
      <AnonymousCallToActions
        tellMeMoreLink={`https://cleanfooddirtygirl.com/plant-fueled-life/?utm_campaign=join&utm_source=portal&utm_medium=button&utm_content=recipe+tell+me+more&utm_term=${recipeStore.state.publicData.id}`}
        joinLink={`/trial?utm_campaign=trial&utm_source=portal&utm_medium=button&utm_content=recipe+trial+footer&utm_term=${recipeStore.state.publicData.id}`}
      />
    </div>
  );
}
