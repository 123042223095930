import "../IngredientPreps.css";
import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../components/Checkbox";
import Popover from "../../components/Popover";
import Helpers from "../../../services/Helpers";

export default function IngredientPreps(props) {
  const {isNotEmpty} = Helpers();

  function render() {
    let result = [];
    for (const [index, instruction] of props.prep.instructions.entries()) {
      const firstCol = index === 0 ? (
        <td>
          {props.prep.ingredientName}<Popover title={props.prep.ingredientName} photo={props.prep.ingredientPhoto}/>
        </td>
      ) : <td/>;
      const background = (props.i % 2) === 1 ? null : "table-secondary";
      const additionalInstruction = isNotEmpty(instruction.additionalInstruction) ? (
        <span className="td-addl-instructions"> {instruction.additionalInstruction}</span>
      ) : null;
      const video = isNotEmpty(instruction.ingredientPrepVideoTitle) ? (
        <button
          className="btn btn-link p-0 ps-1"
          onClick={() => {
            props.showVideo({
              ingredientPrepVideoTitle: instruction.ingredientPrepVideoTitle,
              ingredientPrepVimeoId: instruction.ingredientPrepVimeoId
            });
          }}
        >
          <span className="far fa-play-circle text-muted"/>
        </button>
      ) : null;
      result.push(
        <tr key={index} className={background}>
          {firstCol}
          <td className="td-checkbox">
            {/*TODO: this is bad, updatePrep shouldn't work here*/}
            <Checkbox checked={instruction.checked} i={instruction.recipeComponentId}
                      toggle={() => props.updatePrep(instruction.recipeComponentId, !instruction.checked)}/>
          </td>
          <td className="instructions-wrap"><span className="td-instructions">{instruction.text}</span>{additionalInstruction}{video}</td>
          <td className="d-print-block td-recipename text-muted">{instruction.recipeName}</td>
        </tr>
      );
    }
    return <>{result}</>;
  }

  return render();
}

IngredientPreps.propTypes = {
  prep: PropTypes.object,
  i: PropTypes.number,
  updatePrep: PropTypes.func,
  showVideo: PropTypes.func,
}

