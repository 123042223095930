import {useEffect, useState} from "react";
import axios from "axios";
import Helpers from "../Helpers";

export default function useGroceries(token, member) {
  const {isNotEmpty} = Helpers();
  const [customItems, setCustomItems] = useState([]);

  useEffect(() => {
    if (isNotEmpty(token) && member.hasMP) {
      axios({
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/groceries/custom-items`,
      }).then(res => {
        setCustomItems(res.data);
      }).catch(() => {
        // we do nothing
      });
    }
  }, [token, member]);

  function createCustomItem(data, cb) {
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/groceries/custom-items`,
      data: data,
    }).then(res => {
      setCustomItems(res.data);
      if (cb) cb();
    }).catch(() => {
      // we do nothing
    });
  }

  function updateCustomItem(id) {
    const item = customItems.find(ci => ci.id === id);
    if (isNotEmpty(item)) {
      axios({
        method: "put",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`
        },
        url: `${process.env.REACT_APP_API}/groceries/custom-items/${id}`,
        data: {id: id, text: item.text, checked: !item.checked},
      }).then(res => {
        setCustomItems(res.data);
      }).catch(() => {
        // we do nothing
      });
    }
  }

  function deleteCustomItem(id) {
    axios({
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/groceries/custom-items/${id}`,
    }).then(res => {
      setCustomItems(res.data);
    }).catch(() => {
      // we do nothing
    });
  }

  const gStore = {
    customItems: customItems,
    createCustomItem: createCustomItem,
    updateCustomItem: updateCustomItem,
    deleteCustomItem: deleteCustomItem,
  }

  return {gStore};
}