import {useEffect, useState} from "react";
import axios from "axios";

export default function useRelatedContent(token, recipeId) {
  const [content, setContent] = useState({plans: [], recipes: []});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios({
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
      },
      url: `${process.env.REACT_APP_API}/recipe-vault/${recipeId}/related-content`,
    }).then(res => {
      setContent(res.data);
    }).catch(() => {
      // do nothing
    });
  }, [recipeId]);

  useEffect(() => {
    setLoading(false);
  }, [content]);

  return {content, loading};
}